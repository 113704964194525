import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { LoginService } from "../../login";
import { Observable, mergeMap, of, tap } from "rxjs";

@Injectable()
export class IsLoggedIn implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.loginService.isLoggedIn.pipe(
      mergeMap((isLoggedIn : boolean) => { return this.handleActivate(isLoggedIn)})
    )
  }

  handleActivate(isLoggedIn: boolean): Observable<boolean> {
    if(isLoggedIn) {
      return of(true);
    }
    this.router.navigate(['/login'])
    return of(false);
  }
}
