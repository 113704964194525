<mat-card *ngIf="!(isLoggedIn | async) && (isFirstStart | async)" class="m-b-12">
  <img mat-card-image src="./assets/images/Bild_Home@3x.png" alt="Werbefoto">
  <mat-card-header>
    <mat-card-title>Ihr Weg zum passenden Förderprogramm</mat-card-title>
  </mat-card-header>
  <mat-card-content>
      <p style="text-align: justify;">
          Willkommen in der Telekom App <b>Meine Förderung</b>. Hier
          erhalten Sie einen Überblick über Förderprogramme
          des Bundes, der Länder und der Europäischen Union:
          Nutzen Sie die Suchfunktion und durchforsten Sie das
          aktuelle Förderangebot – passgenau für Ihr Vorhaben.
      </p>
      <div class="wrapper_login">
          <span class="login_text_sections">Loggen Sie sich ein, um vollständigen Zugriff auf alle
              Funktionen der App meine Förderung zu erhalten.</span>
      </div>
  </mat-card-content>
  <mat-card-action class="mf-card-actions space-between">
    <button role="link" [routerLink]="['/login']" [trackClick]="['layer', 'button', 'telekom-login']" mat-flat-button color="primary" >Jetzt registrieren</button>
    <span>oder</span>
    <button role="link" [routerLink]="['/login', 'reg']" [trackClick]="['layer', 'button', 'jetzt-registrieren']" mat-flat-button color="primary" >Telekom-Login</button>
  </mat-card-action>
</mat-card>
