import { Component, OnInit } from '@angular/core';
import { ImpressumService, Imprint } from "../../services/impressum.service";
import { BehaviorSubject, Observable, catchError, finalize, map, of, tap } from 'rxjs';
import { LoaderStatus } from '../../components/loader';
import { UpdateService } from '../../services/update.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  $loadingStatus = new BehaviorSubject<LoaderStatus>(LoaderStatus.loading);
  hasError : boolean = false;

  impressum: Observable<Imprint> = this.impressumService.get_impressum().pipe(
    tap(() => {
      this.hasError = false;
      this.$loadingStatus.next(LoaderStatus.loading)
    }),
    catchError(() => {
      this.hasError = true;
      this.$loadingStatus.next(LoaderStatus.error);
      return of(null)
    }),
    finalize(() => {
      !this.hasError ? this.$loadingStatus.next(LoaderStatus.success) : null;
    })
  );

  readonly version = this.updateService.version
  readonly hash = this.updateService.versionHash

  constructor(
    private impressumService: ImpressumService,
    private updateService: UpdateService
  ) { }

  ngOnInit(): void {
  }

}
