<mf-animated-header>
</mf-animated-header>


<div class="scroll-container" mfScrollShadow>
  <div class="container" #contentArea>
    <div class="row justify-content-center">
      <div class="m-t-16 m-b-16 col-12 col-lg-8">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Impressum</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p><strong>App-Version: </strong> {{ version }} <span *ngIf="hash | async as hash" [title]="hash" tabindex="0">(#{{ hash | slice:-4 }})</span></p>
            <p innerHTML={{impress.Impressum}} *ngIf="impressum | async as impress; else loadingOrError"></p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingOrError>
  <ng-container *ngIf="($loadingStatus | async) === 'loading'; else error">
    <span role="status" class="cdk-visually-hidden">Lade den Seiteninhalt</span>
    <mf-skeleton-loader class="m-16" height="600px"></mf-skeleton-loader>
  </ng-container>
</ng-template>

<ng-template #error>
  <mf-loader-error class="block m-t-32"></mf-loader-error>
</ng-template>
