import {
  Directive,
  HostBinding,
  PLATFORM_ID,
  Inject,
  Input,
  ElementRef,
  HostListener
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Directive({
  selector: 'a[href]'
})
export class ExternalLinkDirective {
  @HostBinding('rel') relAttr: string = null;
  @HostBinding('target') targetAttr: string = null;
  @HostBinding('href') hrefAttr: string = '';
  @Input() href: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private elementRef: ElementRef<HTMLAnchorElement>,
    private router: Router
  ) {}

  @HostListener('click', [
    '$event.button',
    '$event.ctrlKey',
    '$event.shiftKey',
    '$event.altKey',
    '$event.metaKey',
  ])
  onClick(): boolean {
    if (this.isLinkExternal()) {
      return true
    } else {
      this.router.navigateByUrl(this.href)
      return false
    }

  }

  ngOnChanges() {
    this.hrefAttr = this.href;

    if (this.isLinkExternal()) {
      this.relAttr = 'noopener';
      this.targetAttr = '_blank';
    }
  }

  private isLinkExternal() {
    return isPlatformBrowser(this.platformId) && !this.elementRef.nativeElement.hostname.includes(location.hostname)
  }
}