<mf-animated-header>
</mf-animated-header>


<div class="scroll-container" mfScrollShadow>
  <div class="container" #contentArea>
    <ng-container *ngIf="promotion | async as data; else errorOrLoading">
      <div class="row m-t-16 justify-content-center">
        <div class="m-t-16 col-12 col-lg-8">
         <mf-truncate-card>
          <ng-container slot="title">
            <mat-card-title class="flex flex-between full-width flex-align-items-center">
              {{data.name}}
              <span>
                <button  mat-icon-button (click)="share_link()" aria-label="Förderprogramm teilen">
                  <mat-icon class="share_icon">share</mat-icon>
                </button>
                <ng-container *ngIf="isLoggedIn | async">
                  <span *ngLet="isFavChecked | async as isFavorite" class="fav-icon m-l-12">
                    <input type="checkbox" class="cdk-visually-hidden" id="myCheckbox{{data.id}}" ngModel (ngModelChange)="toggleFavorite(data.id, $event)" [checked]="isFavorite" />
                    <label for="myCheckbox{{data.id}}" title="{{ isFavorite ? 'Favorit entfernen' : 'Als Favorit markieren' }}">
                      <span class="cdk-visually-hidden">{{ isFavorite ? 'Favorit entfernen' : 'Als Favorit markieren' }}</span>
                      <mat-icon>{{ isFavorite ? 'bookmark' : 'bookmark_border' }}</mat-icon>
                    </label>
                  </span>
                </ng-container>
              </span>
            </mat-card-title>
          </ng-container>
          <ng-container slot="content">
            <div class="status" *ngIf="data.status">
              {{ data.status }}
            </div>
            <div class="fristen row" *ngIf="data.fundingAmount || data.deadline">
              <div class="col-sm-6 col-12 col-xs-12 m-b-8" *ngIf="data.deadline"><strong>Antragsfrist:</strong> {{ data.deadline | date: 'dd.MM.yyyy' }}</div>
              <div class="col-sm-6 col-12 col-xs-12 m-b-8" *ngIf="data.fundingAmount"><strong>Maximale Förderhohe:</strong> {{ data.fundingAmount }}</div>
            </div>
            <div *ngIf="data.foerderart" class="text_formatter">
              <strong class="label">Förderart:</strong>
              <p innerHTML={{data.foerderart}}>
                {{data.foerderart}}</p>
            </div>
              <div *ngIf="data.foerderbereich" class="text_formatter">
                <strong class="label">Förderbereich:</strong>
                <p innerHTML={{data.foerderbereich}} class="text_in_promotion">
                  {{data.foerderbereich}}
                </p>
              </div>
              <div *ngIf="data.foerdergebiet" class="text_formatter">
                <strong class="label">Fördergebiet:</strong>
                <p innerHTML={{data.foerdergebiet}} class="text_in_promotion">
                  {{data.foerdergebiet}}
                </p>
              </div>
              <div *ngIf="data.foerderberechtigte" class="text_formatter">
                <strong class="label">Förderberechtigte:</strong>
                <p innerHTML={{data.foerderberechtigte}} class="text_in_promotion">
                  {{data.foerderberechtigte}}
                </p>
              </div>
              <div *ngIf="data.foerdergeber" class="text_formatter">
                <strong class="label">Fördergeber:</strong>
                <p innerHTML={{data.foerdergeber}} class="text_in_promotion">
                  {{data.foerdergeber}}
                </p>
              </div>
              <div *ngIf="data.ansprechpunkt">
                <strong class="label">Ansprechpunkt:</strong>
                <p innerHTML={{data.ansprechpunkt}} class="text_word_warp">
                  {{data.ansprechpunkt}}
                </p>
              </div>
              <div *ngIf="data.links" class="extern_links">
                <strong class="label">Weiterführende Links:</strong>
                <div *ngFor="let link of data.links">
                  <mat-icon class="icon_link">reply</mat-icon><a href={{link}} target="_blank"
                  class="link">{{link.split("/")[2]}}/{{link.split("/")[3]}}</a>
                </div>
              </div>
              <div *ngIf="data.kurztext">
                <strong class="label">Kurztext:</strong>
                <p innerHTML={{data.kurztext}}>{{data.kurztext}}</p>
              </div>
              <div *ngIf="data.volltext">
                <strong *ngIf="data.kurztext !== undefined">Volltext:</strong>
                <p innerHTML={{data.volltext}} class="text_word_warp">{{data.volltext}}</p>
              </div>
              <div *ngIf="data.rechtliche_voraussetzungen">
                <strong class="label">rechtliche Voraussetzungen:</strong>
                <div>
                  <p innerHTML={{data.rechtliche_voraussetzungen}} class="text_word_warp">
                    {{data.rechtliche_voraussetzungen}}
                  </p>
                </div>
              </div>
              <div *ngIf="data.fristen">
                <strong class="label">Fristen:</strong>
                <div>
                  <p innerHTML={{data.fristen}}>{{data.fristen}}</p>
                </div>
              </div>
              <div *ngIf="data.verfahrensablauf">
                <strong class="label">Verfahrensablauf:</strong>
                <div>
                  <p innerHTML={{data.verfahrensablauf}}>{{data.verfahrensablauf}}</p>
                </div>
              </div>
              <div *ngIf="data.richtlinie">
                <strong class="label">Richtlinie:</strong>
                <p innerHTML={{data.richtlinie}}>{{data.richtlinie}}</p>
              </div>
              <ng-container *ngIf="data.fp_id_intern">
                <p class="lizenzvereinbarung_text">
                  Weitere Informationen zum Förderprogramm finden Sie
                  <a href="{{data.url}}" target="_blank">hier</a>.
                </p>
                <app-disclaimer></app-disclaimer>
              </ng-container>
              <ng-container *ngIf="!data.fp_id_intern">
                <p class="lizenzvereinbarung_text">
                  Bundesministerium für Wirtschaft und Klimaschutz: {{data.name}}. In: Förderdatenbank des
                  Bundes, 2022, <a href="{{data.url}}" target="_blank">{{data.url}}</a>
                  (abgerufen
                  am {{data.lastCrawledDate}}).
                </p>
                <p class="lizenzvereinbarung_text">
                  Förderprogramme, die vom Bundesministerium für Wirtschaft und Klimaschutz (BMWK) veröffentlicht
                  werden,
                  sind unter CC BY-ND 3.0 DE lizensiert und werden unbearbeitet und vollständig wiedergegeben.
                </p>
              </ng-container>
          </ng-container>
         </mf-truncate-card>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <h2 class="m-t-16">Finden Sie ein passendes Förderprogramm</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4">
          <mat-card class="m-b-12">
            <mat-card-header>
              <mat-card-title class="mf-small-title">Quick-Check</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              Nutzen Sie unseren Quick-Check und entdecken Sie weitere, für Sie relevante Fördermöglichkeiten.
            </mat-card-content>
            <mat-card-actions class="flex-end">
              <button [trackClick]="['content', 'button', 'zum-check', data.id]" role="link" [routerLink]="['/quickcheck']" [queryParams]="{skipIntro: true,  forceQuickchecks: data.quickchecks?.join(',')}" color="primary" mat-flat-button>
                <span class="uppercase">Zum Quick-Check </span><mat-icon iconPositionEnd>arrow_forward</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
        <div class="col-12 col-lg-4">
          <mat-card class="m-b-12">
            <mat-card-header>
              <mat-card-title class="mf-small-title">Kontakt</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              Sie haben Fragen zu Ihrem Vorhaben oder eines der Förderprogramme? Dann nehmen Sie am besten gleich Kontakt zu uns auf und lassen sich beraten.
            </mat-card-content>
            <mat-card-actions class="space-between">
              <a [trackClick]="['content', 'button', 'telefon-kontakt', data.id]" class="mf-button" *ngIf="(contactInfo | async) as info" [href]=" 'tel:' + info.allgemeine_telefon" target="_blank" mat-flat-button>
                <span class="uppercase">Anrufen</span><mat-icon iconPositionEnd>phone</mat-icon>
              </a>
              <button [trackClick]="['content', 'button', 'email-kontakt', data.id]" role="link" routerLink="/contactform" [queryParams]="{'type': 'promotion', 'promotionId': data.id }" color="primary" mat-flat-button>
                <span class="uppercase">Kontakt</span> <mat-icon iconPositionEnd>mail</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>


        </div>
      </div>
      <div class="row justify-content-center" *ngIf="data.products?.length > 0">
        <div class="col-12 col-lg-8">
          <ng-container>
            <h2 class="m-t-16">Unsere passenden Produkte</h2>
            <mf-horizontal-scroller class="m-b-16">
              <mf-simple-product-card [trackClick]="['content', 'button-image-text', 'produkt', data.id + '.' + product.id]" *ngFor="let product of data.products" title="{{product.displayName}}" url="{{product.url}}"></mf-simple-product-card>
            </mf-horizontal-scroller>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #errorOrLoading>
  <ng-container *ngIf="$error | async; else loading">
    <div class="row m-t-16 justify-content-center">
      <div class="m-t-16 col-12 col-lg-8">
        <mf-loader-error></mf-loader-error>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #loading>
  <span role="status" class="cdk-visually-hidden">Lade den Seiteninhalt</span>
  <mf-skeleton-loader class="block m-t-16 m-b-8" height="550px"></mf-skeleton-loader>
  <mf-skeleton-loader class="block m-t-32 m-b-16" height="30px"></mf-skeleton-loader>
  <mf-skeleton-loader class="block m-t-8 m-b-8" height="120px"></mf-skeleton-loader>
  <mf-skeleton-loader class="block m-t-8 m-b-8" height="120px"></mf-skeleton-loader>
</ng-template>
