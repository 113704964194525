import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'mf-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  @Input() label : string = "";
  @Input() value: string = "";

  @Output() searchValue = new EventEmitter<string>();

  @ViewChild('search') search: ElementRef<HTMLInputElement>;
  
  public clearValue() {
    this.value = "";
    this.startSearch()
  }

  startSearch() {
    this.search.nativeElement.blur()
    this.searchValue.emit(this.value);
  }
}
