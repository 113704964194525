import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private readonly onlyShowContent = new BehaviorSubject<boolean>(false);

  constructor() { }

  setShowContent(showOnlyContent : boolean) {
    this.onlyShowContent.next(showOnlyContent);
  }

  getShowContent() : Observable<boolean> {
    return this.onlyShowContent;
  }
}
