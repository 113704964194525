import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar'
import { UserService } from '../user.service'
import { FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { SCIMRegistration } from '../model/scrim-registration.iface';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  registerForm = this.formBuilder.nonNullable.group({
    corporation: ['', Validators.required],
    title: [''],
    givenName: ['', Validators.required],
    familyName: ['', Validators.required],
    email: ['', Validators.compose([Validators.email, Validators.required])],
    postalCode: ['', Validators.required]
  })

  /* HTML */
  registrationError?: string = undefined

  constructor(
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
  }

  register() {

    this.registerForm.disable()
    this.registrationError = undefined
    // TODO: Fix register form
    this.userService.registerUser(this.registerForm.value as unknown as SCIMRegistration).pipe(
      finalize(() => {
        this.registerForm.enable()
      })
    ).subscribe({
      next: () => {
        this.snackbar.open(
          'Ihre Registrierung wurde erfolgreich übermittelt. Bitte prüfen Sie Ihren E-Maileingang auf die Bestätigungs-E-Mail und vergeben Sie ein Passwort.',
          undefined,
          {
            duration: 3000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          }
        )
        this.router.navigate(['/'])
      },
      error: (data) => {
        if (data?.status == 409) {
          this.registrationError = 'Ein Nutzer mit diesen Angaben existiert bereits. Bitte melden Sie sich an oder versuchen Sie eine andere E-Mail-Addresse.'
        } else {
          this.registrationError = 'Registrierung leider fehlgeschlagen! Bitte versuchen Sie es später erneut.'
        }
      }
    })
  }

}
