import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgLetDirective } from './directives/ng-let.directive';
import { SecurePipe } from './pipes/secure.pipe';
import { EnvPipe } from './pipes/env.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { RepeatTimesDirective } from './directives/repeat.directive';
import { SecureHTMLPipe } from './pipes/secure-html.pipe';



@NgModule({
  declarations: [
    NgLetDirective,
    SecurePipe,
    EnvPipe,
    TruncatePipe,
    RepeatTimesDirective,
    SecureHTMLPipe
  ],
  exports: [
    NgLetDirective,
    SecurePipe,
    SecureHTMLPipe,
    EnvPipe,
    TruncatePipe,
    RepeatTimesDirective
  ],
  imports: [
    CommonModule
  ]
})
export class UtilitiesModule { }
