import { ApplicationRef, Inject, Injectable, InjectionToken } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionReadyEvent } from '@angular/service-worker';
import { Observable, ReplaySubject, concat, filter, first, interval, map, of, startWith, tap } from 'rxjs';

export const PACKAGE_VERSION = new InjectionToken<string>('package.version')

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private _versionHash = new ReplaySubject<string>(1)
  public updateAvailable: boolean = false;
  public readonly versionHash: Observable<string> = this._versionHash

  constructor(
    private updates: SwUpdate,
    private appRef: ApplicationRef,
    @Inject(PACKAGE_VERSION) public readonly version: string
  ) {
    // If updates are enabled
    if (updates.isEnabled) {
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(10 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
      this.updates.versionUpdates.pipe(
        filter(event => event.type === 'VERSION_READY'),
        map(event => (<VersionReadyEvent>event).currentVersion.hash),
        tap(hash => localStorage.setItem("VERSION_HASH", hash)),
        startWith(localStorage.getItem("VERSION_HASH"))
      ).subscribe(this._versionHash)
    } else {
      this.versionHash = of()
    }
  }

  // Called from app.components.ts constructor
  public checkForUpdates() {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_DETECTED') {
          console.log('New version detected')
          this.updateAvailable = true;
          this.doUpdate();
        }
        if (event.type === 'VERSION_READY') {
          console.info(`UPDATE AVAILABLE FROM ${event.currentVersion.hash} to ${event.latestVersion.hash}`)
          document.location.reload()
        }

        if (event.type === 'VERSION_INSTALLATION_FAILED') {
          console.info('Version Installation failed', event.version, event.error)
        }
      });

      this.updates.unrecoverable.subscribe(event => {
        console.info(`UNABLE TO RECOVER BECAUSE: ${event.reason}`)
        this.doRecover(event)
      })
    }
  }

  // If there is an update, promt the user
  public doUpdate(): void {
    this.updates.activateUpdate().then(() => {
      // TODO: Add analytics event here.
    });
  }

  private doRecover(_: UnrecoverableStateEvent): void {
    // TODO: Track analytics event here.
    document.location.reload()
  }
}
