import { Injectable } from '@angular/core';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { map, catchError, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserProfile } from './model/user-profile.iface';

@Injectable({
  providedIn: 'root'
})
export class OIDCService {

  private readonly tokenReceived = new BehaviorSubject<boolean>(false)
  public readonly isLoggedIn: Observable<boolean> = this.tokenReceived.pipe(distinctUntilChanged())
  public get isLoggedInSync(): boolean {
    return this.tokenReceived.value
  }

  constructor(
    private oAuthService: OAuthService
  ) {
    this.initialize()

    this.oAuthService.events.pipe(
    ).subscribe(event => {
      switch(event.type) {
        case 'token_received':
          this.tokenReceived.next(true)
        break;
      }
    })

  }

  private initialize() {
    const config = environment.authentication
    const authConfig: AuthConfig = { 
      ...config,
      loginUrl: config.issuer + '/protocol/openid-connect/auth',
      logoutUrl: window.location.origin,
      tokenEndpoint: config.tokenEndpoint ?? config.issuer + '/protocol/openid-connect/token',
      userinfoEndpoint: config.userinfoEndpoint ?? config.issuer + '/protocol/openid-connect/userinfo',
      redirectUri: window.location.origin,
      responseType: 'code',
      showDebugInformation: true
    }

    this.oAuthService.configure(authConfig)
    this.oAuthService.setupAutomaticSilentRefresh()
  }

  public login(): Observable<boolean> {
    if (this.oAuthService.getRefreshToken()) {
      return this.doRefreshTokenFlow()
    } else {
      return this.doLoginFlow()
    }
  }

  public checkLogin(): Observable<boolean> {
    return from(this.oAuthService.tryLogin()).pipe(
      switchMap(() => {
        if (this.oAuthService.hasValidAccessToken()) {
          return of(true)
        } else if (!!this.oAuthService.getRefreshToken()) {
          return this.doRefreshTokenFlow(false)
        } else {
          return of(false)
        }
      }),
      tap(isLoggedIn => {
        if (isLoggedIn) {
          this.tokenReceived.next(isLoggedIn)
        }
      })
    )
  }

  public logout(): Observable<void> {
    return of(this.oAuthService.logOut()).pipe(
      tap(() => {
        this.tokenReceived.next(false)
      })
    )
  }

  public getProfile(): Observable<UserProfile> {
    const promiseWrapper = new Promise((resolve, reject) => {
      try {
        this.oAuthService.loadUserProfile().then(resolve).catch(reject)
      } catch(e) {
        reject(e)
      }
    })

    return from(promiseWrapper).pipe(
      map((profile: any) => ({
        id: profile.info.icuid,
        username: profile.info.preferred_username,
        email: profile.info.email,
        firstname: profile.info.given_name,
        lastname: profile.info.family_name,
        postalCode: profile.info.postal_code,
        corporation: profile.info.corporation
      }))
    )
  }

  private doRefreshTokenFlow(loginOnError: boolean = true): Observable<boolean> {
    return from(this.oAuthService.refreshToken()).pipe(
      map(() => true),
      catchError(() => loginOnError ? this.doLoginFlow() : of(false))
    )
  }

  private doLoginFlow(): Observable<boolean> {
    return from(this.oAuthService.tryLogin()).pipe(
      map(() => {
        if (!this.oAuthService.hasValidIdToken() || !this.oAuthService.hasValidAccessToken()) {
          this.oAuthService.initCodeFlow()
          return false
        } else {
          return true
        }
      })
    )
  }
}

