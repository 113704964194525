import { Component } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DeviceService } from 'src/app/services/device.service';
import { StorageService } from 'src/app/services/storage.service';
import { toNumber } from 'src/app/utilities';
import { Environment } from 'src/environments/environment.iface';

@Component({
  selector: 'mf-app-store-card',
  templateUrl: './app-store-card.component.html',
  styleUrls: ['./app-store-card.component.scss']
})
export class AppStoreCardComponent {

  private readonly STORAGE_KEY = "appStoreCardShown"

  showCard: Observable<boolean>
  isAndroid = this.deviceService.osFamily === "Android"
  appstoreUrl: string
  dontShowAgain: boolean
  
  constructor(
    private deviceService: DeviceService,
    private environment: Environment,
    private storage: StorageService
  ) {
    this.appstoreUrl = this.environment.appStoreUrls[this.deviceService.osFamily] ?? ""

    // Show the card only on mobile when not installed and
    // the card has not been shown more than 3 times already
    this.showCard = this.storage.observe(this.STORAGE_KEY).pipe(
      map(value => this.deviceService.isMobile && 
                   !this.deviceService.isStandalone &&
                   toNumber(value) < 3
      )
    )
  }

  closeCard() {
    // Incremet the counter in the storage.
    let currentCount = toNumber(this.storage.get(this.STORAGE_KEY))
    this.storage.set(this.STORAGE_KEY, (this.dontShowAgain ? 99 : ++currentCount).toString())
  }

}
