<mat-toolbar class="mat-toolbar mat-toolbar-multiple-rows" color="primary">
  <mat-toolbar-row>
    <ng-container *ngIf="!isDesktop; else DesktopMenu">
      <button
        mat-icon-button
        aria-label="Zurück"
        (click)="backNavigation()"
      >
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <h1 class="title">{{ title | async }}</h1>
      <div spacer></div>
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>

<ng-template #DesktopMenu>
  <button
    *ngIf="standalone; else logo"
    mat-icon-button
    aria-label="Zurück"
    (click)="backNavigation()"
  >
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <ng-template #logo>
    <img src="/assets/images/logo.svg" alt="Meine Förderung Logo" />
  </ng-template>
  <div spacer></div>
  <nav
    mat-tab-nav-bar
    class="desktop-menu"
    [tabPanel]="tabPanel"
    mat-align-tabs="end"
    mat-stretch-tabs="false"
  >
    <ng-container  *ngFor="let link of menuItems">
      <a mat-tab-link
        [routerLink]="link.path"
        *ngIf="!link.items"
        routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive"
        [class.medium]="rla.isActive"
        class="uppercase">
      {{link.name}}
      </a>
      <button class="uppercase" *ngIf="link.items" mat-tab-link [matMenuTriggerFor]="menu">
        {{link.name}}
      </button>
      <mat-menu #menu="matMenu" class="sub-menu">
        <ng-container *ngFor="let childLink of link.items">
          <button class="uppercase" *ngIf="!childLink.external" role="link" mat-menu-item  routerLink="{{childLink.path}}">
            {{childLink.name}}
          </button>
          <a class="external-link uppercase" *ngIf="childLink.external" mat-menu-item [href]="childLink.path" >
            <span>{{childLink.name}}</span>
            <mat-icon [inline]="true">launch</mat-icon>
          </a>
        </ng-container>
      </mat-menu>
    </ng-container>
  </nav>
  <ng-container *ngTemplateOutlet="actions"></ng-container>
  <!-- Needed so the component is valid even if not used-->
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
</ng-template>

<ng-template #actions>
  <a mat-icon-button QRCode>
    <mat-icon>qr_code_2</mat-icon>
  </a>
  <a
    routerLink="/t_ansprechpartner"
    mat-icon-button
    aria-label="Kontakt"
  >
    <mat-icon>phone</mat-icon>
  </a>
  <a *ngLet="isLoggedIn | async as isLoggedIn" [routerLink]="['/profile']" mat-icon-button aria-label="Mit Benutzer anmelden" [trackClick]="['navi-top', 'image', 'login']">
    <mat-icon *ngIf="!isLoggedIn">login</mat-icon>
    <mat-icon *ngIf="isLoggedIn">account_circle</mat-icon>
  </a>
</ng-template>