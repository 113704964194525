<mf-animated-header>
</mf-animated-header>

<scroll-shadow>
  <div class="scroll-container">
    <div class="container">
      <div class="row justify-content-center">
        <div class="m-t-16 m-b-16 col-12 col-lg-8">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Melden Sie sich jetzt mit Ihrem Telekom-Login an.</mat-card-title>
            </mat-card-header>
              <mat-card-content>
                  <a [trackClick]="['content', 'text', 'mehr-informationen']" href="https://www.telekom.de/datenschutzhinweise/view/274.html" target="_blank" class="mehr_informationen_link">Mehr Informationen</a>
              </mat-card-content>
              <mat-card-actions class="space-between">
                <a routerLink="/register" class="mf-button" mat-flat-button>Jetzt registrieren</a>
                <button [trackClick]="['content', 'button', 'weiter-zum-login']"  (click)="login()" mat-flat-button color="accent" class="button-login">Zum Login</button>
              </mat-card-actions>
          </mat-card>
        </div>
      </div>

    </div>
  </div>
</scroll-shadow>
