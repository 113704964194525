import { Output } from '@angular/core';
import { Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { delay, filter, map, switchMap, take } from 'rxjs/operators';
import { RestoreScrollingService } from './restore-scrolling.service';
import { ScrollPositionBase } from './scroll-position-base';
import { ToBoolean } from 'src/app/utilities';

@Directive({
  selector: '[mfRestoreScrolling]'
})
export class RestoreScrollingDirective implements OnInit, OnDestroy, OnChanges {

  @Input('mfRestoreScrolling')
  public tag: string

  @Input()
  public scrollerLoaded: boolean = false

  @Input() @ToBoolean()
  public waitForScroller: boolean = false

  @Input() @ToBoolean()
  public emitScrollPosition: boolean = false

  @Output()
  public scrollPosition: EventEmitter<number> = new EventEmitter()

  private scrollerLoaded$ = new ReplaySubject<boolean>(1)

  constructor(
    private scroller: ScrollPositionBase,
    private service: RestoreScrollingService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.scrollerLoaded) {
      this.scrollerLoaded$.next(changes.scrollerLoaded.currentValue)
    }
  }

  ngOnInit(): void {
    this.service.getScrollPosition(this.tag).pipe(
      take(1),
      switchMap(position => combineLatest([
        of(position), 
        this.waitForScroller ? this.scrollerLoaded$.pipe(
          filter(loaded => !!loaded),
          take(1)
        ) : of(true)
      ])),
      map(([position, _]) => position),
      delay(50)
    ).subscribe(position => {
      if (this.emitScrollPosition) {
        this.scrollPosition.next(position)
      } else {
        this.scroller.scrollTop = position
      }
    })
  }

  ngOnDestroy(): void {
    this.service.setScrollPosition(this.tag, this.scroller.scrollTop)
  }



}
