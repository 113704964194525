import { Component, Input } from '@angular/core';

@Component({
  selector: 'mf-skeleton-loader',
  templateUrl: './loader-card.component.html',
  styleUrls: ['./loader-card.component.scss']
})
export class LoaderCardComponent {

  @Input() height: string = '100px'
  @Input() width: string = '100%'

}
