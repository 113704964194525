import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmpfehlungsCardComponent } from './empfehlungs-card/empfehlungs-card.component';
import { MatCardModule } from '@angular/material/card';
import { UtilitiesModule } from '../../utilities';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ListCardComponent } from './list-card/list-card.component';
import { RouterModule } from '@angular/router';
import { SimpleProductCardComponent } from './simple-product-card/simple-product-card.component';
import { TruncateCardComponent } from './truncate-card/truncate-card.component';
import { AccordionNavCardComponent } from './accordion-nav-card/accordion-nav-card.component';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { DirectivesModule } from '../directives/directives.module';


@NgModule({
  declarations: [
    EmpfehlungsCardComponent,
    ListCardComponent,
    SimpleProductCardComponent,
    TruncateCardComponent,
    AccordionNavCardComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    UtilitiesModule,
    RouterModule,
    MatListModule,
    MatExpansionModule,
    DirectivesModule
  ],
  exports: [
    EmpfehlungsCardComponent,
    ListCardComponent,
    SimpleProductCardComponent,
    TruncateCardComponent,
    AccordionNavCardComponent
  ],
  providers: [
  ]
})
export class CardsModule { }
