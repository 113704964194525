import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicOverlayComponent } from './dynamic-overlay.component';
import { OverlayTriggerDirective } from './overlay-trigger.directive';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  declarations: [
    DynamicOverlayComponent,
    OverlayTriggerDirective
  ],
  exports: [
    DynamicOverlayComponent,
    OverlayTriggerDirective
  ],
  imports: [
    CommonModule,
    PortalModule,
    OverlayModule,
    A11yModule,
    DirectivesModule
  ]
})
export class DynamicOverlayModule { }
