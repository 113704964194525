import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { jsPDF } from 'jspdf';
import { BehaviorSubject } from 'rxjs';
import { 
  QuickCheck,
  QuickCheckAnswer,
  QuickCheckContainmentAnswer,
  QuickCheckContainmentQuestion,
  QuickCheckTab,
  QuickcheckService
} from "../../services/quickcheck.service";
import { Aktionsbereich, Style, UtagService } from '../../components/analytics';
import { flavor } from 'src/environments/flavor';
import { environment } from 'src/environments/environment';
import { UiService } from '../../components/layout';
import { PromotionService } from '../promotion/services';
import { DeviceService } from 'src/app/services/device.service';
import { Promotion } from '../promotion';
import { Environment } from 'src/environments/environment.iface';

interface SimpleQuickcheck {
  qcTyp: string,
  qcBreadcrumb: QcBreadcrumb[]
}

interface QcBreadcrumb {
  Id: string,
  FrageId: string,
  Antworten: string[]
}

interface QcReasons {
  header: string,
  reasons: string[]
}

export enum QuickCheckUIState {
  intro = "intro",
  entry = "entry",
  quickcheck = "quickcheck",
  result = "result",
  download = "download"
}

enum QuestionGroup {
  ausschluss = "ausschluss",
  eingrenzung = "eingrenzung"
}

@Component({
  selector: 'app-quickcheck',
  templateUrl: './quickcheck.component.html',
  styleUrls: ['./quickcheck.component.scss'],
})
export class QuickcheckComponent implements OnInit {
  // ################################################################
  // # CONSTANTS
  // ################################################################

  public QuickCheckUIState = QuickCheckUIState;

  UI_QUESTION_TYPE_RADIO = "Radiobutton";
  UI_QUESTION_TYPE_SLIDER = "Slider";
  UI_QUESTION_TYPE_CHECKBOX = "Checkbox";

  // ################################################################
  // # VARS
  // ################################################################

  // QUICKCHECK VARS
  private quickchecks: QuickCheck[];
  private currentQuickcheck: QuickCheck;
  private currentQuestions: Map<string, QuickCheckContainmentQuestion> = new Map

  qcGroups: QuickCheckTab[] = [];
  qcActiveTab$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  // QUICKCHECK STATUS
  private qcFinished: boolean = false;
  private qcCurQuestionId: string | null = null;
  private qcSelections: Map<string, QcBreadcrumb> = new Map();
  qcBreadcrumbIndex = -1;
  qcBreadcrumb: QcBreadcrumb[] = [];
  
  // QUICKCHECK RESULT
  qcResultSuccess: boolean = false;
  qcResultReasons: QcReasons[] = [];
  private qcResultPromotionIds: string[] = [];
  private qcResultPromotions: Promotion[] = [];
  private qcInstantDownload: boolean = false;

  qcResultDocLink: string = "";
  
  // OTHER VARS
  iosApp: boolean = this.deviceService.isIOSWebView
  snippetOrHotline: boolean = flavor.snippet || flavor.isHotline;


  // UI VARS
  isHotline: boolean = flavor.isHotline;
  ui_state: QuickCheckUIState = QuickCheckUIState.intro;
  ui_question: string = "";
  ui_questionSub: string = "";
  ui_questionType: string = "";
  ui_answers : QuickCheckAnswer[] = [];
  ui_answerSingleSelection = -1; // for questionType Radio and Slider
  ui_answerMultiSelection: any = [];

  ui_forcedQuickchecks : string[] = [];

  // ################################################################
  // # INIT
  // ################################################################

  constructor(
    private QuickcheckService: QuickcheckService,
    private PromotionService: PromotionService,
    private route: ActivatedRoute,
    private utag: UtagService,
    private router: Router,
    private uiService: UiService,
    private deviceService: DeviceService,
    private environment: Environment
  ) { }

  ngOnInit(): void {

    this.setUIState(QuickCheckUIState.intro);

    // fetch quickcheck
    this.QuickcheckService.getQuickchecks().subscribe(quickchecks => {
      // init vars
      this.quickchecks = quickchecks

      this.checkUrlParamsAndStart();
    });
  }

  navigateToContact(type: string) {
    if (flavor.snippet) {
      const url = new URL(window.location.href)
      url.pathname = url.pathname.split('/').slice(0, -1).join('/') + '/kontakt'
      url.searchParams.set('type', type)

      window.location.href = url.toString()
    } else {
      this.router.navigate(['/contactform'], { queryParams: { type } });
    }
  }

  deeplink_to_fp(promo: Promotion) {
    this.utag_promotionClick(promo);
    if (flavor.snippet) {
      window.open(this.environment.assetBaseUrl + '/promotions/' + promo.id, "_blank");
    } else {
      this.router.navigate(['/promotions', promo.id])
    }
  }

  checkUrlParamsAndStart() {
    this.route.queryParams.subscribe(params => {
      if (params.forceQuickchecks) {
        let forceQuickchecks = params.forceQuickchecks.split(',');
        forceQuickchecks.forEach((quickcheckId: string) => {
          this.ui_forcedQuickchecks.push(quickcheckId)
        });
      } else {
        this.ui_forcedQuickchecks = []
      }

      if(params.skipIntro && !this.qcFinished && params.quickCheck === undefined) {
        console.log('should force')
        this.event_startQuickcheck()
      } else if (params.t !== undefined && params.q == undefined && params.skipIntro == undefined) {
        this.setQcTyp(params.t);
        this.setUIState(QuickCheckUIState.quickcheck);
        this.nextQuickcheckQuestion();
      } else if (params.t !== undefined && params.q !== undefined) {
        let success: boolean = true;
        if(params.instantDownload) {
          this.qcInstantDownload = true;
        }

        this.currentQuickcheck = this.quickchecks.find(qc => qc.id == params.t);
        this.initGroups();
        this.qcCurQuestionId = null;
        this.qcFinished = true;
        this.qcBreadcrumb = [];
        this.qcSelections = new Map();

        let questions = params.q.split(";");
        this.qcBreadcrumbIndex = questions.length;
        outer: for (let iQ = 0; iQ < questions.length; iQ++) {
          let question = this.currentQuestions.get(questions[iQ].split(":")[0]);
          if (!question) {
            success = false;
            break;
          }

          let answers = [];
          let a = [];
          if (questions[iQ].split(":")[1] !== "") {
            a = questions[iQ].split(":")[1].split(",");
          }

          for (let iA = 0; iA < a.length; iA++) {
            let answer = this.getAnswerById(question, a[iA]);
            if (answer == null) {
              success = false;
              break outer;
            }
            answers.push(answer.AntwortId);
          }

          let bcObject : QcBreadcrumb = {
            "Id": question.Id,
            "FrageId": question.FrageId,
            "Antworten": answers
          }
          this.qcBreadcrumb.push(bcObject);
          this.qcSelections.set(question.Id, bcObject)
        }

        if (success) {
          this.writeQuickcheckStatus();
          if (this.createResult()) {
            this.utag_viewResult(this.currentQuickcheck.id);
            this.setUIState(QuickCheckUIState.result);

            if (this.qcInstantDownload) {
              this.setUIState(QuickCheckUIState.download)
              this.uiService.setShowContent(true)
              this.writePDF()
            }
          }
        } else {
          this.utag_viewQuickCheck();
          this.resetQuickcheckStatus();
          this.initByLocaleStorage();
        }
      } else {
        this.initByLocaleStorage();
      }
    });
  }

  initByLocaleStorage() {
    this.readQuickcheckStatus();

    if (this.qcFinished) {
      if (this.createResult()) {
        this.utag_viewResult(this.currentQuickcheck.id);
        this.setUIState(QuickCheckUIState.result);
      } else {
        this.utag_viewQuickCheck();
        this.resetQuickcheckStatus();
      }
    } else {
      this.utag_viewQuickCheck();
      this.resetQuickcheckStatus();
    }
  }

  // ################################################################
  // # QC PROCESSING METHODS
  // ################################################################

  setQcTyp(qcTyp: string) {
    this.currentQuickcheck = this.quickchecks.find(qc => qc.id == qcTyp)
    this.initGroups();
    this.writeQuickcheckStatus();
  }

  nextQuickcheckQuestion() {
    if (this.qcBreadcrumbIndex >= 0) {
      const bcObject = this.createCurSelectionObject()

      this.qcBreadcrumb.push(bcObject);
      this.qcSelections.set(bcObject.Id, bcObject)
    }

    this.qcBreadcrumbIndex++;

    let question = this.getNextQuestion();
    if (!!question) {
      this.qcCurQuestionId = question.Id;

      this.writeQuickcheckStatus();
      this.showQuestion(question);
      this.showAnswerSelection();
    } else {
      this.qcCurQuestionId = null;
      this.qcFinished = true;
      this.writeQuickcheckStatus();

      this.createResult();
      this.utag_viewResult(this.currentQuickcheck.id);
      this.setUIState(QuickCheckUIState.result);
    }
    this.getActiveGroupIndex()
  }

  prevQuickcheckQuestion() {
    if (this.qcCurQuestionId) {
      const bcObject = this.createCurSelectionObject()
      this.qcSelections.set(bcObject.Id, bcObject)
    }

    this.qcBreadcrumbIndex--;
    if (this.qcBreadcrumbIndex == -1) {
      this.qcCurQuestionId = null;
      this.writeQuickcheckStatus();
      this.event_startQuickcheck();
    } else {
      const breadcrumbEntry = this.qcBreadcrumb.pop();
      const question = this.currentQuestions.get(breadcrumbEntry.Id);
      this.qcCurQuestionId = question.Id;
      this.writeQuickcheckStatus();
      this.showQuestion(question);
      this.showAnswerSelection();
    }
    this.getActiveGroupIndex()
  }

  getNextQuestion(): QuickCheckContainmentQuestion {
    let useNext: boolean = false;
    const questions = this.currentQuickcheck
    if (!questions) {
      return null;
    }

    for (let i = 0; i < questions.Ausschlussfragen.length; i++) {
      if (!this.qcCurQuestionId || useNext) {
        return questions.Ausschlussfragen[i];
      } else if (questions.Ausschlussfragen[i].Id == this.qcCurQuestionId) { // aktuelle Frage gefunden, dann nächste Frage verwenden
        useNext = true;
      }
    }
    if (!this.qcCurQuestionId || useNext) { // wenn keine ausschlussfragen oder nächste frage erste eingrenzungsfrage ist
      return this.currentQuestions.get(questions.StartEingrenzungsfrage);
    } else {
      // nächste Frage abhängig von weiter und weiterPrio
      const curQuestion = this.currentQuestions.get(this.qcCurQuestionId);

      if (this.ui_questionType == this.UI_QUESTION_TYPE_RADIO || this.ui_questionType == this.UI_QUESTION_TYPE_SLIDER) {
        if (!!curQuestion.Antworten[this.ui_answerSingleSelection].weiter) {
          return this.currentQuestions.get(curQuestion.Antworten[this.ui_answerSingleSelection].weiter);
        } else {
          for (let i = 0; i < questions.Eingrenzungsfragen.length; i++) {
            if (useNext) {
              return questions.Eingrenzungsfragen[i];
            } else if (questions.Eingrenzungsfragen[i].Id == this.qcCurQuestionId) { // aktuelle Frage gefunden, dann nächste Frage verwenden
              useNext = true;
            }
          }
        }
      } else if (this.ui_questionType == this.UI_QUESTION_TYPE_CHECKBOX) {
        // nächste frage anhand gewählter antworten und prio ermitteln
        {
          let antwort: QuickCheckContainmentAnswer = undefined;
          for (let i = 0; i < this.ui_answerMultiSelection.length; i++) {
            if (this.ui_answerMultiSelection[i]) {
              let weiterPrio = curQuestion.Antworten[i].weiterPrio || Number.MAX_SAFE_INTEGER;
              if (antwort === undefined || (antwort !== undefined && weiterPrio < (antwort.weiterPrio || Number.MAX_SAFE_INTEGER))) {
                antwort = curQuestion.Antworten[i];
              }
            }
          }
          if (!!antwort?.weiter) {
            return this.currentQuestions.get(antwort.weiter);
          }
        }

        // wenn keine antwort geklickt wurde, nach default schauen
        {
          let defaultAnswer = this.getDefaultAnswer(curQuestion);
          if (!!defaultAnswer?.weiter) {
            return this.currentQuestions.get(defaultAnswer.weiter)
          }
        }

        // wenn kein antwort geklickt wurde und kein default vorhanden, nächsten Index verwenden
        {
          for (let i = 0; i < questions.Eingrenzungsfragen.length; i++) {
            if (useNext) {
              return questions.Eingrenzungsfragen[i];
            } else if (questions.Eingrenzungsfragen[i].Id == this.qcCurQuestionId) { // aktuelle Frage gefunden, dann nächste Frage verwenden
              useNext = true;
            }
          }
        }
      }
    }
    return null;
  }

  // Eingrenzung oder Ausschluss
  getQuestionGroup(id: string): QuestionGroup {
    let questions = this.currentQuickcheck
    if (!questions) {
      return null;
    }

    for (let i = 0; i < questions.Ausschlussfragen.length; i++) {
      if (questions.Ausschlussfragen[i].Id == id) {
        return QuestionGroup.ausschluss;
      }
    }
    for (let i = 0; i < questions.Eingrenzungsfragen.length; i++) {
      if (questions.Eingrenzungsfragen[i].Id == id) {
        return QuestionGroup.eingrenzung;
      }
    }
    return null;
  }

  getQuestionById(id: string): QuickCheckContainmentQuestion {
    return this.currentQuestions.get(id)
  }

  getAnswerById(question: QuickCheckContainmentQuestion, antwortId: string): QuickCheckContainmentAnswer {
    return question.Antworten?.find(answer => answer.AntwortId == antwortId)
  }

  getAnswerIndexById(question: QuickCheckContainmentQuestion, antwortId: string): number {
    return question.Antworten?.findIndex(answer => answer.AntwortId == antwortId)
  }

  getDefaultAnswer(question: QuickCheckContainmentQuestion): QuickCheckContainmentAnswer {
    return question.Antworten?.find(answer => !answer.Antwort)
  }

  private createCurSelectionObject(): QcBreadcrumb {
    if (!this.qcCurQuestionId) {
      return null;
    }

    let question = this.currentQuestions.get(this.qcCurQuestionId);
    let answers: string[] = [];
    if (this.ui_questionType == this.UI_QUESTION_TYPE_RADIO || this.ui_questionType == this.UI_QUESTION_TYPE_SLIDER) {
      if (this.ui_answerSingleSelection !== -1) {
        answers.push(question.Antworten[this.ui_answerSingleSelection].AntwortId);
      }
    } else if (this.ui_questionType == this.UI_QUESTION_TYPE_CHECKBOX) {
      for (let i = 0; i < this.ui_answerMultiSelection.length; i++) {
        if (this.ui_answerMultiSelection[i]) {
          answers.push(question.Antworten[i].AntwortId);
        }
      }
    }
    return {
      "Id": question.Id,
      "FrageId": question.FrageId,
      "Antworten": answers
    };
  }

  // ################################################################
  // # RESULT PROCESSING METHODS
  // ################################################################

  createResult(): boolean {
    this.qcResultSuccess = true;
    this.qcResultReasons = [];
    this.qcResultPromotionIds = [];
    this.qcResultPromotions = [];


    let firstEingrenzung: boolean = true;
    for (let iQuestion = 0; iQuestion < this.qcBreadcrumb.length; iQuestion++) {
      let promotions: string[] = [];

      let question = this.currentQuestions.get(this.qcBreadcrumb[iQuestion].Id);
      if (question == null) {
        return false;
      }

      let questionGroup = this.getQuestionGroup(this.qcBreadcrumb[iQuestion].Id);

      if (question.Typ == this.UI_QUESTION_TYPE_RADIO || question.Typ == this.UI_QUESTION_TYPE_SLIDER) {
        let answerId = this.qcBreadcrumb[iQuestion].Antworten[0];
        let answer = this.getAnswerById(question, answerId);
        if (answer == null) {
          return false;
        }
        this.processResultAnswer(questionGroup, answer, promotions);
      } else if (question.Typ == this.UI_QUESTION_TYPE_CHECKBOX) {
        if (this.qcBreadcrumb[iQuestion].Antworten.length > 0) {
          for (let iAnswer = 0; iAnswer < this.qcBreadcrumb[iQuestion].Antworten.length; iAnswer++) {
            let answerId = this.qcBreadcrumb[iQuestion].Antworten[iAnswer];
            let answer = this.getAnswerById(question, answerId);
            if (answer == null) {
              return false;
            }
            this.processResultAnswer(questionGroup, answer, promotions);
          }
        } else {
          let defaultAnswer = this.getDefaultAnswer(question);
          if (!!defaultAnswer) {
            this.processResultAnswer(questionGroup, defaultAnswer, promotions);
          }
        }
      }

      if (questionGroup == QuestionGroup.eingrenzung) {
        if (firstEingrenzung) {
          firstEingrenzung = false;
          this.qcResultPromotionIds = promotions;
        } else {
          let tmpPromotions: string[] = [];
          for (let i = 0; i < this.qcResultPromotionIds.length; i++) {
            if (promotions.includes(this.qcResultPromotionIds[i])) {
              tmpPromotions.push(this.qcResultPromotionIds[i]);
            }
          }
          this.qcResultPromotionIds = tmpPromotions;
        }
      }
    }

    if (this.qcResultPromotionIds.length > 0) {
      for (let i = 0; i < this.qcResultPromotionIds.length; i++) {
        this.loadAndFinishResultPromotion(i, this.qcResultPromotionIds[i]);
      }
    } else {
      this.finishResult();
    }

    return true;
  }

  loadAndFinishResultPromotion(i: number, promotionId: string) {
    this.PromotionService.getPromotionById(promotionId).subscribe((promotion) => {
      this.qcResultPromotions[i] = promotion;
      this.nextPromotionLoaded();
    }, (err) => {
      console.log("PROMOTION: " + promotionId + " NOT FOUND");
      this.qcResultPromotions[i] = null;
      this.nextPromotionLoaded();
    });
  }

  nextPromotionLoaded() {
    let allLoaded: boolean = true;
    for (let j = 0; j < this.qcResultPromotionIds.length; j++) {
      allLoaded &&= this.qcResultPromotions[j] !== undefined;
    }
    if (allLoaded) {
      this.finishResult();
    }
  }

  finishResult() {
    // removed promotions which could not load from server
    if (this.qcResultPromotions.length > 0) {
      let tmpResultPromotions = [];
      for (let i = 0; i < this.qcResultPromotions.length; i++) {
        if (this.qcResultPromotions[i] !== null) {
          tmpResultPromotions.push(this.qcResultPromotions[i]);
        }
      }
      this.qcResultPromotions = tmpResultPromotions;
    }

    this.qcResultSuccess &&= this.qcResultPromotions.length > 0;

    if (this.iosApp) {
      let quickcheck: SimpleQuickcheck = {
        qcTyp: this.currentQuickcheck.id,
        qcBreadcrumb: this.qcBreadcrumb
      };
      this.qcResultDocLink = this.createQuickcheckUri(quickcheck);
    }
  }

  processResultAnswer(questionGroup: QuestionGroup, answer: QuickCheckContainmentAnswer, promotions: string[]) {
    if (questionGroup == QuestionGroup.ausschluss) {
      this.qcResultSuccess &&= !!answer.Wertung;
    } else if (questionGroup == QuestionGroup.eingrenzung) {
      for (let iPromotion = 0; iPromotion < answer.Einschluss?.length ?? 0; iPromotion++) {
        let promotion = answer.Einschluss[iPromotion];
        if (!!promotion && !promotions.includes(promotion)) {
          promotions.push(promotion);
        }
      }
    }

    this.addReason(answer);
  }

  addReason(answer: QuickCheckContainmentAnswer) {
    const reasonHeader = answer.Grundhead;
    const reason = answer.Grund;

    if (!reasonHeader && !reason) {
      return;
    }

    let headerIndex = this.qcResultReasons.findIndex(reasons => reasons.header == reasonHeader)
    if (headerIndex == -1) {
      headerIndex = this.qcResultReasons.push({
        "header": reasonHeader,
        "reasons": []
      }) - 1
    }

    if (!!reason && !this.qcResultReasons[headerIndex].reasons.includes(reason)) {
      this.qcResultReasons[headerIndex].reasons.push(reason);
    }
  }

  writePDF() {
    const doc = new jsPDF();
    let pageHeight = doc.internal.pageSize.height - 20;
    let y = 85;
    const qc_type_pdf_string = this.currentQuickcheck.pdfTitle

    let pageWidth = 200,
      margin = 10,
      maxLineWidth = pageWidth - margin * 2,
      fontSize = 11;

    // Ueberschrift
    const headerline1 = 'Analyse zur Förderfähigkeit.\nDer Telekom Quick-Check „' + qc_type_pdf_string +'“';
    // const headerline1 = 'Der Telekom Fördermittel Quick-Check';
    const headerline2 = 'Auf Grundlage Ihrer Antworten geben wir Ihnen eine Orientierung, von welchen Förderprogrammen Sie\nbei der Umsetzung Ihrer Digitalisierung mit Telekom-Produkten profitieren können.';
    const headerline3 = 'Falls Sie noch Fragen haben, erreichen Sie uns über das Kontaktformular direkt im Anschluss an den\nQuick-Check in der App oder telefonisch unter 0800 330 6001.';
    const headerline4 = 'Besuchen Sie uns auch im Web unter: '
    var splitHeaderline1 = doc.splitTextToSize(headerline1, 150);

    doc.setFont("helvetica", "bold");
    doc.text(splitHeaderline1, 10, 20, { baseline: 'top' }).setFontSize(14).setFont('bold');
    doc.setFont("helvetica", "normal");
    doc.setFontSize(fontSize);
    doc.text(headerline2, 10, 38);
    doc.text(headerline3, 10, 52);
    doc.text(headerline4, 10, 66);
    doc.setTextColor(0, 0, 255);
    doc.setDrawColor(0, 0, 255);
    doc.textWithLink('www.telekom.de/digital-foerderung', 78, 66, { url: 'www.telekom.de/digital-foerderung' });
    const textWidth_link = doc.getTextWidth('www.telekom.de/digital-foerderung');
    doc.line(78, 67, 78 + textWidth_link, 67);
    doc.setTextColor(0, 0, 0);
    doc.setDrawColor(0, 0, 0);


    // WRITE PROMOTIONS
    {

      let text2 = "";
      if (this.qcResultSuccess) {
        let text1 = "Ihre Förderprogramme";
        doc.setFont("helvetica", "bold");
        doc.text(text1, 10, 76);
        doc.setFont("helvetica", "normal");
        const textWidth = doc.getTextWidth(text1);
        doc.line(10, 77, 10 + textWidth + 5, 77);

        for (let i = 0; i < this.qcResultPromotions.length; i++) {
          text2 = "";
          let promotion = this.qcResultPromotions[i];
          //console.log(promotion);
          text2 += "Förderprogramm: " + promotion.name + "\n";
          text2 += "Was: " + promotion.kurztext_simple + "\n";
          var textLines2 = doc.setFont("helvetica").setFontSize(fontSize).splitTextToSize(text2, maxLineWidth);
          for (let x = 0; x < textLines2.length; x++) {
            if (y > pageHeight) {
              y = 30;
              doc.addPage();
            }
            doc.text(textLines2[x], margin, y);
            y += 5;
          }
          let link_text = "Link: ";
          doc.text(link_text, margin, y);
          const url_text = promotion.url.toString().split('/');
          doc.setTextColor(0, 0, 255);
          doc.setDrawColor(0, 0, 255);
          doc.textWithLink(url_text[2], margin + doc.getTextWidth(link_text), y, { url: promotion.url });
          doc.line(margin + doc.getTextWidth(link_text), y + 1, margin + doc.getTextWidth(link_text) + doc.getTextWidth(url_text[2]) + 1, y + 1);
          doc.setTextColor(0, 0, 0);
          doc.setDrawColor(0, 0, 0);
          y += 10;
          doc.text("\n", margin, y);

        }
      }
    }
    // WRITE REASONS
    {
      let text3 = "";
      let texth3 = "";
      if (this.qcResultReasons.length > 0) {
        y = 35;
        doc.addPage();
        texth3 = 'Zusätzliche Hinweise zu den ermittelten Ergebnissen: \n \n';
        doc.setFont("helvetica", "bold");
        doc.text(texth3, 10, y - 10);
        doc.setFont("helvetica", "normal");
        const textWidth = doc.getTextWidth(texth3);
        doc.line(10, y - 9, 10 + textWidth + 5, y - 9);

        for (let iHeader = 0; iHeader < this.qcResultReasons.length; iHeader++) {
          if (this.qcResultReasons[iHeader].header) {
            text3 += this.qcResultReasons[iHeader].header + "\n";
          }

          for (let iReason = 0; iReason < this.qcResultReasons[iHeader].reasons.length; iReason++) {
            if (this.qcResultReasons[iHeader].reasons[iReason]) {
              text3 += this.qcResultReasons[iHeader].reasons[iReason] + "\n";
            }

          }
          if (text3) {
            text3 += "\n";
          }
        }

        var textLines3 = doc.setFont("helvetica").setFontSize(fontSize).splitTextToSize(text3, maxLineWidth);
        if (!!text3) {
          for (let x = 0; x < textLines3.length; x++) {
            if (y > pageHeight) {
              y = 30;
              doc.addPage();
            }
            doc.text(textLines3[x], margin, y);
            y += 5;
          }
        }
      }
    }
    // WRITE Q&A
    {
      y = 35;
      doc.addPage();
      let texttqa = "Telekom - Quick-Check - Ihre Antworten \n \n";
      doc.setFont("helvetica", "bold");
      doc.text(texttqa, 10, y - 10);
      doc.setFont("helvetica", "normal");
      const textWidth = doc.getTextWidth(texttqa);
      doc.line(10, y - 9, 10 + textWidth + 5, y - 9);
      let text1 = "";
      for (let iQuestion = 0; iQuestion < this.qcBreadcrumb.length; iQuestion++) {
        let question = this.currentQuestions.get(this.qcBreadcrumb[iQuestion].Id);
        let questionStr: string = "Frage: " + (iQuestion + 1) + ". " + question.Frage + "\n";
        let hinweisStr: string = question.Hinweis ? question.Hinweis + "\n" : "";
        let answerStr: string = "";

        if (question.Typ == this.UI_QUESTION_TYPE_RADIO || question.Typ == this.UI_QUESTION_TYPE_SLIDER) {
          let answer = this.getAnswerById(question, this.qcBreadcrumb[iQuestion].Antworten[0]);
          if (answer.Antwort.includes('≥ 500')) {
            answerStr += "Antwort: " + ">= 500" + "\n\n";
          } else if (answer.Antwort.includes('≤ 3')) {
            answerStr += "Antwort: " + "<= 3" + "\n\n";
          } else {
            answerStr += "Antwort: " + answer.Antwort + "\n\n";
          }

        } else if (question.Typ == this.UI_QUESTION_TYPE_CHECKBOX) {
          if (this.qcBreadcrumb[iQuestion].Antworten.length > 0) {
            answerStr += answerStr += "Antworten:\n";
            for (let iAnswer = 0; iAnswer < this.qcBreadcrumb[iQuestion].Antworten.length; iAnswer++) {
              let answer = this.getAnswerById(question, this.qcBreadcrumb[iQuestion].Antworten[iAnswer]);
              answerStr += "* " + answer.Antwort + "\n";
            }
          } else if (!!this.getDefaultAnswer(question)) {
            answerStr += answerStr += "Antworten:\n";
            answerStr += "* " + "(keine Angabe)" + "\n";
          } else {
            answerStr += "Antworten: -\n";
          }
          answerStr += "\n";
        }

        text1 += questionStr;
        text1 += hinweisStr;
        text1 += answerStr;
      }

      let textLines1 = doc.setFont("helvetica").setFontSize(fontSize).splitTextToSize(text1, maxLineWidth);
      for (let x = 0; x < textLines1.length; x++) {
        if (y > pageHeight) {
          y = 30;
          doc.addPage();
        }
        doc.text(textLines1[x], margin, y);
        y += 5;
      }
    }

    this.set_header_footer(doc, pageWidth);

    doc.save("Quickcheck.pdf");
  }

  add_header(doc: jsPDF, pageWidth: number) {
    doc.setFillColor(226, 0, 116,);
    doc.rect(0, 0, pageWidth, 15, "F");

    var img = new Image();
    img.src = environment.assetBaseUrl + '/assets/images/T_logo_claim_rgb_n.png'

    let position_img_margin_top = 4.5;
    let imgwidth = 20.3;
    let imgheight = 6;
    //let position_img_margin_left = pageWidth - (imgwidth + 5);
    let position_img_margin_left = 5;
    doc.addImage(img, 'png', position_img_margin_left, position_img_margin_top, imgwidth, imgheight);
  }

  set_header_footer(doc: jsPDF, pageWidth: number) {
    // Header / footer auf jeder Seite
    var pageCount = (<any>doc.internal).getNumberOfPages();
    for (var i = 0; i < pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();


      let aktDate = new Date();

      const datepipe: DatePipe = new DatePipe('en-US');
      let formattedDate = datepipe.transform(aktDate, 'dd.MM.yyyy')


      const footer_kontakt = "Kontakt: Förderhotline: 0800 330 6001";
      const footer_datum = "Datum " + formattedDate;
      const footer_pagenumber = "Seite " + (<any>doc.internal).getCurrentPageInfo().pageNumber + " von " + pageCount;
      //doc.text(10, 10, doc.internal.getCurrentPageInfo().pageNumber + "/" + pageCount);

      // Header

      this.add_header(doc, pageWidth);

      // Footer
      doc.setFontSize(8);
      doc.text(footer_kontakt, 10, pageHeight - 5, { baseline: 'bottom' }).setFontSize(8);
      doc.text(footer_datum, pageWidth / 2 - (doc.getTextWidth(footer_datum) / 2), pageHeight - 5, { baseline: 'bottom' }).setFontSize(8);
      doc.text(footer_pagenumber, pageWidth - ((doc.getTextWidth(footer_pagenumber) + 15)), pageHeight - 5, { baseline: 'bottom' }).setFontSize(8);
    }
  }

  private createQuickcheckUri(quickcheck: SimpleQuickcheck): string {
    const qcUri = window.location.protocol + '//' + window.location.host + '/pdf/';

    const q = quickcheck.qcBreadcrumb.map(question => `${question.Id}:` + question.Antworten.join(',')).join(';')
    return `${qcUri}?instantDownload=true&t=${encodeURIComponent(quickcheck.qcTyp)}&q=${encodeURIComponent(q)}`
  }

  // ################################################################
  // # PROGRESS BAR METHODS
  // ################################################################

  initGroups(): void {
    this.qcGroups = [];

    const qc = this.currentQuickcheck
    if (qc) {
      for (let group of qc.tabIDs) {
        this.qcGroups.push(group);
      }

      qc.Ausschlussfragen.forEach(question => this.currentQuestions.set(question.Id, question))
      qc.Eingrenzungsfragen.forEach(question => this.currentQuestions.set(question.Id, question))
    }

    this.getActiveGroupIndex()
  }


  getActiveGroupIndex(): number {
    let activeTabID: string = null;
    if (this.qcCurQuestionId) {
      let question = this.currentQuestions.get(this.qcCurQuestionId);
      activeTabID = question.tabID;
    }

    let count: number = 0;
    for (let group of this.qcGroups) {
      count++;
      if (group.tabID == activeTabID) {
        this.qcActiveTab$.next(count - 1)
        break;
      }
    }

    return count;
  }

  getGroupById(tabID: string): QuickCheckTab | null {
    for (let group of this.qcGroups) {
      if (group.tabID == tabID) {
        return group;
      }
    }
    return null;
  }

  getActiveGroupName(): string {
    let questionId = this.qcCurQuestionId;
    if (!!questionId) {
      let question = this.currentQuestions.get(questionId);
      let qcGroup: QuickCheckTab = this.getGroupById(question.tabID);
      if (qcGroup) {
        return qcGroup.Text;
      }
    }
    return this.qcGroups[this.qcGroups.length - 1].Text;
  }

  // ################################################################
  // # UI METHODS
  // ################################################################

  setUIState(state: QuickCheckUIState) {
    this.ui_state = state;
  }

  event_startQuickcheck() {
    this.setUIState(QuickCheckUIState.entry);

    this.utag_viewEinsteig();
    
    this.ui_answers = this.quickchecks.map(qc => ({ Antwort: qc.introAnswer, AntwortId: qc.id }))
    this.ui_answerSingleSelection = this.quickchecks.findIndex(qc => qc.id == this.currentQuickcheck?.id)

    this.scrollToTop();
  }

  event_submitEntryAnswer() {
    const qc = this.quickchecks[this.ui_answerSingleSelection]

    this.utag.aktion(Aktionsbereich.Content, Style.Button, "naechste-frage");
    this.utag.aktion(Aktionsbereich.Content, Style.Radio, qc.id);

    this.setQcTyp(qc.id);

    this.setUIState(QuickCheckUIState.quickcheck);
    this.nextQuickcheckQuestion();
  }

  showQuestion(question: QuickCheckContainmentQuestion) {
    this.utag_viewFrage(this.currentQuickcheck.id, question);
    this.ui_question = question.Frage;
    this.ui_questionSub = question.Hinweis || "";
    this.ui_questionType = question.Typ;
    this.ui_answers = [];
    this.ui_answerMultiSelection = [];
    for (let i = 0; i < question.Antworten.length; i++) {
      this.ui_answers.push(question.Antworten[i]);
      if (this.ui_questionType == this.UI_QUESTION_TYPE_CHECKBOX) {
        this.ui_answerMultiSelection[i] = false;
      }
    }
    this.ui_answerSingleSelection = -1;
  }

  showAnswerSelection() {
    let questionId = this.qcCurQuestionId;
    if (!!questionId) {
      let question = this.currentQuestions.get(questionId);
      let selection = this.qcSelections.get(questionId);
      if (!!selection) {
        if (this.ui_questionType == this.UI_QUESTION_TYPE_RADIO || this.ui_questionType == this.UI_QUESTION_TYPE_SLIDER) {
          if (!!selection.Antworten[0]) {
            this.ui_answerSingleSelection = this.getAnswerIndexById(question, selection.Antworten[0]);
          }
        } else if (this.ui_questionType == this.UI_QUESTION_TYPE_CHECKBOX) {
          for (let i = 0; i < selection.Antworten.length; i++) {
            this.ui_answerMultiSelection[this.getAnswerIndexById(question, selection.Antworten[i])] = true;
          }
        }
      }
    }
  }

  event_checkedChanged(isChecked: boolean, id: number) {
    this.ui_answerMultiSelection[id] = isChecked;
  }

  isAnyCheckboxChecked() {
    let anyChecked: boolean = false;
    for (let i = 0; i < this.ui_answerMultiSelection.length; i++) {
      anyChecked = anyChecked || (this.ui_answerMultiSelection[i] === true);
    }
    return anyChecked;
  }

  event_nextQuestion() {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "naechste-frage");
    let question = this.currentQuestions.get(this.qcCurQuestionId);
    let qa = this.createCurSelectionObject();
    if (question.Typ == this.UI_QUESTION_TYPE_RADIO || question.Typ == this.UI_QUESTION_TYPE_SLIDER) {
      this.utag.aktion(Aktionsbereich.Content, Style.Radio, qa.Antworten[0]);
    } else if (question.Typ == this.UI_QUESTION_TYPE_CHECKBOX) {
      for (let answer of qa.Antworten) {
        this.utag.aktion(Aktionsbereich.Content, Style.Checkbox, answer);
      }
    }

    this.nextQuickcheckQuestion();
    this.scrollToTop();
  }

  event_prevQuestion() {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "vorherige-frage");
    this.prevQuickcheckQuestion();
    this.scrollToTop();
  }

  event_resultRestartQuickcheck() {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "neu-starten");
    this.resetQuickcheckStatus();
    this.event_startQuickcheck();
  }

  event_resultPrevQuestion() {
    this.setUIState(QuickCheckUIState.quickcheck);
    this.qcFinished = false;
    this.event_prevQuestion();
  }

  event_resultIntro() {
    this.resetQuickcheckStatus()
    this.setUIState(QuickCheckUIState.intro);
  }

  scrollToTop() {
    let ele = document.getElementById("quickcheck-container") as HTMLElement;
    if (ele) {
      ele.scrollIntoView();
    }
  }

  closeWindow() {
    window.close()
  }

  // ################################################################
  // # UTILS
  // ################################################################

  resetQuickcheckStatus() {
    this.qcFinished = false;
    this.currentQuickcheck = undefined;
    this.qcCurQuestionId = null;
    this.qcBreadcrumbIndex = -1;
    this.qcBreadcrumb = [];
    this.qcSelections = new Map();

    this.writeQuickcheckStatus();
  }

  readQuickcheckStatus() {
    let statusStorage: string | null = localStorage.getItem("quickcheck_status");
    if (statusStorage === null) {
      this.resetQuickcheckStatus();
    } else {
      let status = JSON.parse(statusStorage);
      this.qcFinished = status.qcFinished;
      this.currentQuickcheck = this.quickchecks.find(qc => qc.id == status.qcTyp);
      this.initGroups();
      this.qcCurQuestionId = status.qcCurQuestionId;
      this.qcBreadcrumbIndex = status.qcBreadcrumbIndex;
      this.qcBreadcrumb = status.qcBreadcrumb;
      this.qcSelections = new Map(Object.entries(status.qcSelections));
    }
  }

  writeQuickcheckStatus() {
    let status = {
      "qcFinished": this.qcFinished,
      "qcTyp": this.currentQuickcheck?.id,
      "qcCurQuestionId": this.qcCurQuestionId,
      "qcBreadcrumbIndex": this.qcBreadcrumbIndex,
      "qcBreadcrumb": this.qcBreadcrumb,
      "qcSelections": Object.fromEntries(this.qcSelections)
    }

    localStorage.setItem("quickcheck_status", JSON.stringify(status));
  }

  utag_startQuickcheckClick(): void {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "jetzt-foerderfaehigkeit-pruefen");
  }

  utag_downloadPdfClick(): void {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "pdf-herunterladen");
  }

  utag_contactClick(): void {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "kontakt-und-naechste-schritte");
  }

  utag_promotionClick(promotion: Promotion): void {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "foerderprogramme", promotion.id);
  }

  utag_viewQuickCheck(): void {
    this.utag.view({
      type: "quickcheck", // individuell setzen
      navigationLevels: ["quick-check"]
    });
  }

  utag_viewEinsteig(): void {
    this.utag.view({
      type: "quickcheck", // individuell setzen
      navigationLevels: ["quick-check", "einstieg"]
    });
  }

  utag_viewFrage(qcTyp: string, frage: QuickCheckContainmentQuestion): void {
    this.utag.view({
      type: "quickcheck", // individuell setzen
      navigationLevels: ["quick-check", qcTyp, frage.FrageId]
    });
  }

  utag_viewResult(qcTyp: string): void {
    this.utag.view({
      type: "quickcheck", // individuell setzen
      navigationLevels: ["quick-check", qcTyp, "ergebnis"]
    });
  }

  onImageNotFound(event: Event) {
    (event.target as HTMLImageElement).src = this.environment.assetBaseUrl + '/assets/images/quickchecks/quickcheck-default.png'
  }
}
