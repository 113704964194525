import { forwardRef, Injectable, Input, Output, Provider, Type } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export abstract class ScrollPositionBase {

  @Output() abstract scrollTopChange: Observable<number>
  @Input() abstract scrollTop: number

}

export function provideScrollPosition<T extends ScrollPositionBase>(component: Type<T>) : Provider {
  return { provide: ScrollPositionBase, useExisting: forwardRef(() => component) }
}