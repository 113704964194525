<mat-form-field appearance="outline" color="accent" floatLabel="auto">
  <mat-label>{{filter.displayName}}</mat-label>
  <mat-select [formControl]="itemMultiCtrl" multiple="true" #multiSelect>
    <mat-select-trigger>
      <mat-chip-listbox>
        <mat-chip-option 
          *ngFor="let selectedValue of itemMultiCtrl.value"
          selectable="false"
          [removable]="true"
          (removed)="onSelectedRemove(selectedValue)"
        >
          {{ selectedValue.displayName }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [showToggleAllCheckbox]="true"
                             [toggleAllCheckboxIndeterminate]="isIndeterminate"
                             [toggleAllCheckboxChecked]="isChecked"
                             (toggleAll)="toggleSelectAll($event)"
                             [formControl]="itemMultiFilterCtrl"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItemsMulti | async" [value]="item">
      <span class="option-name">{{item.displayName}}</span>
    </mat-option>
  </mat-select>
</mat-form-field>