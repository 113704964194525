<mf-animated-header>
</mf-animated-header>

<scroll-shadow class="full-width">
  <div class="scroll-container full-width">
    <div class="container">
      <div class="row justify-content-center">
        <div class="m-t-16 m-b-16 col-12 col-lg-8">
          <form [formGroup]="registerForm" (ngSubmit)="register()">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Legen Sie sich jetzt einen Account an</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Firma</mat-label>
                  <input matInput type="text" formControlName="corporation" required>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Titel</mat-label>
                  <input matInput type="text" formControlName="title">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Ihr Vorname</mat-label>
                  <input matInput type="text" formControlName="givenName" required>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Ihr Nachname</mat-label>
                  <input matInput type="text" formControlName="familyName" required>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Ihre Email</mat-label>
                  <input matInput type="email" formControlName="email" placeholder="email@example.com" required>
                  <mat-error class="m-b-16" *ngIf="registerForm.controls.email.errors?.['email']">
                    Das Format der E-Mail-Adresse ist falsch. Bitte im Format email@example.com angeben.
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Ihre Postleitzahl</mat-label>
                  <input matInput type="text" formControlName="postalCode" required>
                  <mat-error *ngIf="registerForm.get('postalCode').hasError('required')">Dieses Feld ist ein Pflichtfeld.</mat-error>
                </mat-form-field>

                <p class="text_inputs">*Pflichtfelder</p>

                <p class="info-container error" *ngIf="registrationError">
                  {{registrationError}}
                </p>
              </mat-card-content>
              <mat-card-actions align="end">
                <button mat-flat-button color="accent" type="submit" [disabled]="!registerForm.valid || registerForm.disabled" [trackClick]="['content', 'button', 'registrierung-abschliessen']">Registrierung abschliessen</button>
              </mat-card-actions>
            </mat-card>
          </form>
        </div>
      </div>

    </div>
  </div>
</scroll-shadow>

