import { Component, Input } from '@angular/core';

@Component({
  selector: 'mf-simple-product-card',
  templateUrl: './simple-product-card.component.html',
  styleUrls: ['./simple-product-card.component.scss']
})
export class SimpleProductCardComponent {
  @Input() title : string;
  @Input() url : string;
}
