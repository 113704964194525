<mat-card *ngIf="showCard | async" class="m-b-12">
  <mat-card-header>
    <mat-card-title>Meine Förderung als App</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      Suchen und verwalten Sie Ihre Förderprogramme doch in unserer App.
      Mit Klick auf das App-Store-Icon gelangen Sie direkt zur App
    </p>
    <ng-container *ngIf="isAndroid; then android; else ios"></ng-container>
  </mat-card-content>
  <mat-card-action class="mf-card-actions space-between">
    <mat-checkbox [(ngModel)]="dontShowAgain">Nicht mehr anzeigen.</mat-checkbox>
    <button (click)="closeCard()" mat-flat-button color="primary" >Schließen</button>
  </mat-card-action>
</mat-card>

<ng-template #android>
  <div class="badge"><a href="{{appstoreUrl}}"><img src="/assets/images/google-play-badge.png" alt="App bei Google Play laden"></a></div>
  <div class="legal">Google Play und das Google Play-Logo sind Marken von Google LLC.</div>
</ng-template>

<ng-template #ios>
  <div class="badge"><a href="{{appstoreUrl}}"><img src="/assets/images/apple-appstore_logo.png" alt="App im App Store laden"></a></div>
  <div class="legal">App Store und das Apple-Logo sind Marken von Apple Inc.</div>
</ng-template>
