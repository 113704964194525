import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { CarouselItem } from '../components/carousel/carousel.component';

//über diesen Service können sich die JSONs bezüglich aller NEWS-Bereiche von der Startseite geholt werden.
//Aktuell folgende:
//banner_foerderprogramme.json -> erster Bereich oben auf der Startseite
//newsfeed.json -> für den Bereich "Aktuelle Informationen"
//highlight.json -> für den Bereich "Unsere Empfehlungen"

export interface NewsBanner {
  id: string;
  route: string;
  subtitle: string;
  title: string;
}

export interface News {
  neuigkeit: string;
  titel: string;
}

export interface NewsHighlight {
  buttonText: string;
  headline: string;
  id: string;
  route: string;
}


export interface Empfehlung {
  title: string;
  button: {
    text: string;
    route: string;
  };
  imgUrl: string
}
@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private http: HttpClient
  ) { }
  get_all_news():Observable<CarouselItem[]>{
    return this.http.get<NewsBanner[]>(environment.backendUrl + '/news/banner').pipe(
      map(itemData => {
        return itemData.map(item => {
            return {
              title: item.title,
              link: item.route,
              imgUrl: environment.backendUrl  + '/resources/banner/' + item.id + '.jpg',
              isExternal: item.route.includes('https://')
            }
        })
      })
    );
  }
  get_akt_information():Observable<News[]>{
    return this.http.get<any>(environment.backendUrl + '/news');
  }
  get_highlights_information():Observable<Empfehlung[]>{
    return this.http.get<NewsHighlight[]>(environment.backendUrl + '/news/highlights').pipe(
    map(itemData => {
      return itemData.map(item => {
          return {
            title: item.headline,
            button: {
              text: item.buttonText,
              route: item.route
            },
            imgUrl: environment.backendUrl  + '/resources/highlight/' + item.id + '.jpg'
          }
      })
    })
  )
  }
}
