<ng-container *ngIf="($loadingStatus | async) !== 'error'; else error">
  <ng-container *ngLet="search$ | async as search">
    <mf-animated-header>
      <mf-search-input label="Thema, Bereich, Bundesland eingeben" [value]="search.query" (searchValue)="onSearchChange($event)"></mf-search-input>
    </mf-animated-header>
    <ng-container *ngLet="result$ | async as result">
      <ng-container>
        <div class="container" #contentArea>
          <div class="row" >
            <div class="col-12 m-t-8">
              <div class="action-bar">
                <button class="m-b-16" *ngLet="search.filter | keyvalue as filter" [disabled]="($loadingStatus | async) !== 'success'" mat-stroked-button [matBadge]="filter.length" [matBadgeHidden]="filter.length === 0" [overlayTriggerFor]="filterOverlay">
                  <span>Filter & Sortierung</span>
                  <mat-icon iconPositionEnd>tune</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="m-t-8 m-b-8 col-12 col-lg-8">
              <ng-template #resultCount>
                <div class="result-count">{{result.length}} Ergebnisse</div>
              </ng-template>
              <div *ngIf="($loadingStatus | async) !== 'success'; else resultCount" class="result-count">Lade Ergebnisse</div>
            </div>
          </div>
        </div>
        <scroll-shadow>
          <div
            class="scroll-container"
            mfScrollPosition
            onlyTop
            #scrollContainer
          >
            <div class="container" #contentArea>
              <div class="row justify-content-center">
                <div class="m-t-8 col-12 col-lg-8">
                  <mat-list *ngIf="($loadingStatus | async) === 'success'; else loadingList">
                    <virtual-scroller
                      #scroller
                      [items]="result"
                      [enableUnequalChildrenSizes]="true"
                      [parentScroll]="scrollContainer"

                      mfRestoreScrolling="promotions"
                      waitForScroller="true"
                      [scrollerLoaded]="($loadingStatus | async) === 'success'"
                    >
                      <mf-list-card
                        *ngFor="let promotion of scroller.viewPortItems"
                        [highlight]="promotion.productCount > 0"
                        [routerLink]="promotion.id"
                        title="{{promotion.name}}"
                        [icon]="promotion.favoriten ? 'bookmark' : null"
                        [trackClick]="['content','button','suche', null, { 'search_engine_clickitem': promotion.name + '_' + urlForPromotion(promotion.id) }]"
                      >
                        <mf-content-row *ngIf="promotion.foerderberechtigte" labelMinWidth="30px" label="Wer:" content="{{promotion.foerderberechtigte}}"></mf-content-row>
                        <mf-content-row *ngIf="promotion.foerderbereich" labelMinWidth="30px" label="Was:" content="{{promotion.foerderbereich}}"></mf-content-row>
                        <mf-content-row *ngIf="promotion.foerdergebiet" labelMinWidth="30px" label="Wo:" content="{{promotion.foerdergebiet}}"></mf-content-row>
                      </mf-list-card>
                      <div class="spacer"></div>
                    </virtual-scroller>
                  </mat-list>
                </div>
              </div>
            </div>
          </div>
        </scroll-shadow>
        <mf-dynamic-overlay [fullSize]="true" maxWidth="380px" [toLeft]="true" [hasBackdrop]="true" [closeOnBackdropClick]="true" #filterOverlay>
          <div class="filter-overlay">
              <div class="header">
                <span class="overlay-title">Filter & Sortierung</span>
                <button (click)="filterOverlay.close()" class="close" mat-icon-button  aria-label="Filter schließen">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <div class="content">
                <div class="sortierungs-container m-t-16">
                  <mat-form-field class="sort-select" appearance="outline" color="accent" floatLabel="auto">
                    <mat-label>Sortieren nach </mat-label>
                    <mat-select name="property" [value]="search.sort.property" (selectionChange)="setSorting($event.value)">
                      <mat-option *ngFor="let sorting of searchSortings" [value]="sorting.property">{{sorting.displayName}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="sort-select" appearance="outline" color="accent" floatLabel="auto">
                    <mat-label>Sortierrichtung</mat-label>
                    <mat-select name="direction" [value]="search.sort.direction ?? 'Asc'" (selectionChange)="setSortingDirection($event.value)">
                      <mat-option [value]="'Asc'">Aufsteigend</mat-option>
                      <mat-option [value]="'Desc'">Absteigend</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <hr class="m-t-24 block m-b-12">
                <div class="m-b-16 reset-button-wrapper">
                  <button (click)="resetFilter()" mat-button >
                    Alle Filter zurücksetzen
                    <mat-icon iconPositionEnd inline="true">backspace</mat-icon>
                  </button>
                </div>
                <ng-container *ngFor="let filter of searchFilters | async; let i = index">
                  <ng-container [ngSwitch]="filter.type">
                    <mf-multi-select *ngSwitchCase="'comma'" [filter]="filter" [selected]="search.filter[filter.property]" (valueChanged)="setFilter($event)"></mf-multi-select>
                    <mf-single-select *ngSwitchDefault [filter]="filter" [selected]="search.filter[filter.property]" (valueChanged)="setFilter($event)"></mf-single-select>
                  </ng-container>
                </ng-container>
              </div>
              <div class="footer">
                <button (click)="filterOverlay.close()" mat-flat-button color="primary" class="full-width">
                  <span *ngIf="($loadingStatus | async) === 'loading'; else resultText">Lade Ergebnisse</span>
                  <ng-template #resultText>{{result.length}} Ergebnisse anzeigen</ng-template>
                </button>
              </div>
          </div>
        </mf-dynamic-overlay>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingList>
  <mat-list>
    <span role="status" class="cdk-visually-hidden">Lade den Ergebnisliste</span>
    <mf-skeleton-loader *repeatTimes="4" height="120px"></mf-skeleton-loader>
    <div class="spacer"></div>
  </mat-list>
</ng-template>

<ng-template #error>
  <mf-animated-header></mf-animated-header>
  <mf-loader-error class="block m-t-24"></mf-loader-error>
</ng-template>
