import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
} from '@angular/core';

export class NgLetContext {
  $implicit: any = null;
  ngLet: any = null;
}

/**
 * Directive to create local context in templates without additional template logic.
 * 
 * This directive is almost equivalent with *ngIf with the only difference, that its
 * context is not being used for additional template logic like showing or hiding the
 * content based on the boolean result of it.
 * 
 * Use case:
 * - create a local variable from a complex object
 * 
 *   ```
 *     <ng-container *ngLet="property.myValue as value"></ng-container>
 *   ```
 * 
 * - use pipes
 * 
 *   ```
 *     <ng-container *ngLet="property | async as value"></ng-container>
 *   ```
 * 
 * - combine multiple properties to a local object
 * 
 *   ```
 *     <ng-container *ngLet="{ prop1: property | async, prop2: anotherProperty } as props"></ng-container>
 *   ```
 * 
 * - keep context of a template to make ViewChild decorators work also with templates.
 * 
 *   Template:
 *   ```
 *     <ng-container *ngLet="property | async as prop">
 *       <ng-container *ngTemplateOutlet="template"></ng-container>
 *     </ng-container>
 * 
 *     <ng-template #template>
 *       <div #myReference></div>
 *     </ng-template>
 *   ```
 *   
 *   Component:
 *   ```
 *      \@ViewChild('myReference') templateRef: TemplateRef
 *   ```
 */
@Directive({
  selector: '[ngLet]',
})
export class NgLetDirective implements OnInit {
  private _context = new NgLetContext();

  @Input()
  set ngLet(value: any) {
    this._context.$implicit = this._context.ngLet = value;
  }

  constructor(
    private _vcr: ViewContainerRef,
    private _templateRef: TemplateRef<NgLetContext>
  ) {}

  ngOnInit() {
    this._vcr.createEmbeddedView(this._templateRef, this._context);
  }
}