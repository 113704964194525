import { EventEmitter } from "@angular/core";
import { Filter, FilterValue, SearchFilter } from "../model";

export abstract class SelectFilter {

  filter: SearchFilter;
  selected: FilterValue;

  valueChanged: EventEmitter<Filter> = new EventEmitter();

  public abstract resetSelection(): void

}