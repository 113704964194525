<mf-animated-header>
</mf-animated-header>

<scroll-shadow class="full-width">
  <div class="scroll-container full-width">
    <div class="container">
      <div class="row justify-content-center">
        <div class="m-t-16 m-b-16 col-12 col-lg-8">
          <div class="profile-data">
              <mat-icon inline="false">account_circle</mat-icon>
              <h2>{{user_name}}</h2>
              <button (click)="logout()" mat-stroked-button>Abmelden</button>
          </div>
          <mat-expansion-panel class="m-t-16" expanded="true">
            <mat-expansion-panel-header class="mf-expansion-panel-header">
                <mat-panel-title>
                    Profildaten
                </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="panel-spacer m-b-24">
            <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
              <mat-label>Ihr Name</mat-label>
              <input matInput type="text" readonly="true" [(ngModel)]="user_name" name="username" #username="ngModel">
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
              <mat-label>Ihre Email</mat-label>
              <input matInput type="text" readonly="true"  [(ngModel)]="user_email" name="email" #email="ngModel">
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
              <mat-label>Ihre Postleitzahl</mat-label>
              <input matInput type="text" readonly="true" [(ngModel)]="user_plz" name="plz" #plz="ngModel">
            </mat-form-field>

            <button (click)="deleteUserClick()" mat-flat-button class="mf-button">Dieses Profil löschen</button>
          </mat-expansion-panel>
        </div>
      </div>

    </div>
  </div>
</scroll-shadow>


<ng-template #deleteProfileDialogue>
  <h2 mat-dialog-title>Sind Sie sicher, dass Sie Ihr Profil löschen möchten?</h2>
  <mat-dialog-content>
    Das Löschen des Profils ist nicht widerrufbar. Sind Sie sich sicher, dass Sie das Profil mit den Anmeldeinformationen des Telekom Business Login auch für keine weiteren Anwendung benötigen?
    <mat-checkbox class="m-t-16" [checked]="confirmDeletion" (change)="confirmDeletion=!confirmDeletion">Ja, ich bin mir sicher und möchte mein Profil löschen.*</mat-checkbox>
  </mat-dialog-content>
  <mat-dialog-actions class="flex-between">
    <button mat-flat-button class="mf-button" mat-dialog-close>Abbrechen</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-flat-button color="warn" [disabled]="!confirmDeletion" mat-dialog-close>Profil Löschen</button>
  </mat-dialog-actions>
</ng-template>
