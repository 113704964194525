import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { LoginComponent } from "./login/login.component";
import { MatOptionModule } from "@angular/material/core";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { ComponentsModule } from "../components";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginService } from "./login.service";
import { OAuthModule } from "angular-oauth2-oidc";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { UserService } from "./user.service";
import { OIDCService } from "./oidc.service";
import { LayoutModule } from "../components/layout";
import { MatInputModule } from "@angular/material/input";
import { RegistrationComponent } from "./registration/registration.component";

@NgModule({
  providers: [
    OIDCService
  ]
})
export class InternalLoginModule {}

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent
  ],
  exports: [
    LoginComponent,
    RegistrationComponent
  ],
  imports: [
    CommonModule,
    MatOptionModule,
    MatCardModule,
    MatSelectModule,
    MatDividerModule,
    MatListModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    ComponentsModule,
    LayoutModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          window.location.protocol + '//' + window.location.host + '/api', // absolute URLs
          '/api' // relative URLs
        ],
        sendAccessToken: true
      }
    }),
    InternalLoginModule
  ],
  providers: [
    LoginService,
    UserService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoginModule {}
