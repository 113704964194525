import { Injectable } from '@angular/core';
import { LoginService, UserService } from '../../../login';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, scan, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

//über diesen Service können User (wenn angemeldet) Förderprogramme abspeichern (Favoritenliste)

interface FavoriteUpdate {
  type: "add" | "remove",
  items: string[]
}

@Injectable()
export class FavoriteService {

  private favoriteItems: Observable<Set<string>>
  private favoriteUpdates = new Subject<FavoriteUpdate>()

  constructor(
    private loginService: LoginService,
    private userService: UserService
  ) {
    this.favoriteItems = this.loginService.isLoggedIn.pipe(
      // If we are logged in, we...
      switchMap(isLoggedIn => isLoggedIn ?
        // try to fetch the favorites from the user profile
        this.userService.restoreFavorites().pipe(
          map(favorite => new Set([...favorite])),
          switchMap(favorite => this.favoriteUpdates.pipe(
            scan((favorite, update) => {
              switch(update.type) {
                case 'add': update.items.forEach(item => favorite.add(item)); break;
                case 'remove': update.items.forEach(item => favorite.delete(item)); break;
                default: break;
              }

              return favorite
            }, favorite),
            // save any changes directly back to the profile
            switchMap(favorite => this.save(favorite)),
            tap(favorites => console.log(favorites)),
            // and start with the fetched list, if there are no changes.
            startWith(favorite)
          ))
        ):
        // else we return an empty array.
        of(new Set<string>())
      ),
      shareReplay(1)
    )
  }

  get(): Observable<Set<string>> {
    return this.favoriteItems
  }

  add(promotionId: string) {
    this.favoriteUpdates.next({
      type: 'add',
      items: [promotionId]
    })
  }

  remove(promotionId: string) {
    this.favoriteUpdates.next({
      type: 'remove',
      items: [promotionId]
    })
  }

  private save(favorites: Set<string>): Observable<Set<string>> {
    return this.userService.saveFavorites([...favorites]).pipe(
      map(() => favorites),
      catchError(() => of(favorites))
    )
  }

}
