import { Component, OnInit, ViewChild, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { OverlayRef, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { trigger, style, transition, animate, AnimationEvent, keyframes } from '@angular/animations';
import { OverlayEvents } from './overlay-events.enum';
import { Observable, Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ToBoolean } from '../../utilities/decorators';

const ANIMATION_TIMINGS = '250ms linear';

@Component({
  selector: 'mf-dynamic-overlay',
  templateUrl: './dynamic-overlay.component.html',
  styleUrls: ['./dynamic-overlay.component.scss'],
  animations: [
    trigger('slideContent', [
      transition("void => enter", [
        animate(ANIMATION_TIMINGS, keyframes([
          style({ transform: 'translateY(100%)' }),
          style({ transform: 'translateY(0)' })
        ]))
      ]),
      transition("enter => leave", [
        animate(ANIMATION_TIMINGS, keyframes([
          style({ transform: 'translateY(0)' }),
          style({ transform: 'translateY(100%)' })
        ]))
      ]),
      transition("void => enterRight", [
        animate(ANIMATION_TIMINGS, keyframes([
          style({ transform: 'translateX(100%)' }),
          style({ transform: 'translateX(0)' })
        ]))
      ]),
      transition("enterRight => leaveRight", [
        animate(ANIMATION_TIMINGS, keyframes([
          style({ transform: 'translateX(0)' }),
          style({ transform: 'translateX(100%)' })
        ]))
      ])
    ])
  ]
})
export class DynamicOverlayComponent implements OnInit {

  private dynamicOverlayOpened:  boolean = false;
  private dynamicOverlayRef:     OverlayRef;

  public animationState: 'enter' | 'leave' | 'enterRight' | 'leaveRight' = 'enter';
  public animationStateChanged = new EventEmitter<AnimationEvent>();
  private overlayHeight:         number;
  public offset : number = 0;

  private subscriptions : Subscription[] = []
  private toggleSubscription: Subscription
  private breakpointSubscription: Subscription;
  private routerSubscription: Subscription;

  @Input() @ToBoolean() fullSize: boolean;
  @Input() @ToBoolean() center: boolean;
  @Input() @ToBoolean() toLeft: boolean;
  @Input() @ToBoolean() hasBackdrop: boolean;
  @Input() @ToBoolean() closeOnBackdropClick: boolean;
  @Input() @ToBoolean() darkBackground: boolean;
  @Input() @ToBoolean() hasButtonPill: boolean;
  @Input() @ToBoolean() noPadding: boolean;
  @Input() attachTo : ElementRef

  @Input() set toggleOverlay(toggle: Observable<OverlayEvents>) {
    this.subscribeToToggle(toggle)
  };

  @Input() animate : boolean = true;
  @Input() maxWidth : string;

  @Input()
  set openSelf(open: boolean) { open ? this.open() : this.close() };

  @ViewChild('dynamicOverlay') dynamicOverlay: CdkPortal;
  @Output() overlayClosed : EventEmitter<boolean> = new EventEmitter()

  constructor(
    private overlay: Overlay,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.animationStateChanged.subscribe(event => {
        if (event.toState === 'leave' && event.fromState === 'enter' || event.toState === 'leaveRight' && event.fromState === 'enterRight') {
          this.closeDynamicOverlay();
        }
      })
    )
  }

  handleOverlayMovement(distance : number) {
    this.offset = distance;
    if (this.offset > (this.overlayHeight / 1.8)) {
      this.closeDynamicOverlay();
    }
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation() {
    this.animationState = this.toLeft ? 'leaveRight' : 'leave';
  }

  ngOnDestroy() {
    if (this.dynamicOverlayRef) {
      this.dynamicOverlayRef.dispose();
    }
    while(this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe()
    }
  }

  public isOpen(): boolean {
    return this.dynamicOverlayOpened
  }

  public open() {
    this.openDynamicOverlay()
  }

  public close(force: boolean = false) {
    force ? this.closeDynamicOverlay() : this.startExitAnimation()
  }

  private subscribeToToggle(toggle: Observable<OverlayEvents>) {
    this.toggleSubscription?.unsubscribe()
    this.toggleSubscription = toggle.subscribe(event => {
      switch (event) {
        case OverlayEvents.forceClose: {
          this.closeDynamicOverlay();
          break;
        }
        case OverlayEvents.close: {
          this.startExitAnimation();
          break;
        }
        case OverlayEvents.open: {
          this.openDynamicOverlay();
          break;
        }
        default:
          break;
      }
    })
  }

  private openDynamicOverlay() {
    this.closeDynamicOverlay()

    this.animationState = this.toLeft ? 'enterRight' : 'enter';
    this.dynamicOverlayOpened = true;
    const config = new OverlayConfig();

    if (this.closeOnBackdropClick || this.hasBackdrop || this.fullSize) {

      let currentRoute = this.router.routerState.snapshot.url

      this.routerSubscription = this.router.events.pipe(
        map(event => {
          if (event instanceof NavigationStart) {
            if (event.navigationTrigger === 'popstate' && this.dynamicOverlayOpened) {
              this.router.navigateByUrl(currentRoute, { skipLocationChange: true });
              history.pushState(null, null, currentRoute)
              this.closeDynamicOverlay()
            }
          }
        })
      ).subscribe()
    }

    config.hasBackdrop         = (this.closeOnBackdropClick || this.hasBackdrop || this.fullSize) && !this.attachTo;
    config.height              = this.fullSize ? '100vh' : 'auto';
    config.maxHeight           = '100vh';
    config.width               = '100vw';
    config.disposeOnNavigation = true;
    config.panelClass          = this.center ? ['dynamic-overlay','center'] : ['dynamic-overlay'];

    config.maxWidth = this.maxWidth;
    config.backdropClass       = this.closeOnBackdropClick && !this.hasBackdrop ? 'cdk-overlay-transparent-backdrop' : "overlay-backdrop"

    config.positionStrategy = this.toLeft ? this.overlay.position().global().right().top() : this.overlay.position().global().centerVertically().bottom()

    this.dynamicOverlayRef = this.overlay.create(config);
    this.dynamicOverlayRef.attach(this.dynamicOverlay);

    this.overlayHeight = this.dynamicOverlayRef.overlayElement.offsetHeight;
    this.animationState = this.toLeft ? 'enterRight' : 'enter';

    if (this.closeOnBackdropClick) {
      this.dynamicOverlayRef.backdropClick().subscribe(() => {
        this.startExitAnimation()
      })
    }
  }

  private closeDynamicOverlay() {
    if(this.dynamicOverlayOpened) {
      if (this.closeOnBackdropClick || this.hasBackdrop || this.fullSize) {
        this.routerSubscription?.unsubscribe();
      }
      this.breakpointSubscription?.unsubscribe()
      this.dynamicOverlayRef.dispose();
      this.dynamicOverlayOpened = false;
      this.overlayClosed.emit(true);
    }
  }

}
