import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { routes } from './routes';
import { IsLoggedIn } from '../shared/guards/login.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [ RouterModule ],
  declarations: [],
  providers: [
    IsLoggedIn
  ]
})
export class AppRoutingModule { }
