<mat-form-field appearance="outline" color="accent" floatLabel="auto">
  <mat-label>{{filter.displayName}}</mat-label>
  <mat-select [formControl]="itemSingleCtrl" #singleSelect>
    <mat-option>
      <ngx-mat-select-search [formControl]="itemFilterCtrl"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      <span class="option-name">{{item.displayName}}</span>
    </mat-option>
  </mat-select>
</mat-form-field>