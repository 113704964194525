import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Promotion } from '../pages/promotion';

export interface QuickCheckAnswer {
  Antwort: string,
  AntwortId: string,
  Start?: string
}

export interface QuickCheck {
  id: string,
  pdfTitle: string,
  introAnswer: string,
  Ausschlussfragen: QuickCheckContainmentQuestion[],
  Eingrenzungsfragen: QuickCheckContainmentQuestion[],
  StartEingrenzungsfrage: string,
  Titel: string,
  tabIDs: QuickCheckTab[]
  promotions: Promotion[]
}

export interface QuickCheckContainmentQuestion {
  Id: string;
  Typ: string;
  FrageId: string;
  Frage: string;
  tabID: string;
  Antworten: QuickCheckContainmentAnswer[];
  Hinweis?: null | string;
}

export interface QuickCheckContainmentAnswer extends QuickCheckAnswer {
  weiter: string,
  // TODO: was macht das?
  weiterPrio: number,
  Grundhead: string,
  Grund: string,
  Einschluss: string[],
  Wertung?: boolean;
}

export interface QuickCheckTab {
  tabID: string,
  Text: string
}

//über den Service holt sich die PWA die JSON für den Quickcheck und schickt das Ergebnis an das Backend für die PDF-Generierung

@Injectable({
  providedIn: 'root'
})
export class QuickcheckService {

  constructor(private http: HttpClient) {

  }

  getQuickchecks(): Observable<QuickCheck[]>{
    return this.http.get<QuickCheck[]>(environment.backendUrl + '/v2/quickchecks');
  }

  storeQuickcheck() {

  }

  restoreQuickcheck() {

  }



}
