import { Component } from '@angular/core';
import {slideInAnimation} from './app-routing/route-animation'
import { Subject, catchError, combineLatest, count, filter, map, mergeAll, mergeMap, of, tap, windowTime } from 'rxjs';
import { flavor } from 'src/environments/flavor';
import { BreakpointService, UiService } from './components/layout';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { LoginService } from './login';
import { MenuItem } from './components/header/header.component';
import { UtagService } from './components/analytics';
import { EventBookingService } from './pages/events/services/event-booking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toBoolean } from './utilities';
import { UpdateService } from './services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent {
  public navLinks : MenuItem[] = [
    {
      name: 'Start',
      path: '/start',
      icon: 'mf-start',
    },
    {
      name: 'Fördersuche',
      path: '/promotions',
      icon: 'mf-search',
    },
    {
      name: 'Produkte',
      path: '/produkte',
      icon: 'mf-products',
    },
    {
      name: 'Quick-Check',
      path: '/quickcheck',
      icon: 'mf-quick-check',
    },
    {
      name: 'Mehr',
      path: '/mehr',
      icon: 'mf-more',
      items: [
        {
          name: 'Ansprechpartner',
          path: '/t_ansprechpartner',
          icon: 'mf-ansprechpartner',
        },
        {
          name: 'Events',
          path: '/events',
          icon: 'mf-quick-check',
        },
        {
          name: 'Webinare',
          path: 'https://geschaeftskunden.telekom.de/hilfe-und-service/webinare',
          icon: 'mf-webinare',
          external: true
        },
        {
          name: 'FAQs',
          path: '/faq',
          icon: 'mf-faq',
        },
        {
          name: 'Datenschutzhinweise',
          path: 'https://www.telekom.de/datenschutzhinweise/view/274.html',
          icon: 'mf-datenschutz',
          external: true
        },
        {
          name: 'Impressum',
          path: '/impressum',
          icon: 'mf-impressum',
        },
      ]
    }
  ];

  snippetOrHotline: boolean = flavor.snippet || flavor.isHotline;

  private triggerDebug$ = new Subject();

  constructor(
    private updateService: UpdateService,
    private uiService: UiService,
    private breakpointService: BreakpointService,
    private loginService: LoginService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utagService: UtagService,
    private eventBookingService: EventBookingService,
    private snackbar: MatSnackBar
  ) {
    this.handleHashRoutes()

    this.eventBookingService.hasEvents().subscribe((hasEvents) => {
      if(!hasEvents) {
        const indexOfMore = this.navLinks.map((el) => {
          return el.path;
        }).indexOf('/mehr');
        const index = this.navLinks[indexOfMore].items.map((el) => {
          return el.path;
        }).indexOf('/events');
        if (index !== -1) {
          this.navLinks[indexOfMore].items.splice(index, 1)
        }
      }
    })

    this.handlePageAnalytics();

    this.registerIcons();

    this.triggerDebug$.pipe(
      windowTime(2000),
      map(window => window.pipe(count())),
      mergeAll(),
      filter(count => count > 5),
    ).subscribe(() => {
      const preview = !toBoolean(localStorage.getItem('SHOW_PREVIEW'))
      localStorage.setItem('SHOW_PREVIEW', preview.toString())

      this.snackbar.open('Preview ' + (preview ? 'aktiviert' : 'deaktiviert'), undefined, { duration: 1000 })
    })
  }

  public readonly onlyShowContent = this.uiService.getShowContent();
  public isDesktop = this.breakpointService.isDesktop


  ngOnInit(): void {
    this.updateService.checkForUpdates()

    this.uiService.setShowContent(flavor.isHotline || flavor.snippet || this.inIframe())

    this.loginService.checkLogin().pipe(
      catchError(() => of(undefined))
    ).subscribe()
  }

  public triggerDebug() {
    this.triggerDebug$.next(undefined)
  }

  private inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch {
      return true;
    }
  }

  private registerIcons() {
    this.matIconRegistry.addSvgIcon(`mf-start`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/home_outline.svg`));
    this.matIconRegistry.addSvgIcon(`mf-search`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/search_outline.svg`));
    this.matIconRegistry.addSvgIcon(`mf-products`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/meinmagenta_graphical.svg`));
    this.matIconRegistry.addSvgIcon(`mf-quick-check`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/todo-list_outline.svg`));
    this.matIconRegistry.addSvgIcon(`mf-more`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/hamburger-button_outline.svg`));
    this.matIconRegistry.addSvgIcon(`mf-ansprechpartner`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/ansprechpartner.svg`));
    this.matIconRegistry.addSvgIcon(`mf-datenschutz`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/datenschutz.svg`));
    this.matIconRegistry.addSvgIcon(`mf-faq`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/faq.svg`));
    this.matIconRegistry.addSvgIcon(`mf-impressum`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/impressum.svg`));
    this.matIconRegistry.addSvgIcon(`mf-webinare`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/webinare.svg`));
    this.matIconRegistry.addSvgIcon(`mf-login-aktiv`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/login_aktiv.svg`));
    this.matIconRegistry.addSvgIcon(`mf-login-inaktiv`, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mf-icons/login_inaktiv.svg`));
  }

  private handlePageAnalytics() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute.firstChild;
        let child = route;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
            route = child;
          } else {
            child = null;
          }
        }
        return route;
      }),
      mergeMap(route => combineLatest([route.data, route.params])),
      filter(([data]) => !!data.analytics)
    ).subscribe(([data, params]) => {
      this.utagService.view(data.analytics, params)
    });
  }

  private handleHashRoutes() {
    this.activatedRoute.fragment.pipe(
      filter(fragment => !!fragment),
      map(fragment => fragment.split('/')),
      filter(fragments => fragments.length > 1),
      map(fragments => fragments.slice(1)),
      map(fragments => {
        const lastFragment = fragments[fragments.length - 1]
        const [fragment, query] = lastFragment.split('?')
        fragments[fragments.length - 1] = fragment

        const queryParams: Params = {}
        new URLSearchParams(query).forEach((value, key) => queryParams[key] = value)

        return { fragments, queryParams }
      })
    ).subscribe(({fragments, queryParams }) => {
      this.router.navigate(fragments, { replaceUrl: true, queryParams })
    })
  }
}
