<mf-animated-header *ngIf="!isSnippet">
</mf-animated-header>

<div class="scroll-container" mfScrollShadow>
  <div class="container">
    <div class="row justify-content-center">
      <div class="m-t-16 m-b-16 col-12 col-lg-8" *ngIf="!successpage; else successTemplate">
        <form novalidate *ngIf="(contactForm && isHotline) || contactForm; else loading" #form="ngForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Persönliche Beratung? - Aber gerne!</mat-card-title>
          </mat-card-header>
          <mat-card-content>
              <div class="info-container">
                  <p class="info-container-value">Alle mit * gekennzeichneten Felder sind Pflichtfelder.</p>
              </div>
              <div *ngIf="type==='quickcheck'" class="info-container">
                  <p class="info-container-value">Hinweis: Als Basis einer optimalen Beratung werden Ihre Antworten aus dem Quick-Check mit Absenden des Formulars automatisch an uns übermittelt.</p>
              </div>
              <div class="divider-24"></div>
              <fieldset>
                <legend>Kundendaten</legend>

                <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
                  <mat-label class="input-label">Firma</mat-label>
                  <input matInput type="text" formControlName="firma" class="input-value">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
                    <mat-label class="input-label">Vorname*</mat-label>
                    <input matInput type="text" formControlName="vorname" required class="input-value">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
                    <mat-label class="input-label">Nachname*</mat-label>
                    <input matInput type="text" formControlName="nachname" required class="input-value">
                </mat-form-field>
                <mat-form-field class="full-width"  appearance="outline" hideRequiredMarker="true">
                    <mat-label class="input-label">Straße/Hausnummer*</mat-label>
                    <input matInput type="text" formControlName="strassehausnummer" required class="input-value">
                </mat-form-field>
                <div class="row">
                    <mat-form-field class="col-4" appearance="outline" hideRequiredMarker="true">
                        <mat-label class="input-label">Postleitzahl*</mat-label>
                        <input matInput type="text" inputmode="numeric" pattern="^[ ]*[0-9]*[ ]*$" formControlName="plz" required class="input-value">
                    </mat-form-field>
                    <mat-form-field class="col-8" appearance="outline" hideRequiredMarker="true">
                        <mat-label class="input-label">Ort*</mat-label>
                        <input matInput type="text" formControlName="stadt" required class="input-value">
                    </mat-form-field>
                </div>
                <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
                    <mat-label class="input-label">E-Mail*</mat-label>
                    <input matInput type="email" formControlName="email" required class="input-value">
                </mat-form-field>
              </fieldset>
              <fieldset class="m-b-16">
                <legend>Wann können wir Sie am besten erreichen?</legend>
                <mat-radio-group formControlName="erreichbarkeit">
                    <mat-radio-button class="block full-width" color="primary" value="0">Ganztags (09:00 - 18:00)</mat-radio-button>
                    <mat-radio-button class="block full-width" color="primary" value="1">Vormittags (09:00 - 12:00)</mat-radio-button>
                    <mat-radio-button class="block full-width" color="primary" value="2">Mittags (12:00 - 14:00)</mat-radio-button>
                    <mat-radio-button class="block full-width" color="primary" value="3">Nachmittags (14:00 - 16:00)</mat-radio-button>
                    <mat-radio-button class="block full-width" color="primary" value="4">Abends (16:00 - 18:00)</mat-radio-button>
                </mat-radio-group>
              </fieldset>
              <fieldset>
                <legend>Telekom Festnetzrufnummer</legend>
                <div class="row">
                    <mat-form-field class="col-6" appearance="outline" hideRequiredMarker="true">
                        <mat-label class="input-label">Vorwahl*</mat-label>
                        <input matInput type="tel" formControlName="vorwahl" required class="input-value">
                    </mat-form-field>
                    <mat-form-field  class="col-6" appearance="outline" hideRequiredMarker="true">
                        <mat-label class="input-label">Telefonnummer*</mat-label>
                        <input matInput type="tel" formControlName="rufnummer" required class="input-value">
                    </mat-form-field>
                </div>
              </fieldset>
              <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
                <mat-label class="input-label">Ihr Platz für zusätzliche Fragen und Informationen</mat-label>
                <textarea matInput formControlName="kommentar" class="input-value"></textarea>
              </mat-form-field>
              <fieldset *ngIf="isHotline">
                  <legend>Hotline-Zusatzfelder</legend>
                  <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
                    <mat-label class="input-label">Kundennummer</mat-label>
                    <input matInput type="text" maxlength="12" formControlName="kundennummer" class="input-value">
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="outline" hideRequiredMarker="true">
                    <mat-label class="input-label">Kampagne</mat-label>
                    <mat-select formControlName="campagne">
                        <mat-option value="">(keine Angabe)</mat-option>
                        <ng-container *ngFor="let campagne of campagneKampagne">
                            <mat-option color="primary" [value]="campagne.feld_id">{{campagne.feld_id}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
              </fieldset>
              <fieldset>
                <legend>Informationen und Datenschutz</legend>
                <mat-checkbox color="primary" formControlName="rueckruf" required class="txt-lightgray">Ich erteile meine jederzeit widerrufliche Einwilligung, per E-Mail sowie telefonisch zu meinem Anliegen von der Deutschen Telekom AG und deren Tochterunternehmen kontaktiert zu werden.*</mat-checkbox>
                <mat-checkbox color="primary" formControlName="datenschutz" required class="txt-lightgray">Mit dem Absenden dieses Formulars stimmen Sie unseren Datenschutzbestimmungen zu.*</mat-checkbox>
                <div class="m-b-16 m-t-16">
                  <p class="info-container-value">Hier finden Sie die <a href="https://www.telekom.de/is-bin/INTERSHOP.enfinity/WFS/EKI-PK-Site/-/-/-/ViewAGB-Start" target="_blank">Allgemeinen Geschäfsbedingungen</a> und <a href="https://www.telekom.de/datenschutzhinweise" target="_blank">Datenschutzhinweise</a>.</p>
                  <br>
                  <p class="info-container-value">Ihre Daten werden ausschließlich für die Bearbeitung Ihres Anliegens verwendet.</p>
              </div>
              </fieldset>
            </mat-card-content>
            <mat-card-actions class="space-between">
              <button id="contact_abort_btn" mat-button type="reset" (click)="cancel()">Abbrechen</button>
              <button id="contact_submit" mat-flat-button type="submit" [class.spinner]="loading_spinner" [disabled]="loading_spinner" color="primary">
                <span class="submit-button-wrapper">
                  <mat-spinner [diameter]="16" *ngIf="loading_spinner"></mat-spinner>Absenden
                </span>
              </button>
            </mat-card-actions>
          </mat-card>
        </form>
      </div>
    </div>

  </div>
</div>

<ng-template #loading>
  <div class="container">
    <div class="row justify-content-center">
      <div class="m-t-24 m-b-24 col-12">
        <mat-spinner class="m-center"></mat-spinner>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successTemplate>
  <div class="m-t-16 m-b-16 col-12 col-lg-8">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar ><img class="success_icon" src="./assets/images/confirm_graphical.svg"/></div>
        <mat-card-title>Vielen Dank.</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        Ihre Nachricht wurde erfolgreich abgesendet! Sie können die Seite nun schließen.
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
