import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { toBoolean } from '../types';
import { Environment } from 'src/environments/environment.iface';

@Injectable()
export class PreviewInterceptor implements HttpInterceptor {

  constructor(private environment: Environment) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newRequest = toBoolean(localStorage.getItem('SHOW_PREVIEW')) && request.url.startsWith(this.environment.backendUrl) ? 
      request.clone({ headers: request.headers.append('preview', 'true') }) :
      request
    return next.handle(newRequest);
  }
}
