<mf-animated-header>
</mf-animated-header>

<div class="scroll-container" mfScrollShadow>
  <div class="container" #contentArea>
    <ng-container *ngIf="faqs | async as data; else loadingOrError">
      <ng-container *ngLet="openPanelId | async as openPanelId">
        <div class="row justify-content-center">
          <div class="m-t-8 m-b-16 col-12 col-lg-8">
            <h2 class="m-t-8">Häufig gestellte Fragen</h2>
            <mat-expansion-panel class="m-t-16" *ngFor="let item of data,let i = index" [expanded]="openPanelId === item.id" id="{{item.id}}" (opened)="utag_questionOpened(item)" (closed)="utag_questionClosed(item)">
              <mat-expansion-panel-header class="mf-expansion-panel-header">
                  <mat-panel-title>
                      {{item.frage}}
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="panel-spacer">
              <p class="p-t-16" [innerHTML]="item.antwort | secureHTML" mfDynamicContentLink></p>
            </mat-expansion-panel>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #loadingOrError>
  <div class="row justify-content-center" *ngIf="($loadingStatus | async) === 'loading'; else error">
    <span role="status" class="cdk-visually-hidden">Lade den Seiteninhalt</span>
    <div class="m-t-8 m-b-16 col-12 col-lg-8">
      <mf-skeleton-loader class="m-t-8 m-b-16 block" width="150px" height="30px"></mf-skeleton-loader>
      <mf-skeleton-loader *repeatTimes="8" class="m-t-12" height="80px"></mf-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <mf-loader-error class="block m-t-32"></mf-loader-error>
</ng-template>

