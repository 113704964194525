import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OIDCService } from './oidc.service';

@Injectable()
export class LoginService {

  constructor(private OIDCService: OIDCService) {
  }

  public readonly isLoggedIn = this.OIDCService.isLoggedIn
  public get isLoggedInSync(): boolean {
    return this.OIDCService.isLoggedInSync
  }

  public checkLogin(): Observable<boolean> {
    return this.OIDCService.checkLogin()
  }
  
  public login(): Observable<boolean> {
    return this.OIDCService.login()
  }

  public logout(): Observable<void> {
    return this.OIDCService.logout();
  }
}
