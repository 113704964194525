import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStoreCardComponent } from './app-store-card/app-store-card.component';
import { LoginCardComponent } from './login-card/login-card.component';
import { StartComponent } from './start.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ComponentsModule } from 'src/app/components';
import { PromotionModule } from '../promotion';
import { LayoutModule } from 'src/app/components/layout';
import { MatButtonModule } from '@angular/material/button';
import { IntroComponent } from './intro/intro.component';
import { LoaderModule } from '../../components/loader/loader.module';
import { UtilitiesModule } from '../../utilities/utilities.module';
import { CardsModule } from '../../components/cards';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/components/directives/directives.module';

@NgModule({
  declarations: [
    AppStoreCardComponent,
    LoginCardComponent,
    StartComponent,
    IntroComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ComponentsModule,
    CardsModule,
    PromotionModule,
    LayoutModule,
    UtilitiesModule,
    LoaderModule,
    RouterModule,
    DirectivesModule
  ]
})
export class StartModule { }
