export abstract class Environment {
  /**
   * Specifies if this is a production build
   */
  production: boolean

  /**
   * Base URL of the API
   */
  backendUrl: string

  /**
   * Absolute URL to the frontend assets
   */
  assetBaseUrl: string

  /**
   * App Store Urls
   * 
   * Contains the URLs to the different app stores per platform. The key is
   * the osFamily from the DeviceService. Currently supports only iOS and Android.
   */
  appStoreUrls: {
    iOS: string,
    Android: string,
    [os: string]: string
  }
  
  /**
   * Authentication config
   */
  authentication: {
    /**
     * Issuer of the auth tokens
     */
    issuer: string,

    tokenEndpoint?: string,

    userinfoEndpoint?: string,

    /**
     * ClientID of this application
     */
    clientId: string,

    /**
     * Scopes to be granted to this application
     */
    scope: string,
  }

  /**
   * Script resource for the UTag Service
   */
  utagScript: string
}