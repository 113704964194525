<mat-form-field subscriptSizing="dynamic" class="search-field">
  <mat-label>{{label}}</mat-label>
  <input #search matInput type="search" autocapitalize="none" [(ngModel)]="value" (keyup.enter)="startSearch()"/>
  <button
    *ngIf="value"
    matSuffix
    [disableRipple]="true"
    mat-icon-button
    aria-label="Eingabe löschen"
    (click)="clearValue()"
    class="clear-button"
  >
    <mat-icon>close</mat-icon>
  </button>
  <button

    matSuffix
    mat-icon-button
    (click)="startSearch()"
    aria-label="Suche starten"
  >
    <mat-icon matPrefix >search</mat-icon>
  </button>
</mat-form-field>
