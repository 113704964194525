import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Booking } from '../models/booking';
import { Event } from '../models/event';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBookingService {

  constructor(private http: HttpClient) { }

  public getEvents(): Observable<Event[]> {
    let url: string = environment.backendUrl + "/events";
    return this.http.get<Event[]>(url).pipe(
      catchError(error => {
        console.log(error);
        return []
      })
    );
  }

  public getEvent(eventId: string, appointmentId?: string): Observable<Event> {
    const url: string = environment.backendUrl + "/events/" + eventId + "/" + (appointmentId ? appointmentId : "");
    return this.http.get<Event>(url);
  }

  public hasEvents() : Observable<boolean> {
    return this.getEvents().pipe(
      map(events  => events.length > 0)
    )
  }

  public bookAppointment(eventId: string, appointmentId: string, booking: Booking): Observable<any> {
    let url: string = environment.backendUrl + "/events/" + eventId + "/appointments/" + appointmentId;
    let headers = { "content-type": "application/json" }
    return this.http.post<any>(url, booking, { headers: headers });
  }

}
