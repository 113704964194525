import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, catchError, filter, finalize, map, of, switchMap, tap } from 'rxjs';
import { Aktionsbereich, Style, UtagService } from '../../components/analytics';
import { FAQ, FaqService } from "../../services/faq.service";
import { LoaderStatus } from '../../components/loader';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit {

  faqs: Observable<FAQ[]> = this.faqService.get_all_faq().pipe(
    tap(() => {
      this.hasError = false;
      this.$loadingStatus.next(LoaderStatus.loading)
    }),
    catchError(() => {
      this.hasError = true;
      this.$loadingStatus.next(LoaderStatus.error);
      return of(null)
    }),
    finalize(() => {
      !this.hasError ? this.$loadingStatus.next(LoaderStatus.success) : null;
    })
  )
  openPanelId = this.route.params.pipe(map(params => params['id']))

  $loadingStatus = new BehaviorSubject<LoaderStatus>(LoaderStatus.loading);
  hasError : boolean = false;

  @ViewChildren(MatExpansionPanel, { read: ElementRef<HTMLElement> }) panels: QueryList<ElementRef<HTMLElement>>

  constructor(
    private faqService: FaqService,
    private utag: UtagService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.panels.changes.pipe(
      filter(() => this.panels.length > 0),
      switchMap(() => this.route.params),
      map(params => params['id']),
      map(id => this.panels.find(item => item.nativeElement.id == id))
    ).subscribe(item => item?.nativeElement?.scrollIntoView())
  }

  utag_questionOpened(question: any) {
    this.utag.aktion(Aktionsbereich.Content, Style.AccordionOpen, "id-" + question.id);
  }

  utag_questionClosed(question: any) {
    this.utag.aktion(Aktionsbereich.Content, Style.AccordionClose, "id-" + question.id);
  }
}
