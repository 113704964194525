import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderCardComponent } from './loader-card/loader-card.component';
import { LoaderErrorComponent } from './loader-error/loader-error.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DirectivesModule } from '../directives/directives.module';


@NgModule({
  declarations: [
    LoaderCardComponent,
    LoaderErrorComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DirectivesModule
  ],
  exports: [
    LoaderCardComponent,
    LoaderErrorComponent
  ],
  providers: [
  ]
})
export class LoaderModule { }
