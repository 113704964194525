import { NgModule } from "@angular/core";
import { RestoreScrollingDirective } from "./restore-scrolling.directive";
import { ScrollPositionDirective } from './scroll-position.directive';

@NgModule({
  declarations: [
    RestoreScrollingDirective,
    ScrollPositionDirective
  ],
  exports: [
    RestoreScrollingDirective,
    ScrollPositionDirective
  ],
  imports: []
})
export class ScrollingModule { }