import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export interface FAQ {
  id: string;
  frage: string;
  antwort: string;
}

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(
    private http: HttpClient
  ) { }

  get_all_faq():Observable<FAQ[]>{
    return this.http.get<any>(environment.backendUrl + '/faq');
  }
}

