import { Component } from '@angular/core';

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.scss']
})
export class QRCodeDialogComponent {

  value = window.location.href

}
