import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BreakpointService } from './services/breakpoint.service';
import { AnimatedHeaderComponent } from './animated-header/animated-header.component';
import { UiService } from './services/ui.service';
import { HorizontalScrollerComponent } from './horizontal-scroller/horizontal-scroller.component';
import { ScrollDirective } from './scroll.directive';
import { ContentRowComponent } from './content-row/content-row.component';
import { DirectivesModule } from '../directives/directives.module';


@NgModule({
  declarations: [
    AnimatedHeaderComponent,
    HorizontalScrollerComponent,
    ScrollDirective,
    ContentRowComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    AnimatedHeaderComponent,
    HorizontalScrollerComponent,
    ContentRowComponent,
    ScrollDirective,
    DirectivesModule
  ],
  providers: [
    BreakpointService,
    UiService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule { }
