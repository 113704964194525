import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private observers: {
    [key: string]: Subject<string>
  } = {}

  constructor() { }

  set(key: string, value: string) {
    this.emit(key, value)
  }

  get(key: string): string {
    return this.initObserver(key)
  }

  observe(key: string): Observable<string> {
    this.initObserver(key)
    return this.observers[key].asObservable()
  }

  private initObserver(key: string): string {
    const currentValue = localStorage.getItem(key)
    if (!this.observers[key]) {
      this.observers[key] = new BehaviorSubject(currentValue)
    }

    return currentValue
  }

  private emit(key: string, value: string) {
    this.initObserver(key)
    try {
      localStorage.setItem(key, value)
      this.observers[key].next(value)
    } catch {}
  }
}
