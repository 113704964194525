import { Directive, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QRCodeDialogComponent } from './qrcode-dialog/qrcode-dialog.component';

@Directive({
  selector: '[QRCode]'
})
export class QRCodeDirective {

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event']) onClick(){
    this.dialog.open(QRCodeDialogComponent, {
      width: '340px',
      minWidth: '340px',
      maxWidth: '100%',
      maxHeight: '500px'
    })
  }

}
