import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from 'src/app/login';

@Component({
  selector: 'mf-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss']
})
export class LoginCardComponent {

  readonly isLoggedIn = this.loginService.isLoggedIn;

  public isFirstStart : BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private loginService: LoginService) {
    this.handleFirstStart()
  }

  private handleFirstStart() {
    let firstStart = localStorage.getItem("first_start");
    this.isFirstStart.next(firstStart !== 'false' ? true : false);

    localStorage.setItem("first_start", 'false');
  }

}
