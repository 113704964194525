import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './page-title/page-title.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { QRCodeDialogComponent } from './qrcode-dialog/qrcode-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatDialogModule } from '@angular/material/dialog';
import { QRCodeDirective } from './qrcode.directive';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { DynamicOverlayModule } from './dynamic-overlay';
import { MatListModule } from '@angular/material/list';
import { ClickDirective } from './directives/click.directive';
import { CarouselComponent } from './carousel/carousel.component';
import { UtilitiesModule } from '../utilities';
import { SearchInputComponent } from './search-input/search-input.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from './directives/directives.module';


@NgModule({
  declarations: [
    PageTitleComponent,
    QRCodeDialogComponent,
    QRCodeDirective,
    InfoBoxComponent,
    HeaderComponent,
    FooterComponent,
    ClickDirective,
    CarouselComponent,
    SearchInputComponent
  ],
  imports: [
    QRCodeModule,
    MatDialogModule,
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatTabsModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    DynamicOverlayModule,
    MatListModule,
    UtilitiesModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    DirectivesModule,
  ],
  exports: [
    QRCodeDirective,
    PageTitleComponent,
    InfoBoxComponent,
    HeaderComponent,
    FooterComponent,
    ClickDirective,
    CarouselComponent,
    SearchInputComponent,
    DirectivesModule,
  ],
})
export class ComponentsModule { }
