import { CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { QuickcheckComponent } from './pages/quickcheck/quickcheck.component';

import { MatTableModule } from '@angular/material/table';
import { ContactformComponent } from './pages/contactform/contactform.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { ProfilComponent } from './pages/profil/profil.component';
import { TAnsprechpartnerComponent } from './pages/t-ansprechpartner/t-ansprechpartner.component';
import { createCustomElement } from '@angular/elements';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { flavor } from '../environments/flavor';
import { EventBookingService } from './pages/events/services/event-booking.service';
import { ComponentsModule, ScrollingModule } from './components';
import { DynamicOverlayModule } from './components/dynamic-overlay';
import { LayoutModule, UiService } from './components/layout';
import { LoginModule } from './login';
import { PromotionModule } from './pages/promotion/';
import { PageTitleStrategy, PreviewInterceptor, UtilitiesModule } from './utilities';
import { EnvPipe } from './utilities/pipes/env.pipe';
import { AnalyticsModule } from './components/analytics';
import { CardsModule } from './components/cards';
import 'scroll-shadow-element'
import { TitleStrategy } from '@angular/router';

import { environment } from '../environments/environment';
import { Environment } from '../environments/environment.iface';
import { StartModule } from './pages/start';
import { LoaderModule } from './components/loader/loader.module';
import { MatSelectModule } from '@angular/material/select';
import { default as packageInfo } from '../../package.json';
import { PACKAGE_VERSION } from './services/update.service';
import { DirectivesModule } from './components/directives/directives.module';

export class HAMMER_GESTURE_CONFIG extends HammerGestureConfig {
  overrides = <any>{
      // override hammerjs default configuration
      'swipe': { direction: Hammer.DIRECTION_HORIZONTAL },
      'pinch': { enable: false },
      'rotate': { enable: false },
      'pan': { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    QuickcheckComponent,
    ProfilComponent,
    TAnsprechpartnerComponent,
    ImpressumComponent,
    FaqComponent,
    ContactformComponent
  ],
  imports: [
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    CardsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatExpansionModule,
    MatRadioModule,
    HttpClientModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTableModule,
    ToastrModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    PromotionModule,
    ComponentsModule,
    LoginModule,
    LoaderModule,
    AnalyticsModule,
    UtilitiesModule,
    LayoutModule,
    DynamicOverlayModule,
    StartModule,
    DirectivesModule
  ],
  providers: [
    EventBookingService,
    EnvPipe,
    UiService,
    PageTitleStrategy,
    { provide: TitleStrategy, useExisting: PageTitleStrategy },
    { provide: Environment, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: PreviewInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig, deps: [] },
    { provide: PACKAGE_VERSION, useValue: packageInfo.version }
  ],
  bootstrap: !flavor.snippet ? [AppComponent] : [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements DoBootstrap {

  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const myElement_quickcheck = createCustomElement(QuickcheckComponent, { injector: this.injector });
    customElements.define('app-element-quickcheck', myElement_quickcheck);

    const myElement_contactform = createCustomElement(ContactformComponent, { injector: this.injector });
    customElements.define('app-element-contactform', myElement_contactform);
  }

}
