import {
  transition,
  trigger,
  query,
  style,
  animate,
  group
} from '@angular/animations';

const slideInRight = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
    ], { optional: true }),
    query(':leave', [
      style({ transform: 'translateX(0%)' }),
      animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
    ], { optional: true }),
  ])
]

const slideInLeft = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
    ], { optional: true }),
    query(':leave', [
      style({ transform: 'translateX(0%)' }),
      animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' }))
    ], { optional: true }),
  ])
]

export const slideInAnimation =
trigger('routeAnimations', [
  transition('start => *', slideInRight),
  transition('* => start', slideInLeft),
  transition('promotion => *', slideInRight),
  transition('* => promotion', slideInLeft),
  transition('products => *', slideInRight),
  transition('* => products', slideInLeft),
  transition('quickcheck => *', slideInRight),
  transition('* => quickcheck', slideInLeft),
  transition('* <=> *', slideInRight),
]
);