import { Component, Input } from '@angular/core';
import { ToBoolean } from '../../../utilities';

@Component({
  selector: 'mf-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
  host: {
    role: 'link'
  }
})
export class ListCardComponent {

  @Input() @ToBoolean() highlight:boolean;
  @Input() @ToBoolean() hideHighlight:boolean;
  @Input() title: string;
  @Input() icon: string;

}
