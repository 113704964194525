import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from '../header/header.component';
import { DynamicOverlayComponent } from '../dynamic-overlay';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'mf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() menuItems : MenuItem[] = []

  @ViewChild("extendedMenu") overlay: DynamicOverlayComponent;

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.closeOverlay();
      }
    });
  }

  ngOnInit(): void {
  }

  closeOverlay() {
    this.overlay.close();
  }

}
