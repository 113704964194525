import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Promotion} from '../model/promotion.iface'
import { environment } from '../../../../environments/environment';
import { PromotionSearch } from '../model';
import { UtagService } from 'src/app/components/analytics';

/**
 * Service der die Liste mit allen Förderprogrammen bereitstellt (für die Search Komponente)
 * sowie jedes einzelen Förderprogramm mit allen Details (für die Promotion Komponente) sowie
 * mit zusätzlichen Infos für die Beratung
 */
@Injectable()
export class PromotionService {

  constructor(
    private http: HttpClient,
    private utag: UtagService
  ) {}

  getPromotions(search: PromotionSearch): Observable<Promotion[]>{
    const params = {
      ...search.query ? { query: search.query } : {},
      ...search.filter,
      sort: search.sort?.property + search.sort?.direction
    }
    return this.http.get<Promotion[]>(environment.backendUrl + '/promotions', { params }).pipe(
      tap(results => this.utag.view({
        type: "suche",
        navigationLevels: ["foerdersuche"],
        additionalProperties: {
          search_engine_searchword: search.query ?? "",
          page_searchResults: results.length.toString()
        }
      }))
    )
  }

  getPromotionById(id: string): Observable<Promotion>{
    return this.http.get<Promotion>(environment.backendUrl + '/promotions/' + id);
  }

}
