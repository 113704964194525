
<div class="carousel" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
  <div class="carousel-scroll-container" role="region" aria-label="Karusell" tabindex="0">
    <div class="carousel-media" role="list">
      <a [attr.tabindex]="activeIndex === j ? '0' : '-1'" [trackClick]="['content', 'button-image-text', 'banner-' + item.title]" href="{{item.link}}" [attr.target]="item?.isExternal ? '_blank' : null" aria-label="{{item.title}}" class="carousel-item" #carouselItem *ngFor="let item of data; let j = index" [attr.data-index]="j" [attr.aria-hidden]="activeIndex !== j ? true : null ">
        <img class="mat-elevation-z2" [src]="item.imgUrl | secure | async" aria-hidden="true" alt="{{item.title}}">
      </a>
    </div>
  </div>
</div>
<div class="dots">
  <button class="dot" tabindex="0" (click)="scrollTo(dotIndex)" *ngFor="let item of data; let dotIndex = index" [class.active]="activeIndex === dotIndex" aria-label="Zeige Folie {{dotIndex + 1}}"></button>
</div>
