<div class="info-box">
  <div class="icon-wrapper">
    <i aria-hidden="true">
      <ng-content select="[icon]"></ng-content>
    </i>
  </div>
  <div class="content-wrapper">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
