<div class="app-frame">
  <header *ngIf="!(onlyShowContent | async)">
    <mf-header [isDesktop]="isDesktop | async" [menuItems]="navLinks"></mf-header>
  </header>
  <main [@routeAnimations]="o?.activatedRouteData?.['animation']" [class.snippet]="snippetOrHotline">
    <router-outlet #o="outlet"></router-outlet>
  </main>
  <nav class="mat-elevation-z4" *ngIf="!(isDesktop | async) && !(onlyShowContent | async)">
    <mf-footer [menuItems]="navLinks"></mf-footer>
  </nav>
</div>

<div class="debug" aria-hidden="true" (click)="triggerDebug()"></div>
