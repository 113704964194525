<div class="lizenzvereinbarung_text">
  <p>
    Bitte beachten Sie, dass wir unsere Informationen zu Förderprogrammen
    keine betriebswirtschaftliche, rechtliche oder steuerliche Beratung darstellen.
  </p>
  <p>
    Wir übernehmen keine Gewähr für Richtigkeit, Vollständigkeit oder Aktualität
    der bereitgestellten Informationen. Darüber hinaus übernehmen wir keine
    Haftung für die Richtigkeit evtl. übersendeter Links oder der Richtigkeit und
    Aktualität des verlinkten Contents. Es liegt in der alleinigen Verantwortung
    des Antragstellers, eigenständig zu prüfen, ob Informationen für seine
    spezifische Situation relevant und aktuell sind. Der Antragsteller ist
    verantwortliche für die Einhaltung sämtlicher Vorschriften. Die Entscheidung
    über Bewilligung oder Ablehnung eines Förderantrags wird vom Fördergeber
    getroffen.
  </p>
  <p>
    Wir weisen darauf hin, dass die in diesem Text genannten Förderprogramme und
    Richtlinien möglicherweise nicht vollständig sind und dass es für den
    Antragsteller weitere relevante Fördermöglichkeiten und Vorschriften geben
    kann, die nicht erwähnt wurden. Die Aufnahme oder das Fehlen bestimmter
    Informationen stellt keine Befürwortung oder Ablehnung bestimmter
    Förderprogramme oder Richtlinien dar.
  </p>
  <p>
    In keinem Fall haften wir für Verluste oder Schäden, die durch die Nutzung
    der bereitgestellten Informationen entstehen. Jegliche Handlungen, die auf
    Grundlage der in diesem Text enthaltenen Informationen unternommen werden,
    erfolgen auf eigene Verantwortung.
  </p>
</div>