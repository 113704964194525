export enum Style {
    AccordionClose = "accordion-close",
    AccordionOpen = "accordion-open",
    AccordionCloseAll = "accordion-expand-all-close",
    AccordionOpenAll = "accordion-expand-all-open",
    Breadcrumb = "breadcrumb",
    Button = "button",
    ButtonText = "button-text",
    ButtonImage = "button-image",
    ButtonImageText = "button-image-text",
    Checkbox = "checkbox",
    FeatureList = "feature-list",
    Image = "image",
    ImageText = "image-text",
    InpageNavigation = "inpage-navigation",
    Radio = "radio",
    Selector = "selector",
    Tab = "tab",
    Text = "text",
    TextLink = "text-link",
    Video = "video"
}

export type StyleType = "accordion-close" | "accordion-open" | "accordion-expand-all-close" | "accordion-expand-all-open" | "breadcrumb" | "button" | "button-text" | "button-image" | "button-image-text" | "button-image-text" | "checkbox" | "feature-list" | "image" | "image-text" | "inpage-navigation" | "radio" | "selector" | "tab" | "text" | "text-link" | "video"
