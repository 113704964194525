import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mf-animated-header',
  templateUrl: './animated-header.component.html',
  styleUrls: ['./animated-header.component.scss']
})
export class AnimatedHeaderComponent implements AfterViewInit {

  @ViewChild('content') content!: ElementRef;

  constructor(private changeRef: ChangeDetectorRef) {}

  public hasContent : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  ngAfterViewInit() {
    this.content.nativeElement.childNodes.length > 0 ? this.hasContent.next(true) : this.hasContent.next(false);
    this.changeRef.detectChanges();
  }
}
