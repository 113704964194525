export interface PromotionSearch {
  query: string,
  filter: FilterMap,
  sort: Sorting
}

export interface FilterMap {
  [property:string]: FilterValue
}

export interface Filter {
  property: string,
  value: FilterValue
}

export type SortDirection = "Asc" | "Desc"

export interface Sorting {
  property: string,
  direction: SortDirection
}

export interface SearchSorting {
  property: string,
  displayName: string
}

export type FilterValue = string | string[] | boolean

export interface SearchFilter {
  property: string,
  displayName: string,
  type: FilterType,
  options: SearchOption[]
}

export interface SearchOption {
  displayName: string,
  value: string | boolean
}

export enum FilterType {
  Default = "default",
  CommaSeparated = "comma"
}

export const SEARCH_FILTERS: SearchFilter[] = [
  { property: "foerderberechtigte", displayName: "Förderberechtigte", type: FilterType.CommaSeparated, options: [
    { displayName: "Bildungseinrichtung", value: "Bildungseinrichtung" },
    { displayName: "Existenzgründer/in", value: "Existenzgründer/in" },
    { displayName: "Forschungseinrichtung", value: "Forschungseinrichtung" },
    { displayName: "Hochschule", value: "Hochschule" },
    { displayName: "Kommune", value: "Kommune" },
    { displayName: "Öffentliche Einrichtung", value: "Öffentliche Einrichtung" },
    { displayName: "Privatperson", value: "Privatperson" },
    { displayName: "Unternehmen", value: "Unternehmen" },
    { displayName: "Verband/Vereinigung", value: "Verband/Vereinigung" },
  ]},
  { property: "foerderbereich", displayName: "Förderbereich", type: FilterType.CommaSeparated, options: [
    { displayName: "Arbeit", value: "Arbeit" },
    { displayName: "Aus- & Weiterbildung", value: "Aus- & Weiterbildung" },
    { displayName: "Außenwirtschaft", value: "Außenwirtschaft" },
    { displayName: "Beratung", value: "Beratung" },
    { displayName: "Corona-Hilfe", value: "Corona-Hilfe" },
    { displayName: "Digitalisierung", value: "Digitalisierung" },
    { displayName: "Energieeffizienz & Erneuerbare Energien", value: "Energieeffizienz & Erneuerbare Energien" },
    { displayName: "Existenzgründung & -festigung", value: "Existenzgründung & -festigung" },
    { displayName: "Forschung & Innovation (themenoffen)", value: "Forschung & Innovation (themenoffen)" },
    { displayName: "Forschung & Innovation (themenspezifisch)", value: "Forschung & Innovation (themenspezifisch)" },
    { displayName: "Gesundheit & Soziales", value: "Gesundheit & Soziales" },
    { displayName: "Infrastruktur", value: "Infrastruktur" },
    { displayName: "Kultur, Medien & Sport", value: "Kultur, Medien & Sport" },
    { displayName: "Landwirtschaft & Ländliche Entwicklung", value: "Landwirtschaft & Ländliche Entwicklung" },
    { displayName: "Messen & Ausstellungen", value: "Messen & Ausstellungen" },
    { displayName: "Regionalförderung", value: "Regionalförderung" },
    { displayName: "Smart Cities & Regionen", value: "Smart Cities & Regionen" },
    { displayName: "Städtebau & Stadterneuerung", value: "Städtebau & Stadterneuerung" },
    { displayName: "Umwelt- & Naturschutz", value: "Umwelt- & Naturschutz" },
    { displayName: "Unternehmensfinanzierung", value: "Unternehmensfinanzierung" },
    { displayName: "Wohnungsbau & Modernisierung", value: "Wohnungsbau & Modernisierung" },
  ]},
  { property: "foerdergebiet", displayName: "Fördergebiet", type: FilterType.CommaSeparated, options: [
    { displayName: "Bundesweit", value: "bundesweit" },
    { displayName: "Baden-Württemberg", value: "Baden-Württemberg" },
    { displayName: "Bayern", value: "Bayern" },
    { displayName: "Berlin", value: "Berlin" },
    { displayName: "Brandenburg", value: "Brandenburg" },
    { displayName: "Bremen", value: "Bremen" },
    { displayName: "Niedersachsen", value: "Niedersachsen" },
    { displayName: "Sachsen-Anhalt", value: "Sachsen-Anhalt" },
    { displayName: "Hamburg", value: "Hamburg" },
    { displayName: "Hessen", value: "Hessen" },
    { displayName: "Mecklenburg-Vorpommern", value: "Mecklenburg-Vorpommern" },
    { displayName: "Nordrhein-Westfalen", value: "Nordrhein-Westfalen" },
    { displayName: "Rheinland-Pfalz", value: "Rheinland-Pfalz" },
    { displayName: "Saarland", value: "Saarland" },
    { displayName: "Sachsen", value: "Sachsen" },
    { displayName: "Schleswig-Holstein", value: "Schleswig-Holstein" },
    { displayName: "Thüringen", value: "Thüringen" },
  ]},
  { property: "foerderart", displayName: "Förderart", type: FilterType.CommaSeparated, options: [
    { displayName: "Zuschuss", value: "Zuschuss" },
    { displayName: "Darlehen", value: "Darlehen" },
    { displayName: "Beteiligung", value: "Beteiligung" },
    { displayName: "Bürgschaft", value: "Bürgschaft" },
    { displayName: "Sonstige", value: "Sonstige" }
  ]},
  { property: "grw", displayName: "GRW-Förderprogramme", type: FilterType.Default, options: [
    { displayName: "Alle Förderprogramme", value: null },
    { displayName: "Nur GRW-Förderprogramme", value: true },
    { displayName: "Keine GRW-Förderprogramme", value: false },
  ]},
  { property: "withProducts", displayName: "Telekom Produkte", type: FilterType.Default, options: [
    { displayName: "Alle Förderprogramme", value: null },
    { displayName: "Programme mit förderfähigen Produkten", value: true },
  ]}
];

export const LOGIN_SEARCH_FILTERS: SearchFilter[] = [
  { property: "favoriten", displayName: "Favoriten", type: FilterType.Default, options: [
    { displayName: "Alle Förderprogramme", value: null },
    { displayName: "Nur Favoriten", value: true },
    { displayName: "Keine Favoriten", value: false },
  ]}
]

export const SEARCH_SORTING: SearchSorting[] = [
  {
    property: "productCount",
    displayName: "Anzahl Produkte"
  },
  {
    property: "name",
    displayName: "Programmbezeichnung"
  },
  {
    property: "deadline",
    displayName: "Antragsfrist"
  }
  // Not yet supported due to insufficient data quality
  /*{
    property: "fundingAmount",
    displayName: "Förderhöhe"
  }*/
]
