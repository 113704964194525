import { Directive, ElementRef, Input, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { provideScrollPosition, ScrollPositionBase } from './scroll-position-base';

@Directive({
  selector: '[mfScrollPosition]',
  providers: [
    provideScrollPosition(ScrollPositionDirective)
  ]
})
export class ScrollPositionDirective extends ScrollPositionBase {

  private _elementRef: ElementRef<HTMLElement>

  @Output() scrollTopChange: Subject<number> = new Subject()
  @Input()
  public set scrollTop(pos: number) {
    this._elementRef.nativeElement.scrollTop = pos
  }
  public get scrollTop(): number {
    return this._elementRef.nativeElement.scrollTop
  }

  @Input("mfScrollPosition")
  public set elementRef(elementRef: ElementRef<HTMLElement>) {
    if (elementRef) {
      this._elementRef = elementRef
    }
  }

  constructor(elementRef: ElementRef<HTMLElement>) {
    super()

    this._elementRef = elementRef
  }

  ngOnInit() {
    // Fire updates on change
    fromEvent(this._elementRef.nativeElement, "scroll").pipe(
      map(() => this._elementRef.nativeElement.scrollTop)
    ).subscribe(this.scrollTopChange)
  }

}
