import { Directive, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DynamicOverlayComponent } from './dynamic-overlay.component';
import { OverlayEvents } from './overlay-events.enum';
@Directive({
  selector: '[overlayTriggerFor]',
  host: {
    '(click)': 'toggleOverlay($event)'
  }
})
export class OverlayTriggerDirective implements OnInit {
  public _overlayElement: DynamicOverlayComponent;

  private overlayEvents = new Subject<OverlayEvents>()

  @Input('overlayTriggerFor')
  get overlay() : DynamicOverlayComponent { return this._overlayElement}
  set overlay(overlay: DynamicOverlayComponent) {
    if(overlay === this._overlayElement) {
      return
    }
    this._overlayElement = overlay
    this._overlayElement.toggleOverlay = this.overlayEvents
  }

  constructor(
  ) { }

  ngOnInit() {
  }

  toggleOverlay(event: MouseEvent) {
    this.overlayEvents.next(OverlayEvents.open)

    event.stopPropagation();
    event.preventDefault();
  }

}
