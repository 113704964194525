import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[mfDynamicContentLink]'
})
export class DynamicContentLinkDirective {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  ngAfterViewInit() {
    const links = this.elementRef.nativeElement.querySelectorAll('a')
    links.forEach(link => {
      const hostname = link.hostname;
      if (hostname && !this.isLinkExternal(hostname)) {
        const urlTree = this.router.createUrlTree([link.getAttribute('href')])
        link.setAttribute('href', urlTree.toString())
        link.addEventListener('click', event => {
          event.preventDefault()
          this.router.navigateByUrl(urlTree)
        })
      }
    });
  }

  private isLinkExternal(hostname: string) : boolean {
    return isPlatformBrowser(this.platformId) && !hostname.includes(location.hostname)
  }

}
