<mat-toolbar class="toolbarNav">
  <ng-container *ngFor="let item of menuItems">
    <a mat-flat-button routerLink="{{item.path}}" *ngIf="!item.items; else overlayTrigger" routerLinkActive="active-link">
      <mat-icon class="material-icons color_blue" svgIcon="{{item.icon}}"></mat-icon>
      <span>{{item.name}}</span>
    </a>
    <ng-template #overlayTrigger>
      <button mat-flat-button [overlayTriggerFor]="extendedMenu" >
        <mat-icon class="material-icons color_blue" svgIcon="{{item.icon}}"></mat-icon>
        <span>{{item.name}}</span>
      </button>

      <mf-dynamic-overlay fullSize [maxWidth]="'380px'" toLeft hasBackdrop closeOnBackdropClick #extendedMenu>
        <div class="extended-menu-overlay">
          <div class="header">
            <span class="overlay-title">Mehr</span>
            <button (click)="closeOverlay()" class="close" mat-icon-button  aria-label="Menü schließen">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="content">
            <mat-list class="link-list">
              <ng-container *ngFor="let link of item.items">
                <a class="link-list-item" mat-list-item *ngIf="!link.external" routerLinkActive #rla="routerLinkActive" [activated]="rla.isActive" routerLink="{{link.path}}"  >
                  <span class="link-wrapper">
                    <span><mat-icon class="link-list-item-icon" svgIcon="{{link.icon}}"></mat-icon>{{ link.name }}</span>
                  </span>
                </a>
                <a class="link-list-item" mat-list-item *ngIf="link.external" target="_blank" href="{{link.path}}"  >
                  <span class="link-wrapper">
                    <span><mat-icon class="link-list-item-icon" svgIcon="{{link.icon}}"></mat-icon>{{ link.name }}</span> <mat-icon [inline]="true">launch</mat-icon>
                  </span>
                </a>
              </ng-container>
             </mat-list>
          </div>
        </div>
      </mf-dynamic-overlay>

    </ng-template>
  </ng-container>
</mat-toolbar>
