import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject, takeUntil, take } from 'rxjs';
import { SearchFilter, FilterValue, Filter, SearchOption } from '../../model';
import { SelectFilter } from '../select-filter';

@Component({
  selector: 'mf-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  providers: [
    { provide: SelectFilter, useExisting: SingleSelectComponent, multi: true }
  ]
})
export class SingleSelectComponent extends SelectFilter implements OnInit, AfterViewInit, OnDestroy {

  @Input() filter: SearchFilter;
  @Input() selected: FilterValue;

  @Output() valueChanged: EventEmitter<Filter> = new EventEmitter();

  /** control for the selected option */
  public itemSingleCtrl: FormControl<SearchOption> = new FormControl<SearchOption>(null);

  /** control for the MatSelect filter keyword */
  public itemFilterCtrl: FormControl<string> = new FormControl<string>('');

  /** list of items filtered by search keyword */
  public filteredItems: ReplaySubject<SearchOption[]> = new ReplaySubject<SearchOption[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  private defaultOption: SearchOption

  constructor() {
    super()
  }

  ngOnInit() {
    // find the default option
    this.defaultOption = this.filter.options.find(option => option.value === null)

    // set initial selection
    console.log(this.selected, this.filter.options.find(option => option.value === this.selected) ?? this.defaultOption)

    this.itemSingleCtrl.setValue(this.filter.options.find(option => option.value === this.selected) ?? this.defaultOption);

    // load the initial item list
    this.filteredItems.next(this.filter.options.slice());

    // listen for search field value changes
    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItems();
      });

    this.itemSingleCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(option => {
        this.valueChanged.emit({ property: this.filter.property, value: option.value })
      })
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public resetSelection(): void {
    this.itemSingleCtrl.setValue(this.defaultOption)
  }

  /**
   * Sets the initial value after the filteredItems are loaded initially
   */
  protected setInitialValue() {
    this.filteredItems
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredItems are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: SearchOption, b: SearchOption) => a && b && a?.value === b?.value;
      });
  }

  protected filterItems() {
    if (!this.filter.options) {
      return;
    }
    // get the search keyword
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredItems.next(this.filter.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItems.next(
      this.filter.options.filter(option => option.displayName.toLowerCase().indexOf(search) > -1)
    );
  }

}
