import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, tap } from 'rxjs';
import { UtagService } from 'src/app/components/analytics';
import { DeviceService } from 'src/app/services/device.service';
import { Environment } from 'src/environments/environment.iface';

/**
 * Intro component redirects to the app store on mobile devices and if the app is not installed yet.
 */
@Component({
  template: ''
})
export class IntroComponent {

  constructor(
    deviceService: DeviceService,
    environment: Environment,
    router: Router,
    route: ActivatedRoute,
    utag: UtagService
  ) {
    route.queryParams.pipe(
      tap(params => {
        const navigationLevels = ["intro"]
        if (params["tracking-id"]) {
          navigationLevels.push(params["tracking-id"])
        }
  
        utag.view({
          type: "theme",
          navigationLevels
        })
      }),
      delay(500) // need to delay to wait for the utag service to bootstrap.
    )
    .subscribe(() => {
      if (deviceService.isMobile && !deviceService.isStandalone) {
        window.location.href = environment.appStoreUrls[deviceService.osFamily]
      }
  
      router.navigate(['/start'])
    })
  }

}
