import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import equal from 'fast-deep-equal';
import cloneDeep from 'lodash.clonedeep';
import { BehaviorSubject, Observable, ReplaySubject, catchError, combineLatest, debounceTime, distinctUntilChanged, finalize, map, merge, of, scan, shareReplay, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { LoginService } from 'src/app/login';
import { toBoolean } from 'src/app/utilities';
import { Filter, FilterMap, FilterType, LOGIN_SEARCH_FILTERS, PromotionSearch, SEARCH_FILTERS, SEARCH_SORTING, SearchFilter, SearchSorting, SortDirection, Sorting } from '../../model';
import { Promotion } from '../../model/promotion.iface';
import { PromotionService } from '../../services';
import { LoaderStatus } from '../../../../components/loader';
import { SelectFilter } from '../select-filter';

@Component({
  selector: 'mf-promotion-search',
  templateUrl: './promotion-search.component.html',
  styleUrls: ['./promotion-search.component.scss']
})
export class PromotionSearchComponent implements OnInit {

  search$: Observable<PromotionSearch>
  result$: Observable<Promotion[]>
  $loadingStatus = new BehaviorSubject<LoaderStatus>(LoaderStatus.loading);
  hasError : boolean = false;
  searchFilters: Observable<SearchFilter[]>
  searchSortings = SEARCH_SORTING

  private query = new ReplaySubject<string>(1)
  private filter = new ReplaySubject<Filter>(1)
  private sorting = new ReplaySubject<Partial<Sorting>>(1)

  @ViewChildren(SelectFilter) filterSelect: QueryList<SelectFilter>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private promotionService: PromotionService,
    private loginService: LoginService
  ) {
    this.searchFilters = this.loginService.isLoggedIn.pipe(
      map(isLoggedIn => ([...SEARCH_FILTERS, ...(isLoggedIn ? LOGIN_SEARCH_FILTERS : [])]))
    )

    // Reduce the filters
    const filter = merge(
      this.route.queryParamMap.pipe(
        take(1),
        withLatestFrom(this.searchFilters),
        map(([params, filter]) => this.paramsToFilter(params, filter))
      ),
      this.filter.pipe(
        scan<Filter, FilterMap>((acc, val) => {
          const newAcc = {...acc}
          // If the value is null or the array length of the filter is 0 and the property is set,
          // then delete the property from the filter object
          if ((val.value === null || (Array.isArray(val.value) && val.value.length == 0)) && acc[val.property] !== undefined) {
            delete newAcc[val.property]
          } else
          // If the value is not null or the value is an array with elements,
          // then set the property in the filter object
          if (val.value !== null && (!Array.isArray(val.value) || val.value.length !== 0)) {
            newAcc[val.property] = val.value
          }

          return newAcc
        }, {})
      )
    )

    const query = merge(
      this.route.queryParamMap.pipe(
        take(1),
        map(params => params.get("query"))
      ),
      this.query
    )

    const sort = merge(
      this.route.queryParamMap.pipe(
        take(1),
        map(params => this.paramsToSort(params, SEARCH_SORTING))
      ),
      this.sorting
    ).pipe(
      scan((sorting, newValue) => {
        return {
          property: newValue.property ?? sorting.property,
          direction: newValue.direction ?? sorting.direction
        }
      })
    )

    this.search$ = combineLatest([query, filter, sort]).pipe(
      map(([query, filter, sort]) => cloneDeep({query, filter, sort})),
      tap(search => console.log(search))
    ).pipe(
      distinctUntilChanged(equal),
      tap(search => this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {
            ...search.query ? { query: search.query } : {},
            ...search.filter,
            sort: search.sort?.property + search.sort?.direction
          }
        }
      )),
      // Share the search observable to calculate the query only once.
      shareReplay(1)
    )

    this.result$ = this.search$.pipe(
      debounceTime(300),
      tap(() => {
        this.hasError = false;
        this.$loadingStatus.next(LoaderStatus.loading)
      }),
      switchMap(search => this.promotionService.getPromotions(search).pipe(
        catchError(() => {
          this.hasError = true;
          this.$loadingStatus.next(LoaderStatus.error);
          return of(null)
        }),
        finalize(() => {
          !this.hasError ? this.$loadingStatus.next(LoaderStatus.success) : null;
        })
      )),
      startWith([])
    )
  }

  ngOnInit(): void {
  }

  resetFilter() {
    this.filterSelect.forEach(filterSelect => filterSelect.resetSelection())
  }

  onSearchChange(query: string) {
    this.query.next(query)
  }

  setFilter(filter: Filter) {
    this.filter.next(filter)
  }

  setSorting(property: string) {
    console.log("Sorting", property)
    this.sorting.next({ property })
  }

  setSortingDirection(direction: SortDirection) {
    console.log("Direction", direction)
    this.sorting.next({ direction })
  }

  urlForPromotion(id: string): string {
    return window.location.origin + this.router.serializeUrl(this.router.createUrlTree(['/promotion', id]))
  }

  private paramsToFilter(params: ParamMap, filter: SearchFilter[]): FilterMap {
    // Reduce the params map to a filter map
    return filter.reduce<FilterMap>((acc, filter) => {
      if (params.has(filter.property)) {
        if (filter.type == FilterType.CommaSeparated) {
          let values = params.getAll(filter.property)
          if (values.length === 1) {
            values = values[0].split(';')
          }
          acc[filter.property] = values
        } else {
          acc[filter.property] = toBoolean(params.get(filter.property))
        }
      }

      return acc
    }, {})
  }

  private paramsToSort(params: ParamMap, sortings: SearchSorting[]): Sorting {
    const matcher = /([\w]+)(Asc|Desc)/.exec(params.get("sort"))

    const property = matcher?.[1]
    const direction = matcher?.[2]
    if (property && direction && sortings.find(sorting => sorting.property == property)) {
      return {
        property,
        direction: direction as SortDirection
      }
    }

    return { property: this.searchSortings[0].property, direction: 'Desc' }
  }

}
