import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestoreScrollingService {

  private scrollPositions: { [tag: string]: number } = {}

  private lastTrigger: Observable<string>

  constructor(
    router: Router
  ) {
    this.lastTrigger = router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(event => (<NavigationStart>event).navigationTrigger),
      shareReplay(1)
    )
  }

  public getScrollPosition(tag: string): Observable<number> {
    return this.lastTrigger.pipe(
      map(trigger => trigger == 'popstate' ? this.scrollPositions[tag] : 0)
    )
  }

  public setScrollPosition(tag: string, position: number) {
    this.scrollPositions[tag] = position
  }
}
