import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'mf-truncate-card',
  templateUrl: './truncate-card.component.html',
  styleUrls: ['./truncate-card.component.scss']
})
export class TruncateCardComponent implements AfterViewInit {

  @ViewChild('content') content : ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkHeight();
  }

  public canToggle : boolean = true;

  public open : boolean = false;

  constructor() {}

  ngAfterViewInit() {

    this.checkHeight();
  }

  toggleContent() {
    this.open = !this.open
  }

  checkHeight() {
    if (this.content.nativeElement.firstChild.clientHeight >= this.content.nativeElement.clientHeight && !this.open) {
      this.canToggle = true;
    } else {
      this.canToggle = false;
    }
  }
}
