<mat-card class="accordion-nav-card" id="{{elementId}}">
  <img mat-card-image [src]="item.imageSrc ? ('/api/resources/categories/'+item.id+'.jpg' | secure | async) : null" aria-hidden="true">
  <mat-card-header>
    <mat-card-title>{{item.name}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <span class="m-b-16 block">{{item.description}}</span>
  </mat-card-content>
  <mat-expansion-panel (afterExpand)="emitExpanded()" class="no-elevation no-border-radius" [expanded]="openState" (opened)="openState = true" (closed)="openState = false">
    <mat-expansion-panel-header class="mf-expansion-toggle">
      <mat-panel-title class="uppercase">
        {{toggleLabel}}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-nav-list class="p-0">
      <mat-list-item class="p-l-24 p-r-16" [routerLink]="['/produkte/category', item.id, subcat.id]" role="link" *ngFor="let subcat of item.children">
        <span matListItemTitle class="flex flex-between full-width">
          <span>{{ subcat.name }}</span>
          <span class="count">({{subcat.children.length}}) <mat-icon>chevron_right</mat-icon> </span>
        </span>

      </mat-list-item>
    </mat-nav-list>
  </mat-expansion-panel>
</mat-card>
