import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Aktionsbereich, Style, UtagService } from '../../components/analytics';
import { LoginService, UserService } from "../../login";
import { MatDialog } from '@angular/material/dialog';
import { filter, finalize, mergeMap, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {
  user_name?: string;
  user_email?: string;
  user_plz?: string;

  confirmDeletion: boolean = false;

  @ViewChild('deleteProfileDialogue') deleteProfileDialogue: TemplateRef<any>;

  constructor(
    private userService: UserService,
    private loginService: LoginService,
    private utag: UtagService,
    public dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.get_user_data();
  }

  get_user_data(){
    this.userService.getProfile().subscribe(user => {
      if (user) {
        this.user_name = user.firstname +" "+ user.lastname;
        this.user_email = user.email;
        this.user_plz = user.postalCode;
      }
    });
  }

  deleteUserClick(): void {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "profil-loeschen");

    const dialogRef = this.dialog.open(this.deleteProfileDialogue, {
      width: '320px',
      maxWidth: '100%',
    });

    dialogRef.afterClosed().pipe(
      filter(() => !!this.confirmDeletion),
      switchMap(() => this.userService.deleteUser()),
      mergeMap(() => this.loginService.logout()),
      finalize(() => {
        this.confirmDeletion = false;
        this.router.navigate(['/']);
      })
    )
    .subscribe({
      next: () => {
        this.utag.aktion(Aktionsbereich.Popup, Style.Text, "delete_user_succesfull");
        this.toastr.success('Ihr Profil wurde erfolgreich gelöscht.', 'Vielen Dank!');
      },
      error: () => {
        this.utag.aktion(Aktionsbereich.Popup, Style.Text, "delete_user_error");
        this.toastr.error('Ihr Profil konnte leider nicht gelöscht werden, bitte probieren Sie es später erneut!', 'Vielen Dank!');
      }
    });
  }

  logout() {
    this.loginService.logout();
  }
}
