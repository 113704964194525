import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {

    this.loginService.isLoggedIn.subscribe(loggedIn => {
      if(loggedIn) {
        this.router.navigate(['/profile']);
      }
    })

  }

  ngOnInit(): void {
  }

  login() {
    this.loginService.login().subscribe()
  }


}
