import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Filter, FilterValue, SearchFilter, SearchOption } from '../../model';
import { SelectFilter } from '../select-filter';

@Component({
  selector: 'mf-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    { provide: SelectFilter, useExisting: MultiSelectComponent, multi: true }
  ]
})
export class MultiSelectComponent extends SelectFilter implements OnInit, AfterViewInit, OnDestroy {

  @Input() filter: SearchFilter;
  @Input() selected: FilterValue;

  @Output() valueChanged: EventEmitter<Filter> = new EventEmitter();
  
  /** control for the selected item for multi-selection */
  public itemMultiCtrl: FormControl<SearchOption[]> = new FormControl<SearchOption[]>([]);

  /** control for the MatSelect filter keyword multi-selection */
  public itemMultiFilterCtrl: FormControl<string> = new FormControl<string>('');

  /** list of items filtered by search keyword */
  public filteredItemsMulti: ReplaySubject<SearchOption[]> = new ReplaySubject<SearchOption[]>(1);

  /** local copy of filtered items to help set the toggle all checkbox state */
  protected filteredItemsCache: SearchOption[] = [];


  /** flags to set the toggle all checkbox state */
  isIndeterminate = false;
  isChecked = false;

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor() {
    super()
  }

  ngOnInit() {
    this.itemMultiCtrl.setValue(this.valueFromSelected(this.selected));

    // load the initial item list
    this.filteredItemsMulti.next(this.filter.options.slice());

    // listen for search field value changes
    this.itemMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItemsMulti();
        this.setToggleAllCheckboxState();
      });

    // listen for multi select field value changes
    this.itemMultiCtrl.valueChanges.pipe(
      takeUntil(this._onDestroy)
    ).subscribe(options => {
      this.emitFilter(options)
      this.setToggleAllCheckboxState();
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredItemsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.itemMultiCtrl.patchValue(val);
        } else {
          this.itemMultiCtrl.patchValue([]);
        }
      });
  }

  onSelectedRemove(itemToRemove: SearchOption) {
    this.itemMultiCtrl.setValue(this.itemMultiCtrl.value.filter(item => item !== itemToRemove));
  }

  public resetSelection() {
    this.itemMultiCtrl.setValue([]);
  }

  /**
   * Sets the initial value after the filteredItems are loaded initially
   */
  protected setInitialValue() {
    this.filteredItemsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredItems are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: SearchOption, b: SearchOption) => a?.value === b?.value;
      });
  }

  protected filterItemsMulti() {
    if (!this.filter.options) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrl.value;
    if (!search) {
      this.filteredItemsCache = this.filter.options.slice();
      this.filteredItemsMulti.next(this.filteredItemsCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsCache = this.filter.options.filter(item => item.displayName.toLowerCase().indexOf(search) > -1);
    this.filteredItemsMulti.next(this.filteredItemsCache);
  }

  protected setToggleAllCheckboxState() {
    let filteredLength = 0;
    if (this.itemMultiCtrl && this.itemMultiCtrl.value) {
      this.filteredItemsCache.forEach(el => {
        if (this.itemMultiCtrl.value.indexOf(el) > -1) {
          filteredLength++;
        }
      });
      this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredItemsCache.length;
      this.isChecked = filteredLength > 0 && filteredLength === this.filteredItemsCache.length;
    }
  }

  private emitFilter(options: SearchOption[]) {
    const value = options.map(option => option.value as string)
    this.valueChanged.emit({ property: this.filter.property, value })
  }

  private valueFromSelected(selected: FilterValue): SearchOption[] {
    if (Array.isArray(selected)) {
      return selected.flatMap(value => this.filter.options.find(option => option.value == value) ?? [])
    } else {
      const option = this.filter.options.find(option => option.value == selected)
      return option ? [option] : []
    }
  }
}
