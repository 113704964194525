import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OIDCService } from './oidc.service';
import { SCIMRegistration } from './model/scrim-registration.iface';
import { UserProfile } from './model/user-profile.iface';

//regelt den Telekom-Login/Logout sowie die Registration neuer User

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient,
    private OIDCService: OIDCService
  ) {}

  public getProfile(): Observable<UserProfile> {
    return this.OIDCService.getProfile();
  }

  public registerUser(registration: SCIMRegistration): Observable<unknown> {
    const url = "/api/users";
    return this.http.post<unknown>(url, registration);
  }

  public deleteUser(): Observable<void> {
    const url = "/api/users";
    return this.http.delete<void>(url);
  }

  public restoreFavorites(): Observable<string[]> {
    const url = "/api/users/favorites"
    return this.http.get<string[]>(url)
  }

  public saveFavorites(favorites: string[]): Observable<unknown> {
    const url = "/api/users/favorites"
    return this.http.post<unknown>(url, favorites)
  }

  public syncSearchProfile() {
  }
}
