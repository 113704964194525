import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtagService } from './utag.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    //LoginModule
  ],
  exports: [
  ],
  providers: [
    UtagService
  ]
})
export class AnalyticsModule { }
