<mat-card>
  <mat-card-header>
    <ng-content select="[slot=title]"></ng-content>
  </mat-card-header>
  <mat-card-content>
    <div class="content" [class.open]="open" [class.can-toggle]="canToggle" #content>
      <div>
        <ng-content select="[slot=content]"></ng-content>
      </div>
    </div>
    <button mat-button class="full-width toggle-button" (click)="toggleContent()">
      <ng-container *ngIf="!open" >Zeige alle Details </ng-container>
      <ng-container *ngIf="open">Schließe alle Details </ng-container>
      <mat-icon  *ngIf="!open" iconPositionEnd>expand_more</mat-icon>
      <mat-icon *ngIf="open" iconPositionEnd>expand_less</mat-icon>
    </button>
  </mat-card-content>
</mat-card>
