import { Component, OnInit } from '@angular/core';
import { UserService } from "../../login/user.service";
import { ContactService } from "../../services/contact.service";
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Aktionsbereich, Style, UtagService } from '../../components/analytics';
import { flavor } from 'src/environments/flavor';
import { UserProfile } from '../../login';
import { catchError, map } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { CampagneField } from './campagne-field';


@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.scss'],
  //muss reingenommen werden wenn wir das Kontaktformular als Snippet ausliefern
  //encapsulation: flavor.snippet ? ViewEncapsulation.None : ViewEncapsulation.None
})
export class ContactformComponent implements OnInit {
  private backURL: string = "t_ansprechpartner";

  contactForm: FormGroup;

  isHotline: boolean = flavor.isHotline;
  campagneKampagne: CampagneField[] = [];

  type: String = "";

  loading_spinner = false;
  con_text_color = "white";

  isSnippet : boolean = flavor.snippet;

  successpage: boolean = false;

  constructor(
    private userService: UserService,
    private ContactService: ContactService,
    private route: ActivatedRoute,
    private router: Router,
    private utag: UtagService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    combineLatest([
      this.userService.getProfile().pipe(
        catchError(() => of(undefined))
      ),
      this.route.queryParamMap.pipe(
        map(routeParams => {
          //* Get the query parameters in non-routed environments.
          const urlParams = new URLSearchParams(window.location.search)
          const params = flavor.snippet ? urlParams : routeParams
          return params.get("type") || "default"
        })
      ),
      this.ContactService.getCampagnes().pipe(
        map(campagnes => campagnes.find(campagne => campagne.kampagne == "Kampagne")?.kampagnenfeld ?? [])
      )
    ])
    .subscribe(([profile, type, campagneFields]) => {
      this.initFormGroup(profile, type, campagneFields);
    });

  }

  private initFormGroup(user: UserProfile | undefined, type: string, campagneFields: CampagneField[]) {
    this.type = type
    this.campagneKampagne = campagneFields

    let quelle: string = "Meine Förderung App";
    if (this.isHotline) {
      quelle = "Förderhotline";
    }
    if (flavor.snippet) {
      quelle = "Schubkraft Website"
    }

    this.contactForm = this.formBuilder.group({
      quelle: quelle,
      firma: user?.corporation || "",
      vorname: user?.firstname || "",
      nachname: user?.lastname || "",
      strassehausnummer: "",
      plz: [user?.postalCode || ""],
      stadt: "",
      email: [user?.email || "", Validators.email],
      erreichbarkeit: "0",
      vorwahl: "",
      rufnummer: "",
      kommentar: "",
      quickcheck: this.type === "quickcheck" ? JSON.parse(localStorage.getItem("quickcheck_status") || "{}") : undefined,
      isHotline: this.isHotline,
      kundennummer: "",
      campagne: "",
      rueckruf: [false, Validators.requiredTrue],
      datenschutz: [false, Validators.requiredTrue]
    });
  }

  cancel(): void {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "abbrechen");
    history.back()
  }

  onSubmit(): void {
    this.utag.aktion(Aktionsbereich.Content, Style.Button, "absenden");

    if (this.contactForm.valid) {
      this.loading_spinner = true;
      this.ContactService.sendContactForm(this.contactForm.value).subscribe(() => {
        this.utag.aktion(Aktionsbereich.Popup, Style.Text, "kontaktformular_uebermittelt");

        // für das Snippet
        let url = window.location.href;
        if (url.includes("/magenta-business-collaboration/foerderungen-homeoffice/foerderung-digitalisierung/")) {
          this.toastr.success("Formular erfolgreich gesendet", undefined, {
            positionClass: 'toast-top-center'
          });
          this.loading_spinner = false;
          window.location.href = "https://geschaeftskunden.telekom.de/success-kontakt-schubkraft";
        } else {
          this.toastr.success("Formular erfolgreich gesendet");
          this.router.navigate([this.backURL]);
          this.loading_spinner = false;
          this.successpage = true;        }

      }, (error: any) => {
        this.utag.aktion(Aktionsbereich.Popup, Style.Text, "kontaktformular_fehlgeschlagen");
        if (error.status == 404) {
          this.toastr.error("Server kann nicht erreicht werden");
        } else {
          this.toastr.error("Bitte überprüfen Sie Ihre Eingabe.", "Übermittlung fehlgeschlagen");
        }

        this.loading_spinner = false;
      });
    } else {
      this.toastr.error("Formular ist nicht valide.");
    }
  }
}
