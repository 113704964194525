import { Component, Input } from '@angular/core';
import { Empfehlung } from '../../../services/news.service';

@Component({
  selector: 'mf-empfehlungs-card',
  templateUrl: './empfehlungs-card.component.html',
  styleUrls: ['./empfehlungs-card.component.scss']
})
export class EmpfehlungsCardComponent {

  @Input() data: Empfehlung;

}
