import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subject, auditTime, fromEvent, takeUntil } from 'rxjs';
import { ToBoolean } from '../../utilities';

@Directive({
  selector: '[mfScrollShadow]'
})
export class ScrollDirective implements AfterContentInit, OnDestroy {

  @Input() canScrollUpClass: string = 'can-scroll-up';
  @Input() @ToBoolean() onlyTop: boolean;
  @Input() canScrollDownClass: string = 'can-scroll-down';
  @Input() auditTimeMs: number = 50;

  private readonly destroy$: Subject<void> = new Subject();


  constructor(private renderer: Renderer2,readonly hostElement: ElementRef,) {
    fromEvent(this.hostElement.nativeElement, 'scroll').pipe(
      auditTime(this.auditTimeMs),
      takeUntil(this.destroy$),
      ).subscribe(() => {
        this.setClasses();
      })

    fromEvent(window, 'resize').pipe(
      auditTime(this.auditTimeMs),
      takeUntil(this.destroy$),
      ).subscribe(() => {
        this.setClasses();
      })

  }

  public ngAfterContentInit(): void {
    this.renderer.addClass(this.hostElement.nativeElement, 'scroll-shadow');
    setTimeout(() => {this.setClasses();},500)

  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setClasses() {
    const canScrollUp = this.hostElement.nativeElement.scrollTop > 0;
    const canScrollDown = Math.ceil(this.hostElement.nativeElement.scrollTop + this.hostElement.nativeElement.clientHeight) < this.hostElement.nativeElement.scrollHeight;
    this.renderer.removeClass(this.hostElement.nativeElement, this.canScrollDownClass);
    this.renderer.removeClass(this.hostElement.nativeElement, this.canScrollUpClass);

    if (this.canScrollUpClass && canScrollUp) {
      this.renderer.addClass(this.hostElement.nativeElement, this.canScrollUpClass);
    }

    if (this.canScrollDownClass && canScrollDown && !this.onlyTop) {
      this.renderer.addClass(this.hostElement.nativeElement, this.canScrollDownClass);
    }
  }


}


