import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PromotionSearchComponent } from './components/promotion-search/promotion-search.component';
import { PromotionDetailsComponent } from './components/promotion-details/promotion-details.component';

const routes: Routes = [
  {
    path: '',
    component: PromotionSearchComponent,
    title: "Fördersuche",
    data: {
      animation: 'promotion',
      // no analytics --> done via page results
    }
  },
  {
    path: ':id',
    component: PromotionDetailsComponent,
    data: {
      analytics: {
        type: "theme",
        navigationLevels: ["foerderprogramm",":id"]
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PromotionRoutingModule { }
