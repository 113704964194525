import { Injectable } from '@angular/core';
import platform from 'platform';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  public readonly info = platform.parse(navigator.userAgent)
  public readonly osFamily = this.info.os?.family
  public readonly isMobile = ['iOS', 'Android'].includes(this.osFamily)

  public get isStandalone(): boolean {
    /** Some iOS Hacks */
    const iOSStandalone = (<any>window.navigator).standalone // Safari has a custom property to identify the standalone mode
    return window.matchMedia('(display-mode: standalone)').matches || iOSStandalone || this.isIOSWebView
  }

  public readonly isIOS = ['iOS', 'OS X'].includes(this.osFamily)
  public readonly isSafari = /safari/.test(navigator.userAgent.toLowerCase())
  public readonly isIOSWebView = this.isIOS && !this.isSafari

  constructor() {}

}
