export type Map<T> = { [key:string] : T };

export function toBoolean(value: any): boolean {
  return value != null && `${value}` !== 'false' && value != '0';
}

export function toNumber(value: any): number {
  return  value === null ? 0 : 
          value === undefined ? 0 :
          value === '' ? 1 : 
          Number(value.toString())
}

export class CustomError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

interface typeMap { // for mapping from strings to types
  string: string;
  number: number;
  boolean: boolean;
}

export type PrimitiveOrConstructor = // 'string' | 'number' | 'boolean' | constructor
  | { new (...args: any[]): any }
  | keyof typeMap;

// infer the guarded type from a specific case of PrimitiveOrConstructor
type GuardedType<T extends PrimitiveOrConstructor> = T extends { new(...args: any[]): infer U; } ? U : T extends keyof typeMap ? typeMap[T] : never;

// finally, guard ALL the types!
export function typeGuard<T extends PrimitiveOrConstructor>(o: any, className: T): o is GuardedType<T> {
    const localPrimitiveOrConstructor: PrimitiveOrConstructor = className;
    if (typeof localPrimitiveOrConstructor === 'string') {
    return typeof o === localPrimitiveOrConstructor;
  }
  return o instanceof localPrimitiveOrConstructor;
}
