import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TitleStrategy, RouterStateSnapshot } from "@angular/router";
import { ReplaySubject } from "rxjs";

@Injectable()
export class PageTitleStrategy extends TitleStrategy {

  private readonly _title = new ReplaySubject<string>(1)

  public readonly title = this._title.asObservable()

  constructor(private readonly titleService: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    this.titleService.setTitle(`Meine Förderung ${ title ? ' - ' + title : '' }`)

    this._title.next(title)
  }
}