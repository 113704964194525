import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, NgZone, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CarouselAnimationDirection, CarouselConfig } from './carousel.interface';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Subscription } from 'rxjs';
import { scrollIntoView } from "seamless-scroll-polyfill";

export interface CarouselItem {
  title: string;
  link: string;
  imgUrl: string;
  isExternal?: boolean;
}

@Component({
  selector: 'mf-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})export class CarouselComponent  {
  @Input() data : CarouselItem[] = [];

  @Output() indexChange : EventEmitter<number> = new EventEmitter();

  public activeIndex = 0

  @ViewChildren('carouselItem') carouselItems: QueryList<ElementRef<HTMLElement>>

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.scrollTo(this.activeIndex)
  }

  scrollTo(index: number): void {
    let activeItem : HTMLElement = this.carouselItems?.toArray()[index]?.nativeElement;
    if(activeItem) {
      scrollIntoView(activeItem, { behavior: "smooth", block: "nearest"});
    }
    this.activeIndex = index;
    this.indexChange.emit(this.activeIndex);
  }

  onSwipeLeft() {
    if(this.activeIndex !== (this.carouselItems.length - 1)) {
      this.scrollTo(this.activeIndex + 1);
    }
  }

  onSwipeRight() {
    if(this.activeIndex !== 0) {
      this.scrollTo(this.activeIndex - 1);
    }
  }



}
