import { Component, Input } from '@angular/core';

@Component({
  selector: 'mf-loader-error',
  templateUrl: './loader-error.component.html',
  styleUrls: ['./loader-error.component.scss']
})
export class LoaderErrorComponent {

  @Input() errorText: string = 'Es gab einen Fehler beim Laden des Inhalts.';

  reload() {
    window.location.reload();
  }
}
