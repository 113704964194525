<mf-animated-header>
</mf-animated-header>

<div class="scroll-container" mfScrollShadow>
  <div class="container" #contentArea>
    <div class="row justify-content-center">
      <div class="m-t-16 m-b-16 col-12 col-lg-8">
        <mat-card>
          <img mat-card-image src="./assets/images/Bild_T-Ansprechpartner@3x.png" alt="Werbefoto Ansprechpartner">
          <mat-card-header>
              <mat-card-title>Was können wir für Sie tun? Wählen Sie den gewünschten Kontaktweg.</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            Wir helfen Ihnen bei allen relevanten Fragen zu den
            Förderprogrammen der Bundesregierung in
            Verbindung mit den Telekom Produkten und halten Sie
            auf dem Laufenden.
          </mat-card-content>

      </mat-card>

        <div class="row justify-content-center">
          <div class="m-t-16 col-12 col-lg-6">
            <mat-card>
              <mat-card-header>
                  <mat-card-title>Schreiben Sie uns</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                Sie haben Fragen zu Ihrem Vorhaben  oder eines der Förderprogramme? Dann nehmen Sie am besten gleich Kontakt zu uns auf und lassen sich beraten.
              </mat-card-content>
              <mat-card-actions  align="end">
                <button [trackClick]="['content', 'button', 'kontaktformular']" role="link" routerLink="/contactform" [queryParams]="{'type': 'default'}" color="primary" mat-flat-button>
                  <span class="uppercase">Zum Kontaktformular</span> <mat-icon iconPositionEnd>mail</mat-icon>
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
          <div class="m-t-16 col-12 col-lg-6">
            <mat-card>
              <mat-card-header>
                  <mat-card-title>Sprechen Sie mit uns</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                Kostenlos, Montag bis Freitag 8-16 Uhr.
              </mat-card-content>
              <mat-card-actions  align="end">
                <a class="mf-button" *ngIf="general_advice | async as general_advice" [trackClick]="['content', 'button', 'jetzt-anrufen']" [href]=" 'tel:' + general_advice.allgemeine_telefon" target="_blank" mat-flat-button>
                  <span class="uppercase">Jetzt anrufen</span> <mat-icon iconPositionEnd>phone</mat-icon>
                </a>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
