import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ComponentsModule, ScrollingModule } from "../../components";
import { CardsModule } from "../../components/cards";
import { DynamicOverlayModule } from "../../components/dynamic-overlay";
import { LayoutModule } from "../../components/layout";
import { PromotionRoutingModule } from "./promotion-routing.module";
import { PromotionService } from "./services/promotion.service";
import { MatCardModule } from "@angular/material/card";
import { FavoriteService } from "./services";
import { UtilitiesModule } from "src/app/utilities";
import { PromotionSearchComponent } from './components/promotion-search/promotion-search.component';
import { PromotionDetailsComponent } from './components/promotion-details/promotion-details.component';
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { MultiSelectComponent } from "./components/multi-select/multi-select.component";
import { MatInputModule } from "@angular/material/input";
import { MatChipsModule } from "@angular/material/chips";
import { SingleSelectComponent } from './components/single-select/single-select.component';
import { LoaderModule } from "../../components/loader/loader.module";

@NgModule({
  declarations: [
    PromotionSearchComponent,
    PromotionDetailsComponent,
    DisclaimerComponent,
    MultiSelectComponent,
    SingleSelectComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    DynamicOverlayModule,
    CardsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatFormFieldModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatBadgeModule,
    LayoutModule,
    MatListModule,
    PromotionRoutingModule,
    VirtualScrollerModule,
    UtilitiesModule,
    LoaderModule,
    MatInputModule,
    MatChipsModule,
    ScrollingModule
  ],
  providers: [
    PromotionService,
    FavoriteService
  ],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PromotionModule { }
