<div class="quickcheck-container" [class.snippet]="snippetOrHotline" id="quickcheck-container">
  <div class="full-width" [ngSwitch]="ui_state">
    <ng-container *ngSwitchCase="QuickCheckUIState.intro">
      <ng-container *ngTemplateOutlet="intro"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="QuickCheckUIState.entry">
      <ng-container *ngTemplateOutlet="entry"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="QuickCheckUIState.quickcheck">
      <ng-container *ngTemplateOutlet="quickcheck"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="QuickCheckUIState.result">
      <ng-container *ngTemplateOutlet="result"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="QuickCheckUIState.download">
      <ng-container *ngTemplateOutlet="download"></ng-container>
    </ng-container>
  </div>
</div>

<!--Einleitung-->
<ng-template #intro>
  <div class="quickcheck-intro" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100">
      <h1 class="step-headline force-center" *ngIf="!isHotline">Willkommen beim<br>Fördermittel Quick-Check</h1>
      <h1 class="step-headline force-center" *ngIf="isHotline">Willkommen bei<br>der Förderhotline</h1>
    </div>
    <div class="full-width pseudo-card" fxLayout="row wrap" fxLayoutAlign="space-between none">
      <div fxFlex="100" fxFlex.gt-sm="33">
        <app-info-box>
          <ng-container icon>
            <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
              <path d="m30.2604168 23.6250001-9.1145834-5.9791667v4.9583334h-14.94791671c-2.18750001 0-4.01041668-1.8229167-4.01041668-4.0104167s1.82291667-4.0104167 4.01041668-4.0104167h15.31250011c3.4270833 0 6.1979166-2.7708334 6.1979166-6.1979167 0-3.42708335-2.7708333-6.19791669-6.1979166-6.19791669h-15.16666677c-.43750001-1.23958334-1.67708334-2.18750001-3.06250002-2.18750001-1.82291667 0-3.28125001 1.45833334-3.28125001 3.28125001 0 1.82291668 1.45833334 3.28125002 3.28125001 3.28125002 1.45833334 0 2.62500001-.94791667 3.06250002-2.18750001h15.16666677c2.1875 0 4.0104166 1.82291667 4.0104166 4.01041668 0 2.1875-1.8229166 4.0104167-4.0104166 4.0104167h-15.31250011c-3.42708335 0-6.19791669 2.7708333-6.19791669 6.1979167 0 3.4270833 2.77083334 6.1979167 6.19791669 6.1979167h14.94791671v4.8125z" fill-rule="evenodd" transform="translate(5.645833 5.1875)" />
            </svg>
          </ng-container>
          <ng-container content>
            Wir begleiten Sie Schritt für Schritt auf dem Weg zu Ihrer Förderung.
          </ng-container>
        </app-info-box>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="33">
        <app-info-box>
          <ng-container icon>
            <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
              <path d="m16.0416667 0c8.8958334 0 16.0416666 7.14583336 16.0416666 16.0416667 0 8.8958334-7.1458332 16.0416666-16.0416666 16.0416666-8.89583334 0-16.0416667-7.1458332-16.0416667-16.0416666 0-8.89583334 7.21875003-16.0416667 16.0416667-16.0416667zm0 2.18750001c-7.65625 0-13.85416669 6.19791669-13.85416669 13.85416669 0 7.6562501 6.19791669 13.8541666 13.85416669 13.8541666 7.6562501 0 13.8541666-6.1979165 13.8541666-13.8541666 0-7.65625-6.1979165-13.85416669-13.8541666-13.85416669zm-7.36458333 18.73958339c.51041667-.3645833 1.16666667-.21875 1.53125003.2916667 1.4583333 2.2604167 3.6458333 3.5729167 5.8333333 3.5729167s4.3750001-1.3125 5.8333334-3.5729167c.3645833-.5104167 1.0208333-.65625 1.53125-.2916667.5104167.2916667.65625.9479167.2916667 1.53125-1.8958334 2.9166667-4.7395834 4.59375-7.6562501 4.59375-2.9166666 0-5.7604167-1.6770833-7.65625-4.59375-.36458333-.5104166-.21875-1.1666666.29166667-1.53125zm2.26041663-11.44791669c1.2081229 0 2.1875.97937709 2.1875 2.18749999s-.9793771 2.1875-2.1875 2.1875c-1.20812285 0-2.18749996-.9793771-2.18749996-2.1875s.97937711-2.18749999 2.18749996-2.18749999zm10.2083334 0c1.2081229 0 2.1875.97937709 2.1875 2.18749999s-.9793771 2.1875-2.1875 2.1875-2.1875-.9793771-2.1875-2.1875.9793771-2.18749999 2.1875-2.18749999z" fill-rule="evenodd" transform="translate(3.458333 3.458333)" />
            </svg>
          </ng-container>
          <ng-container content>
            Profitieren Sie von unserer Expertise bei der Suche nach einem passenden Förderprogramm.
          </ng-container>
        </app-info-box>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="33">
        <app-info-box>
          <ng-container icon>
            <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
              <path d="m29.6289583 23.896341-5.46 5.46-3.43-3.5c-.42-.42-.42-1.05 0-1.47s1.05-.42 1.47 0l1.96 1.96 3.92-3.92c.42-.42 1.05-.42 1.47 0s.49 1.05.07 1.47zm-4.375-6.8891667c-4.580625 0-8.2935416 3.7129167-8.2935416 8.295 0 4.5791667 3.7129166 8.2935417 8.2935416 8.2935417 4.5820834 0 8.295-3.714375 8.295-8.2935417 0-4.5820833-3.7129166-8.295-8.295-8.295zm-17.57291663 8.5560417c.60375 0 1.09375-.49 1.09375-1.09375s-.49-1.09375-1.09375-1.09375-1.09375.49-1.09375 1.09375.49 1.09375 1.09375 1.09375zm0-8.7310417c.60375 0 1.09375-.49 1.09375-1.09375s-.49-1.09375-1.09375-1.09375-1.09375.49-1.09375 1.09375.49 1.09375 1.09375 1.09375zm9.10729163 14.6241667h-12.50374997c-1.21333333 0-2.14229166-.9289584-2.14229166-2.14375v-22.78354169h2.856875v-1.42916667c0-.214375 0-.49875.07145833-.71458333h-5.070625v24.92729169c0 2.3566666 1.92791667 4.2845833 4.28458333 4.2845833h14.58916667c-.7933333-.6110417-1.4947917-1.3314583-2.0854167-2.1408333zm1.7820834-26.42937503c0-1.21333333-.9289584-2.14229166-2.1422917-2.14229166h-1.4291667v-.64458334c0-1.07041666-.713125-1.999375-1.715-2.21229166-1.35625-.21583334-2.5710416.85604166-2.5710416 2.14229166v.71458334h-1.42770837c-1.21479166 0-2.14229166.92895833-2.14229166 2.14229166v2.85833334h11.42750003zm2.14375 1.50208334h2.856875v8.43937499c.5497916-.0904167 1.1083333-.14875 1.6829166-.14875.1545834 0 .30625.0160417.459375.0233333v-10.45770829h-5.0720833c.0729167.21583333.0729167.50020833.0729167.71458333zm-8.6814584 16.89041669c-.5716666 0-1.071875.5002083-1.071875 1.071875 0 .5716666.5002084 1.071875 1.071875 1.071875h2.7533334c-.0029167-.0889584-.013125-.1735417-.013125-.2610417 0-.6445833.0670833-1.2702083.1779166-1.8827083zm0-8.7602084c-.5716666 0-1.071875.5002084-1.071875 1.0704167 0 .573125.5002084 1.0733333 1.071875 1.0733333h6.5377084c.5716666 0 1.071875-.5002083 1.071875-1.0733333 0-.5702083-.5002084-1.0704167-1.071875-1.0704167z" fill-rule="evenodd" transform="translate(3.711229 3.687367)" />
            </svg>
          </ng-container>
          <ng-container content>
            Ermitteln Sie mit unserem Quick-Check Ihre Förderchancen auf dem Weg zu einem erfolgreichen Projekt.
          </ng-container>
        </app-info-box>
      </div>
      <div  class="button-wrapper" fxFlex="100" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="12px">
        <button (click)="event_startQuickcheck();utag_startQuickcheckClick()" mat-flat-button color="primary" class="btn_card_start_qc mf-button-big">Jetzt Förderfähigkeit prüfen</button>
        <button *ngIf="isHotline" (click)="navigateToContact('quickcheck_start')" mat-stroked-button color="primary" class="mf-button-big btn_card_start_qc">Zum Kontaktformular</button>
    </div>
    </div>
  </div>
</ng-template>

<!-- Auswahl Quickcheck -->
<ng-template #entry>
  <div class="quickcheck-entry" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100">
      <mf-page-title></mf-page-title>
      <h1 class="step-headline">Für welche Förderprogramme interessieren Sie sich?</h1>
    </div>
    <div class="full-width" fxLayout="row wrap" fxLayoutAlign="space-between none">
      <div fxFlex="100">
        <mat-radio-group [(ngModel)]="ui_answerSingleSelection" name="choosenQC">
          <ng-container *ngFor="let answer of ui_answers; let i = index">
            <mat-radio-button *ngIf="ui_forcedQuickchecks.length === 0 || ui_forcedQuickchecks.indexOf(answer.AntwortId) !== -1" id="quickcheck_choice_radio{{i}}" name="answer" [value]="i" class="entry-item">
              <div class="radio-card-item">
                <img 
                  class="image"
                  [src]="(('backendUrl' | env) + '/resources/quickcheck/' + answer.AntwortId + '.png') | secure | async"
                  (error)="onImageNotFound($event)"
                  aria-hidden="true">
                <div class="content"><span>{{answer.Antwort}}</span></div>
              </div>
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>

      <div class="button-wrapper" fxFlex="100" fxLayoutAlign="space-between center" fxLayout="row-reverse" fxLayoutGap="12px">
        <button mat-flat-button fxFlexAlign="end" color="primary" class="mf-button-big" (click)="event_submitEntryAnswer()" value="Quick-Check beginnen" [disabled]="ui_answerSingleSelection === -1">Nächste Frage</button>
        <button mat-stroked-button *ngIf="isHotline" class="btn-white" (click)="event_resultIntro()">Zur Startseite</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Anzeige der Fragen -->
<ng-template #quickcheck>
  <div class="quickcheck-questions" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100">
      <mf-page-title fxHide fxShow.lt-md="true"></mf-page-title>
      <div class="progress-stepper" role="group" aria-label="progress" *ngIf="{ activeTabIndex: qcActiveTab$ | async } as ctx">
        <ul>
          <ng-container *ngFor="let group of qcGroups; let i = index;">
            <li class="step" [class.completed]="i < ctx.activeTabIndex" [class.is-active]="i === ctx.activeTabIndex" [attr.aria-current]="i === ctx.activeTabIndex">
              <span class="sr-only" *ngIf="i < ctx.activeTabIndex ">Schritt {{i + 1}} abgeschlossen</span>
              <span class="bubble">
                <span>{{ i + 1 }}</span>
                <svg aria-hidden="true" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" >
                    <path transform="matrix(1.2,0,0,1.2,-18,-18)" d="M27.002,43.5C26.619,43.5 26.234,43.354 25.941,43.061L16.941,34.061C16.355,33.475 16.355,32.526 16.941,31.94C17.527,31.354 18.476,31.354 19.062,31.94L27.002,39.879L45.941,20.94C46.527,20.354 47.476,20.354 48.062,20.94C48.648,21.526 48.648,22.475 48.062,23.061L28.062,43.061C27.771,43.354 27.387,43.5 27.002,43.5Z" fill="currentColor" style="fill-opacity:0.9;"/>
                </svg>
              </span>
              <span class="label">{{group.Text}}</span>
            </li>
          </ng-container>
        </ul>
        <div aria-hidden="true" class="current-step-mobile" fxHide fxShow.xs="true" fxShow.sm="true">{{ qcGroups[ctx?.activeTabIndex]?.Text }}</div>
      </div>
    </div>
    <div class="full-width pseudo-card" fxLayout="row wrap" fxLayoutAlign="space-between none">
      <div fxFlex="100">
        <mf-page-title fxHide fxShow.gt-sm="true"></mf-page-title>


        <h1 class="step-headline" style="margin-bottom: 12px;">{{ui_question}}</h1>
        <p class="question-subtitle" *ngIf="ui_questionSub !== ''" style="margin-bottom: 4px;">{{ui_questionSub}}</p>
        <p class="question-subtitle" *ngIf="ui_questionType == UI_QUESTION_TYPE_RADIO || ui_questionType == UI_QUESTION_TYPE_SLIDER">Bitte wählen Sie genau eine Antwort aus</p>
        <mat-radio-group [(ngModel)]="ui_answerSingleSelection" name="choosenAns" *ngIf="ui_questionType == UI_QUESTION_TYPE_RADIO || ui_questionType == UI_QUESTION_TYPE_SLIDER">
          <mat-radio-button color="primary" *ngFor="let answer of ui_answers; let i = index" id="radio{{i}}" name="answer" [value]="i" class="question-answer" [checked]="ui_answerSingleSelection == i">{{answer.Antwort}}</mat-radio-button>
        </mat-radio-group>


        <ng-container *ngIf="ui_questionType == UI_QUESTION_TYPE_CHECKBOX">
          <div fxLayout="column" fxLayout.gt-sm="row">
            <div fxFlex="50%">
              <ng-container *ngFor="let answer of ui_answers; let i = index">
                <mat-checkbox *ngIf="i < 9 && !!ui_answers[i].Antwort" id="checkbox{{i}}" color="primary" name="answer"
                  value="{{i}}" class="question-answer" [checked]="ui_answerMultiSelection[i]"
                  (change)="event_checkedChanged($event.checked, i)">{{answer.Antwort}}</mat-checkbox>
              </ng-container>
            </div>
            <div fxFlex="50%">
              <ng-container *ngFor="let answer of ui_answers; let i = index">
                <mat-checkbox *ngIf="i >= 9 && !!ui_answers[i].Antwort" id="checkbox{{i}}" color="primary" name="answer"
                  value="{{i}}" class="question-answer" [checked]="ui_answerMultiSelection[i]"
                  (change)="event_checkedChanged($event.checked, i)">{{answer.Antwort}}</mat-checkbox>
              </ng-container>
            </div>
          </div>
        </ng-container>

      </div>

      <div  class="button-wrapper" fxFlex="100" fxLayoutAlign="space-between center" fxLayout="row-reverse" fxLayoutGap="12px">
        <button mat-flat-button color="primary" id="next" class="btn-magenta btn_next_question mf-button-big" (click)="event_nextQuestion()" value="nächste Frage" [disabled]="((ui_questionType == UI_QUESTION_TYPE_RADIO ||ui_questionType == UI_QUESTION_TYPE_SLIDER ) && ui_answerSingleSelection === -1)">Nächste Frage</button>
        <button mat-stroked-button id="back" class="btn-white btn_prev_question mf-button-big" (click)="event_prevQuestion()" value="vorherige Frage" [disabled]="qcBreadcrumbIndex == -1 || (qcJson !== undefined && this.qcJson.Einsteig !== undefined && this.qcJson.Einstieg.Antworten.length == 0)">Vorherige Frage</button>
      </div>
    </div>
  </div>
</ng-template>


<!--Anzeige Quickcheck Ergebnis-->
<ng-template #result>
  <ng-container *ngIf="qcResultSuccess; else resultNoSuccess">
    <div class="quickcheck-result pseudo-card" fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex="100" fxFlex.gt-sm="calc(50% - 12px)">
        <mf-page-title></mf-page-title>
        <h1 class="result-headline">Gute Nachrichten!</h1>
        <h2 class="result-subtitle">Sie haben Chancen auf Förderung.</h2>
      </div>

      <div fxFlex="100" fxFlex.gt-sm="50">
        <img [src]="('assetBaseUrl' | env) + '/assets/animations/success.svg'" aria-hidden="true">
      </div>
      <div fxFlex="100">
        <p class="res-reason-title">Wie geht's weiter?</p>
      </div>
      <div class="full-width" fxLayout="row wrap" fxLayout.gt-sm="row wrap-reverse" fxLayoutAlign="flex-start none">
        <div class="m-b-36" fxFlex="100" fxFlex.gt-sm="50">
          <ng-container *ngTemplateOutlet="reasons"></ng-container>
        </div>
        <div class="full-width" fxLayout="row wrap">
          <div fxFlex="100" fxFlex.gt-sm="50" class="m-b-36">
            <div class="call-to-action-teaser">
              <p class="call-to-action-header">Ihre Förderprogramme: {{ qcResultPromotions.length }}</p>
              <p class="call-to-action-text">In der Liste "Ihre Förderprogramme" finden Sie Förderchancen, die wir mit unseren Telekom-Produkten unterstützen können.</p>
              <button mat-stroked-button class="mf-button-big  full-width"  *ngIf="!iosApp" (click)="writePDF();utag_downloadPdfClick()" >Förderprogramme und Angaben als PDF</button>
              <a mat-stroked-button  class="mf-button-big  full-width" *ngIf="iosApp"  href="{{qcResultDocLink}}" target="_blank" (click)="utag_downloadPdfClick()">Förderprogramme und Angaben als PDF</a>
            </div>
          </div>
          <div fxFlex="100" fxFlex.gt-sm="50">
            <ng-container *ngTemplateOutlet="contact"></ng-container>
          </div>
        </div>
      </div>
      <div class="full-width" fxLayout="row wrap" fxLayoutAlign="space-between none">
        <div fxFlex="100" class="m-b-36">
          <div class="angaben  m-t-16" fxLayout="row wrap">
            <p fxFlex="100" class="res-reason-title">Mögliche Förderprogramme:</p>
            <div fxFlex="100" fxFlex.gt-sm="50" *ngFor="let promo of qcResultPromotions">
              <a class="promo" (click)="deeplink_to_fp(promo)">
                  <p class="promo-title">{{promo.name}}</p>
                  <p class="res-promo-value">{{promo.foerderbereich}}</p>
                  <p class="res-promo-value">{{promo.kurztext_simple}}</p>
              </a>
          </div>
          </div>
        </div>

        <div  fxFlex="100" fxLayout="row" fxHide fxShow.lt-md="true">
          <hr class="full-width">
        </div>
        <div  class="button-wrapper" fxFlex="100" fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap="16px">
          <button mat-stroked-button class="btn-white" (click)="event_resultRestartQuickcheck()">Quick-Check neu starten</button>
          <button mat-stroked-button class="btn-white" (click)="event_resultPrevQuestion()">Zurück zu den Fragen</button>
          <button mat-stroked-button *ngIf="isHotline" class="btn-white" (click)="event_resultIntro()">Zur Startseite</button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #resultNoSuccess>
  <div class="quickcheck-result pseudo-card" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100" fxFlex.gt-sm="calc(50% - 12px)">
      <mf-page-title></mf-page-title>
      <h1 class="result-headline">Schade!</h1>
      <h2 class="result-subtitle">Wir konnten leider keine passenden Förderprogramme ermitteln.</h2>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="50">
      <img class="animation" [src]="('assetBaseUrl' | env) + '/assets/animations/no-success.svg'" aria-hidden="true">
    </div>

    <div class="full-width" fxLayout="row wrap" fxLayout.gt-sm="row wrap-reverse" fxLayoutAlign="flex-start none">
      <div class="m-b-36" fxFlex="100" fxFlex.gt-sm="50">
        <ng-container *ngTemplateOutlet="reasons"></ng-container>
      </div>
      <div class="full-width" fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="50">
          <ng-container *ngTemplateOutlet="contact"></ng-container>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50">
          <div class="call-to-action-teaser">
            <p class="call-to-action-header">Ihre Angaben</p>
            <p class="call-to-action-text">Hier können Sie Ihre Antworten innerhalb des Quick-Check ansehen.</p>
            <button mat-stroked-button  class="mf-button-big mf-button-stroked  full-width m-b-36"  *ngIf="!iosApp" (click)="writePDF();utag_downloadPdfClick()" >Angaben als PDF</button>
            <a mat-stroked-button   class="mf-button-big mf-button-stroked  full-width m-b-36" *ngIf="iosApp"  href="{{qcResultDocLink}}" target="_blank" (click)="utag_downloadPdfClick()">Angaben als PDF</a>
          </div>
        </div>
      </div>
    </div>
    <div class="full-width" fxLayout="row wrap" fxLayoutAlign="space-between none">
      <div fxFlex="100">
        <div class="angaben  m-t-16" fxLayout="row wrap">
          <p fxFlex="100" class="res-reason-title">Ihre Angaben zum Quick-Check</p>
          <div *ngFor="let q of qcBreadcrumb" class="res-hist-body" fxFlex="100" fxFlex.gt-sm="50">
              <p class="res-hist-title">{{getQuestionById(q.Id).Frage}}</p>
              <ng-container *ngFor="let a of q.Antworten">
                  <p class="res-hist-value">{{getAnswerById(getQuestionById(q.Id), a).Antwort}}</p>
              </ng-container>
          </div>
        </div>
      </div>

      <div  fxFlex="100" fxLayout="row" fxHide fxShow.gt-sm="true">
        <hr class="full-width">
      </div>
      <div  class="button-wrapper" fxFlex="100" fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap="16px">
        <button mat-stroked-button class="btn-white" (click)="event_resultRestartQuickcheck()">Quick-Check neu starten</button>
        <button mat-stroked-button class="btn-white" (click)="event_resultPrevQuestion()">Zurück zu den Fragen</button>
        <button mat-stroked-button *ngIf="isHotline" class="btn-white" (click)="event_resultIntro()">Zur Startseite</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reasons>
  <ng-container *ngIf="!qcResultSuccess || (qcResultSuccess && qcResultReasons.length > 0)">
    <div *ngFor="let head of qcResultReasons; let i = index" class="res-reason-body m-t-16">
        <p *ngIf="head" class="res-reason-title">{{head.header}}</p>
        <ng-container *ngFor="let reason of head.reasons">
            <p class="res-reason-value">{{reason}}</p>
        </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #contact>
  <div class="call-to-action-teaser">
    <p class="call-to-action-header">Kontaktieren Sie uns!</p>
    <p class="call-to-action-text">Wir stehen Ihnen zur Seite, bis zur Realisierung Ihres Projektes.</p>
    <a mat-flat-button id="quickcheck-contact"
      class="mf-button-big full-width m-b-36"
      color="primary"
      (click)="navigateToContact('quickcheck');utag_contactClick()">
      <span id="quickcheck-contact-text">Kontakt aufnehmen</span>
    </a>
  </div>
</ng-template>


<ng-template #download>
  <div class="quickcheck-download" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex="100">
      <h1 class="step-headline">Download</h1>
      <h2 class="result-subtitle">Ihr Quick-Check als PDF</h2>
    </div>
    <div class="full-width" fxLayout="row wrap" fxLayoutAlign="space-between none">
      <div fxFlex="100">
        <p>Der Download startet automatisch. <br/>Sollte er er nicht automatisch starten, klicken sie den Download Button.</p>
      </div>
      <div fxFlex="100">
        <div  class="button-wrapper" fxFlex="100" fxFlex.gt-sm="33" fxFlexOffset.gt-sm="33" fxLayout="column" fxLayoutGap="16px">
          <button mat-flat-button color="primary" class="btn-magenta btn_next_question mf-button-big" (click)="writePDF()">Download</button>
          <button mat-stroked-button class="btn-white" (click)="closeWindow()">Zurück</button>
        </div>
      </div>

    </div>

  </div>
</ng-template>
