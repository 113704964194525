import { Inject, Injectable } from '@angular/core';
import { AktionsbereichType } from './aktionsbereich';
import { StyleType } from './style';
import { flavor } from 'src/environments/flavor';
import { View } from './view.iface';
import { LoginService } from '../../login';
import { environment } from 'src/environments/environment';
import { Params } from '@angular/router';
import { PACKAGE_VERSION } from '../../services/update.service';

@Injectable()
export class UtagService {

  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(
    private loginService: LoginService,
    @Inject(PACKAGE_VERSION) private readonly version: string
  ) {
    ( window as any).utag_cfg_ovrd = { noview : true };
    ( window as any).utag_data = {};
  }

  // Generic script loader with callback
  getScript(callback: () => void ) {
    if (flavor.isHotline) {
      return;
    }

    const s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = environment.utagScript;
    s.addEventListener('load', () => { callback(); }, false);
    const t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  view(view: View, params: Params = {}) {
    const navigationLevels = view.navigationLevels.map(level => this.getStringOrParam(level, params))

    const data: any = {
      page_content_id: "meine-foerderung.telekom.de.geschaeftskunden." + navigationLevels.join('.'),
      page_domain: "meine-foerderung.telekom.de",
      page_type: view.type,
      page_target_group: "geschaeftskunden",
      portal_name: "foerderung-app",
      app_version: this.version,
      ...view.additionalProperties
    }

    let navLevel: string
    for(let i = 0; i < 6; ++i) {
      navLevel = ""
      if (i < view.navigationLevels.length) {
        navLevel = navigationLevels[i]
      }
      data['page_navigation_level_' + i] = navLevel
    }

    data.page_name = navigationLevels[navigationLevels.length - 1]

    const isLoggedIn = this.loginService.isLoggedInSync
    data.app_usage = (localStorage.getItem("ios_app") === "true") ? "native-ios-app" : "progressive-web-app";
    data.login_status = isLoggedIn ? "business-login_logged-in" : "not-logged-in";
    if (localStorage.getItem("suchbegriff") && isLoggedIn) {
      data.keyword_settings = localStorage.getItem("suchbegriff");
    }

    this.track('view', data);
  }

  aktion(aktionsbereich: AktionsbereichType, style: StyleType, thema: string, navigation?: string, additionalProperties?: any) {
    const data = {
      wt_link_id: aktionsbereich.toString() + "." + style.toString() + "." + thema + (navigation ? "." + navigation : ""),
      app_version: this.version,
      ...additionalProperties
    }
    
    this.track('link', data);
  }

  private getStringOrParam(value: string, params: Params): string {
    if (value.at(0) === ':') {
      return params[value.substring(1)] ?? value
    } else {
      return value
    }
  }

  // Data layer is optional set of key/value pairs
  private track(tealiumEvent: string, data?: any) {
    if (flavor.isHotline) {
      return;
    }

    console.log(data)

    for (let key in data) {
      data[key] = data[key].toString().toLowerCase().
        replaceAll("ä", "ae").
        replaceAll("ö", "oe").
        replaceAll("ü", "ue").
        replaceAll("ß", "ss").
        replaceAll(" ", "-").
        replaceAll(/[^0-9a-z-._]/gi, "").
        replaceAll(/-{2,}/gi, "-");
    }

    // falls tealium nicht gebraucht diesen Block auskommentieren
    if ( ( window as any).utag === undefined ) {
      this.getScript(() => {
        ( window as any).utag.track( tealiumEvent, data );
      });
    } else {
      ( window as any).utag.track( tealiumEvent, data );
    }

  }
}
