import { Injectable } from '@angular/core';
import {
  BreakpointObserver,
  BreakpointState
} from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs';

enum BreakpointsViewports {
  xs ="(max-width:575px)",
  sm ="(min-width: 576px) and (max-width:767px)",
  md ="(min-width: 768px) and (max-width:991px)",
  lg ="(min-width: 992px) and (max-width:1199px)",
  xl ="(min-width: 1200px) and (max-width:1399px)",
  xxl= "(min-width: 1400px)"
}

export enum Breakpoints {
  xs= "xs",
  sm="sm",
  md="md",
  lg="lg",
  xl="xl",
  xxl="xxl"
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  public readonly currentBreakpoint = this.breakpointObserver.observe([
    BreakpointsViewports.xs,
    BreakpointsViewports.sm,
    BreakpointsViewports.md,
    BreakpointsViewports.lg,
    BreakpointsViewports.xl,
    BreakpointsViewports.xxl
  ]).pipe(
    map((state: BreakpointState) => {
      if(state.breakpoints[BreakpointsViewports.xs]) {
        return Breakpoints.xs;
      } else if(state.breakpoints[BreakpointsViewports.sm]) {
        return Breakpoints.sm;
      } else if(state.breakpoints[BreakpointsViewports.md]) {
        return Breakpoints.md;
      } else if(state.breakpoints[BreakpointsViewports.lg]) {
        return Breakpoints.lg;
      } else if(state.breakpoints[BreakpointsViewports.xl]) {
        return Breakpoints.xl;
      } else if(state.breakpoints[BreakpointsViewports.xxl]) {
        return Breakpoints.xxl;
      } else {
        return undefined
      }
    }),
    shareReplay(1)
  )

  public readonly isDesktop = this.currentBreakpoint.pipe(
    map(breakpoint => breakpoint == Breakpoints.lg || breakpoint == Breakpoints.xl || breakpoint == Breakpoints.xxl )
  )

  constructor(public breakpointObserver: BreakpointObserver) {}

}
