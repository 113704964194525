export function createPropertyDecorator<T>(fn: (value: any, ...props:any) => T, ...props:any) : PropertyDecorator {
  return (target: Object, propertyKey: string | symbol) => {
    const tmpKey = `${String(propertyKey)}Tmp`

    function getter(this: any) : boolean {
      return this[tmpKey]
    }

    function setter(this: any, value: any) {
      this[tmpKey] = fn(value, props)
    }

    Object.defineProperty(target, tmpKey, {
      value: undefined,
      writable: true,
      enumerable: true,
      configurable: true
    })

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter,
      enumerable: true,
      configurable: true
    })
  }
}
