import { Component, Input } from '@angular/core';

@Component({
  selector: 'mf-content-row',
  templateUrl: './content-row.component.html',
  styleUrls: ['./content-row.component.scss']
})
export class ContentRowComponent {
  @Input() label: string;
  @Input() content: string;
  @Input() labelMinWidth: string;
}
