import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AktionsbereichType } from '../analytics/aktionsbereich';
import { StyleType } from '../analytics/style';
import { UtagService } from '../analytics';
import { Router } from '@angular/router';

@Directive({
  selector: '[trackClick]'
})
export class ClickDirective {

  constructor(private element:ElementRef, private utag : UtagService, private router: Router) { }

  @Input() trackClick : [AktionsbereichType, StyleType, string, string, any];

  @HostListener('click', ['$event']) onClick($event:any){
    console.info('clicked: ',$event, this.element);
    const [aktionsbereich, style, thema, navigation, additionalProperties] = this.trackClick
    
    this.utag.aktion(aktionsbereich, style, thema, navigation, additionalProperties);
    if (this.element.nativeElement.routerLink) {
      this.router.navigate(this.element.nativeElement.routerLink)
    }
  }


}
