import { Routes } from '@angular/router';
import { QuickcheckComponent } from '../pages/quickcheck/quickcheck.component';
import { LoginComponent } from '../login';
import { ProfilComponent } from '../pages/profil/profil.component';
import { TAnsprechpartnerComponent } from "../pages/t-ansprechpartner/t-ansprechpartner.component";
import { ImpressumComponent } from "../pages/impressum/impressum.component";
import { FaqComponent } from '../pages/faq/faq.component';
import { ContactformComponent } from "../pages/contactform/contactform.component";
import { RegistrationComponent } from '../login/registration/registration.component';
import { IntroComponent, StartComponent } from '../pages/start';
import { IsLoggedIn } from '../shared/guards/login.guard';

export const routes: Routes = [
    {
      path: 'start',
      component: StartComponent,
      title: 'Start',
      data: {
        animation: 'start',
        analytics: {
          type: "theme", // individuell setzen
          navigationLevels: [
            "welcome"
          ]
        }
      }
    },
    {
      path: 'intro',
      component: IntroComponent
    },
    /** Additional fallback route due to index.html */
    {
      path: 'intro/:any', // this ':any' is required as the intro pages have an additional index.html
      redirectTo: 'intro'
    },
    {
      // @deprecated: old path
      path: 'menu',
      redirectTo: 'start'
    },
    { 
      // @deprecated: old path
      path: 'promotion', 
      redirectTo: 'promotions'
    },
    {
      path: 'promotions',
      loadChildren: () => import('../pages/promotion/promotion.module').then(m => m.PromotionModule)
    },
    {
      path: 'produkte',
      loadChildren: () => import('../pages/products/products.module').then(m => m.ProductsModule)
    },
    {
      path: 'quickcheck',
      component: QuickcheckComponent,
      title: 'Quick-Check',
      data: {
        animation: 'quickcheck'
      }
    },
    { path: 'pdf', redirectTo: 'quickcheck', pathMatch: 'full' }, // iOS Safari redirect into SFViewController
    {
      path: 'events',
      loadChildren: () => import('../pages/events/events.module').then(m => m.EventsModule)
    },
    {
      path: 'faq',
      redirectTo: 'faq/'
    },
    {
      path: 'faq/:id',
      component: FaqComponent,
      title: 'FAQs',
      data: {
        animation: 'FAQ',
        analytics: {
          type: "faq",
          navigationLevels: ["mehr", "faq"]
        }
      }
    },
    {
      path: 't_ansprechpartner',
      component: TAnsprechpartnerComponent,
      title: 'Ansprechpartner',
      data: {
        animation: 'T_ansprechpartner',
        analytics: {
          type: "theme", // individuell setzen
          navigationLevels: ["mehr", "ansprechpartner"]
        }
      }
    },
    {
      path: 'contactform',
      component: ContactformComponent,
      title: 'Kontakt',
      data: {
        animation: 'Contactform',
        analytics: {
        type: "kontaktformular", // individuell setzen
        navigationLevels: [
          "contactform"
        ]}
      }
    },
    {
      path: 'profile',
      component: ProfilComponent,
      canActivate: [IsLoggedIn],
      data: {
        title: 'Profil',
        animation: 'Profil',
        analytics: {
          type: "theme",
          navigationLevels: ["profil"]
        }
      }
    },
    {
      path: 'register',
      component: RegistrationComponent,
      data: {
        title: 'Registrieren',
        animation: 'Login',
        analytics: {
          type: "register",
          navigationLevels: ["registrierung"]
        }
      }
    },
    {
      path: 'impressum',
      component: ImpressumComponent,
      data: {
        title: 'Impressum',
        animation: 'Impressum',
        analytics:  {
            type: "theme",
            navigationLevels: ["mehr", "impressum"]
          }
      }
    },

    { path: 'login', component: LoginComponent, data: { animation: 'Login' } },
    { path: 'login/:state', component: LoginComponent, data: { animation: 'Login' } },

    { path: '', redirectTo: 'start', pathMatch: 'full' },

    /** In case of invalid paths, go to start page */
    { path: '**', redirectTo: 'start', pathMatch: 'full' }
];
