import { Environment } from "./environment.iface";

export const environment: Environment = {
  production: true,
  backendUrl: 'https://test.meine-foerderung.telekom.de/api',
  assetBaseUrl: 'https://test.meine-foerderung.telekom.de',
  appStoreUrls: {
    'iOS': 'https://apps.apple.com/de/app/meine-f%25C3%25B6rderung/id1563739360',
    'Android': 'https://play.google.com/store/apps/details?id=de.telekom.meine_foerderung.twa'
  },
  authentication: {
    issuer: "https://business-test-login.telekom.de/auth/realms/telekom-customer-k2",
    clientId: "FoerderAppTelekom",
    scope: "openid offline_access"
  },
  utagScript: '//tags-eu.tiqcdn.com/utag/telekom/meine-foerderung/prod/utag.js'
};
