import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Campagne } from '../pages/contactform/campagne';
import { environment } from '../../environments/environment';
import { GeneralAdvice } from '../shared/general_advice';

//ermöglicht das Absenden von Formulardaten an

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  public sendContactForm(contactForm: any): Observable<any> {
    const body = {
      "quelle": contactForm.quelle,
      "firma": contactForm.firma.trim(),
      "vorname": contactForm.vorname.trim(),
      "nachname": contactForm.nachname.trim(),
      "strassehausnummer": contactForm.strassehausnummer.trim(),
      "plz": contactForm.plz.trim(),
      "stadt": contactForm.stadt.trim(),
      "email": contactForm.email.trim(),
      "erreichbarkeit": Number(contactForm.erreichbarkeit),
      "vorwahl": contactForm.vorwahl.trim(),
      "rufnummer": contactForm.rufnummer.trim(),
      "kommentar": contactForm.kommentar.trim(),
      "quickcheck": contactForm.quickcheck,
      "isHotline": contactForm.isHotline,
      "kundennummer": contactForm.kundennummer.trim(),
      "campagne": contactForm.campagne
    };

    return this.http.post<any>(environment.backendUrl + '/tip', body);
  }

  getCampagnes(): Observable<Campagne[]> {
    let url: string = environment.backendUrl + "/hotline";
    return this.http.get<Campagne[]>(url);
  }

  getGeneralAdvice(): Observable<GeneralAdvice> {
    return this.http.get<GeneralAdvice>(environment.backendUrl + "/contact");
  }
}
