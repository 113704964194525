import { Component, Input } from '@angular/core';
import { LoginService } from 'src/app/login';
import { DeviceService } from 'src/app/services/device.service';
import { PageTitleStrategy } from 'src/app/utilities';

export interface MenuItem {
    name: string,
    path: string,
    icon: string,
    external?: boolean,
    items ?: MenuItem[]
}

@Component({
  selector: 'mf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() isDesktop: boolean = false;

  @Input() menuItems : MenuItem[] = [];

  public standalone = this.deviceService.isStandalone
  public title = this.titleStrategy.title

  readonly isLoggedIn = this.loginService.isLoggedIn

  constructor(
    private loginService: LoginService,
    private titleStrategy: PageTitleStrategy,
    private deviceService: DeviceService
  ) {
  }

  backNavigation() {
    history.back()
  }
}
