import { NgModule } from "@angular/core";
import { ExternalLinkDirective } from "./external-link.directive";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { DynamicContentLinkDirective } from './dynamic-content-link.directive';

@NgModule({
  declarations: [
    ExternalLinkDirective,
    DynamicContentLinkDirective
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    ExternalLinkDirective,
    DynamicContentLinkDirective
  ]
})
export class DirectivesModule { }