/**
 * Delegate decorator to forward getter and setter values
 * 
 * Use-Case:
 * 
 * - A class has getter/setter values, which are always handled by another class.
 *   To avoid delegation boilerplate code, this decorator forwards the values
 *   automatically.
 * 
 * Example:
 * 
 * ```
 * class A {
 *   prop1: string
 *   prop2: boolean
 * }
 * 
 * class B {
 *   constructor(private target: A) {}
 *   
 *   @Delegate("target", "prop1")
 *   propA: string
 * 
 *   @Delegate("target", "prop2")
 *   propB: boolean
 * }
 * 
 * b.propA = "Test"
 * console.log(a.prop1) // prints "Test"
 * ```
 * 
 * 
 * @param delegateTarget property name of the delegate target
 * @param delegateProp optional property name in the delegate target, if names mismatch
 */
export function Delegate(delegateTarget: string | symbol, delegateProp?: string | symbol) : PropertyDecorator {
  return (target: Object, propertyKey: string | symbol) => {
    function getter(this: any) : any {
      return this[delegateTarget][delegateProp || propertyKey]
    }

    function setter(this: any, value: any) {
      this[delegateTarget][delegateProp || propertyKey] = value
    }

    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter,
      enumerable: true,
      configurable: true
    })
  }
}