import { Component, Input, OnInit } from '@angular/core';
import { ToBoolean } from '../../utilities/decorators';
import { flavor } from 'src/environments/flavor';
import { PageTitleStrategy } from 'src/app/utilities';

@Component({
  selector: 'mf-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  public showHeader = !(flavor.isHotline || flavor.snippet)
  public title = this.titleStrategy.title

  @Input() @ToBoolean() public forceCenter: boolean = true;

  constructor(private titleStrategy: PageTitleStrategy) { }

  ngOnInit(): void {
  }

}
