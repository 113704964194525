<span mat-dialog-title>Diese Seite als QR-Code</span>
<mat-dialog-content>
  <div class="d-flex flex-column">
    <qrcode
      [qrdata]="value"
      [allowEmptyString]="false"
      [ariaLabel]="'QR Code'"
      [colorDark]="'#000000ff'"
      [colorLight]="'#ffffffff'"
      [elementType]="'canvas'"
      [errorCorrectionLevel]="'H'"
      [imageSrc]="'./assets/icons/schubkraft_icon_240_240_border.png'"
      [imageHeight]="75"
      [imageWidth]="75"
      [margin]="4"
      [scale]="1"
      [title]="value"
      [width]="300"
      class="align-self-center"
    ></qrcode>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-flat-button color="accent" mat-dialog-close>Schließen</button>
</div>