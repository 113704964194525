import { Component, OnInit } from '@angular/core';
import { GeneralAdvice } from '../../shared/general_advice';
import { ContactService } from '../../services/contact.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-t-ansprechpartner',
  templateUrl: './t-ansprechpartner.component.html',
  styleUrls: ['./t-ansprechpartner.component.scss']
})
export class TAnsprechpartnerComponent implements OnInit {
  public general_advice: Observable<GeneralAdvice> = this.contactService.getGeneralAdvice();
  constructor(
    private contactService: ContactService,
  ) { }

  ngOnInit(): void {
  }

}
