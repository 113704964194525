<mat-card class="list-card" [class.highlight]="highlight">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{title}}</mat-card-title>
      <mat-icon *ngIf="icon" color="accent">{{icon}}</mat-icon>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>
   <ng-content></ng-content>
  </mat-card-content>
</mat-card>
