import { Component } from '@angular/core';

@Component({
  selector: 'mf-horizontal-scroller',
  templateUrl: './horizontal-scroller.component.html',
  styleUrls: ['./horizontal-scroller.component.scss']
})
export class HorizontalScrollerComponent {

}
