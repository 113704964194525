import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductCategoryItem } from '../../../pages/products/product.ifaces';

@Component({
  selector: 'mf-accordion-nav-card',
  templateUrl: './accordion-nav-card.component.html',
  styleUrls: ['./accordion-nav-card.component.scss']
})
export class AccordionNavCardComponent implements AfterViewInit{
  public elementId = crypto.randomUUID()

  @Input() item: ProductCategoryItem;
  @Input() toggleLabel : string = 'Subkategorien anzeigen'

  @Input()  openState : boolean = false;
  @Output() openStateChanged : EventEmitter<boolean> = new EventEmitter();

  ngAfterViewInit(): void {
    if (this.openState) {
      setTimeout(() => {
        document.getElementById(this.elementId).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      },250)
    }
  }

  public emitExpanded() {
    this.openStateChanged.emit(true);

  }
}
